import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/graduation-requirements';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const GraduationRequirements = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="graduation_requirements" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Graduation Requirements</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">Graduation Requirements</h4>
                </div>        
              </div>
              
              <p>
                All students need to satisfy the Ibn Khuldoon National School 
                Graduation Requirements.
              </p>
              
              <p>
              In order to be eligible for graduation, IKNS students must have a record 
              of regular attendance and acceptable discipline. The total number of 
              credits attempted (G9 to G12) is 29.75 credits. The minimum credits 
              required for graduation is 27.75 credits. A student may graduate with 
              one credit less in major subjects (other than Arabic and English) and 
              one credit less in minor subjects (other than Islamic Studies).
              </p>
              
              <p className="content-title content-qoute mt-5">
               IKNS Students must have earned the following credits in grades 9-12
              </p>
             
              <div className="card col-md-12">
                <div className="card-header">
                MAJOR SUBJECT CREDITS
                </div>
                <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">4.0 credits in Arabic</li>
                  <li className="list-group-item">4.0 credits in English</li>
                  <li className="list-group-item">3.0 credits in mathematics (at least)</li>
                  <li className="list-group-item">3.0 credits in Science (at least)</li>
                  <li className="list-group-item">3.0 credits in Social Science (at least)</li>
                </ul>
                </div>
              </div>


              <div className="card col-md-12 mt-3">
                <div className="card-header">
                MINOR SUBJECT CREDITS
                </div>
                <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">2.0 credits in Islamic Studies (for Muslim Students)</li>
                  <li className="list-group-item">2.0 credit of National Social Studies (1.0 credit for IB students)</li>
                  <li className="list-group-item">2.0 credits of Physical Education (for eligible students)</li>
                  <li className="list-group-item">0.5 credits of grade 9 electives</li>
                  <li className="list-group-item">1.0 credit of Theory of Knowledge (IB students only)</li>
                </ul>
                </div>
              </div>

              <div className="card col-md-12 mt-3">
                <div className="card-header">
                6.0 CREDITS SELECTED FROM
                </div>
                <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">Science</li>
                  <li className="list-group-item">Social Studies</li>
                  <li className="list-group-item">Art</li>
                  <li className="list-group-item">Computing Studies</li>
                </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


