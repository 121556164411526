import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/external-exam-results';
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const IbdpResults = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="external_exam_results" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#">External Exam Results</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>IBDP Results</strong></Link></li>
                  </ol>
                
                </div>        
              </div>


              <h4 className="mt-3 page-title">Ibn Khuldoon National School May 2022 IBDP Results</h4>

              <p>
                Congratulations to the 46 students who successfully met all the 
                requirements and obtained the IB Diploma this session. They demonstrated 
                excellent motivation and time management as they completed their IB course 
                requirements and prepared for the examinations during the Covid-19 pandemic.
              </p>
              
              <p>
                Besides the pleasing number of students who fulfilled the diploma 
                requirements, the results they obtained were outstanding. 11% of 
                the IKNS students achieved 40 points and above out of a total of 45 
                points, and 43% achieved 35 points and above.</p>
              
              <p>
                We are also proud of the students who challenged themselves and sat 
                for some IB courses. In addition to the full diploma students 45 students 
                registered as course candidates for this examination session and it is 
                pleasing to see so many IKNS graduates awarded an IB certificate.
              </p>
              
              <p>
                Special congratulations go to Jumana Al Rujaib for achieving the impressive 
                score of 45 points, the second perfect score in a row for IKNS! 
                Congratulations to Lulwa Meshaal Al Khalifa for achieving 42 points, 
                Jenin Ali Ashoor and Ali Husain Jawad Hashem for achieving 41 points, 
                and Joseph Luke for achieving 40 diploma points. The average Diploma 
                score was a distinguished 34 points this session. Well done to the 
                Class of 2022!
              </p>


              <a href="https://drive.google.com/file/d/1RifjuVQuPJeZUjsYGYPyiH3BjbYZjgkh/view" target="_blank" rel="noreferrer" className="text-link">
                View Ibn Khuldoon National School May 2022 IBDP Results <i className="fas fa-arrow-right text-right"></i>
              </a>

            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


