import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/student-life';
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const StudentCongress = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="student_life" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">IKNS Community</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Students' Life</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Students Congress</strong></Link></li>
                  </ol>
                  {/* <h4 className="mt-3 page-title">Students Congress</h4> */}
                </div>        
              </div>
              {/* <p>.</p> */}

              {/* <img src={require('../../assets/img/community/student_life.jpg')} className="mt-3 img-fluid rounded" alt='...'/>               */}
              
              <h4 className="mt-3 page-title text-center">IKNS Student Congress Constitution</h4>

              <p>
                <small className="d-block text-muted">Article I</small> 
                <strong>NAME</strong>
              </p>
              <p>The Name of the student council organization is hereby constituted as 
                "Ibn Khuldoon Student Congress," known as IKSC.</p>

              <p className="mt-5">
                <small className="d-block text-muted">Article II</small> 
                <strong>PURPOSE</strong>
              </p>
              <ol>
                <li>Promote school spirit</li>
                <li>Promote school citizenship</li>
                <li>Strengthen student/teacher/administration relationships.</li>
                <li>Support student opinions on issues related to school life.</li>
              </ol>

              <p className="mt-5">
                <small className="d-block text-muted">Article III</small> 
                <strong>POWERS OF IKSC</strong>
              </p>
                <ol>
                  <li>
                    Self-organization and self-determination vested within the congress 
                    itself. The Student Congress is free to formulate and submit action 
                    plans to the Principal
                  </li>
                  <li>
                    The Principal of the school has the Power of Veto over all 
                    action plans proposed by Student Congress
                  </li>
                  <li>
                  The congress has the right to appeal to any veto action to the 
                  Principal and request an explanation and recommendations
                  </li>
                </ol>

                <p className="mt-5">
                <small className="d-block text-muted">Article IV</small> 
                <strong>DUTIES & RESPONSIBILITIES</strong>
              </p>

            <ol>
              <li>President
                <ul className="alphabetical-list">
                  <li>Plans agendas in collaboration with the Secretary</li>
                  <li>Has the final decision in the case of a tie vote during 
                    Student Congress meetings</li>
                  <li>Represents the Student congress at the meetings of the PTSA and
                    meetings of the Discipline Committee. May attend meeting of other
                    school committees upon invitation from the chairperson of the
                    committee, when the issues are relevant to the Student congress</li>
                  <li>Is the person that assumes prerogative of leadership and
                responsibility in keeping the Student Congress functional</li>
                </ul>
              </li>
              <li className="mt-3">
              Senior Vice President
              <ul className="alphabetical-list">
                <li>Acts as assistant to the President</li>
                <li>Serves at meeting if the President is absent</li>
                <li>Heads the Senior Council</li>
                <li>Serves as the main link between senior related issues and the Student congress</li>
              </ul>
              </li>

              <li className="mt-3">
              Junior Vice President
              <ul className="alphabetical-list">
                <li>Acts as assistant to the President, and tries to pick up the IKSC know how.</li>
                <li>Heads the Junior Council</li>
                <li>Acts as the main link between junior related issues and the Student congress</li>
                <li>Active member in most Student Congress Committees</li>
              </ul>
              </li>

              <li className="mt-3">
                Public Relations Officer
                <ul className="alphabetical-list">
                  <li>In charge of advertising Student congress events within the school premises</li>
                  <li>Acts as the main link between teachers and the Student Congress</li>
                  <li>Acts as the main link between administration and the Student Congress</li>
                  <li>In charge of agreements with outsourced suppliers or clubs</li>
                  <li>Handles notices</li>
                </ul>
              </li>

              <li className="mt-3">
              Treasurer
              <ul className="alphabetical-list">
                <li>Reports monthly money matters to the congress</li>
                <li>Informs the president of the financial status on a regular basis</li>
                <li>Acts as the handler of the portable chest box</li>
                <li>Is the only IKSC member with the authority to withdraw money from the account</li>
              </ul>
              </li>


              <li className="mt-3">
            Secretary
            <ul className="alphabetical-list">
              <li>Takes attendance at the beginning of each meeting</li>
              <li>Presents a written agenda to the congress at the beginning of each meeting</li>
              <li>Keeps the minutes of the meetings</li>
              <li>Collects written motions</li>
              <li>Keeps reports and records</li>
              <li>Handles letters and notices</li>
            </ul>
            </li>

            <li className="mt-3">
            Class Representatives
            <ul className="alphabetical-list">
              <li>Represents grade level and student ideas on the Congress</li>
              <li>Act as a liaison between the classes and the Student Congress</li>
            <li>Report to the class on the results of Congress actions</li>
            <li>Serve on committees</li>
            <li>Help the treasure in preparation for social events or gatherings</li>
            </ul>
            </li>           
           </ol>


            <p className="mt-5">
              <small className="d-block text-muted">Article V</small> 
              <strong>EXECUTIVE COUNCIL</strong>
            </p>

            <ol>
              <li>The Student Congress will be comprised of an Executive Council of 6
              members and 4 Class representatives, for a total of 20 student congress
              members with equal voting rights</li>
              <li>Executive Council</li>
              <ul className="alphabetical-list">
                <li>Elections for the upcoming year's Executive council will be held in the 
                  fourth quarter, after the High School seniors complete their attendance</li>
                <li>All Participants in the Executive Council elections must have had
                participated in Student congress one year prior to the elections. If not,
                the participant must complete a new nomination form to be signed by
                two teachers and the Principal.</li>
                <li>Executive Council</li>
              </ul>

            </ol>

            <div className="col-md-10 mx-auto ">
              <table className="table table-bordered">
               <tbody>
                <tr>
                <td className="align-middle">President</td>
                <td>Grade 12</td>
                </tr>
                <tr>
                <td className="align-middle">Senior Vice President</td>
                <td>Grade 12</td>
                </tr>
                <tr>
                <td className="align-middle">Junior Vice President</td>
                <td>Grade 11</td>
                </tr>
                <tr>
                <td className="align-middle">Public Relations Officer</td>
                <td>Grade 11 or Grade 12</td>
                </tr>
                <tr>
                <td className="align-middle">Treasurer</td>
                <td>Grade 11 or Grade 12</td>
                </tr>
                <tr>
                <td className="align-middle">Secretary</td>
                <td>Grade 11 or Grade 12</td>
                </tr>
              </tbody>
              </table>
            </div>


            <p className="mt-5">
              <small className="d-block text-muted">Article VI</small> 
              <strong>CAMPAIGNING</strong>
            </p>
            <ol>
              <li>Election campaigns take place during one week.</li>
              <li>Each candidate will be allowed to have a maximum of five posters, 
                four banners, and an unlimited number of stickers</li>
              <li>Candidates will be provided with equal spacing and exposure 
                  in a posting area designated for posters and banners</li>
              <li>The Candidates for the seats of President and two Vice-Presidents 
                will deliver speeches at an assembly and will formally debate 
                issues prior to the election</li>
            </ol>

            
            <p className="mt-5">
              <small className="d-block text-muted">Article VII</small> 
              <strong>CLASS REPRESENTATIVES</strong>
            </p>

            <ol>
              <li>Voting</li>
              <ul className="alphabetical-list">
                <li>Each grade will vote for its representatives</li>
                <li>The representatives will be elected on the third Saturday o the new
                school year</li>
                <li>Grade 9-10 will have three representatives, and the grade 11-12 
                  will have four representatives</li>
              </ul>
              <li>Eligibility</li>
                <p>Any student is eligible to run for a Student Congress position
                unless he or she is under academic or behavior probation or in
                suspension. A member of the Student Congress should typically be
                dedicated, responsible, enthusiastic, and very active in school
                activities.</p>
            </ol>

            <p className="mt-5">
              <small className="d-block text-muted">Article VIII</small> 
              <strong>MEETINGS</strong>
            </p>
            <ol>
              <li>Meetings will be held after school hours</li>
              <li>General meetings will be held once every week</li>
              <li>Urgent, additional, or special meetings will be held when necessary</li>
              <li>During meetings the members will split into two committees: The
                  Public Relation Committee; for advertisements and announcements;
                  and the Organization Committee for thorough planning. In addition,
                  the President will consult the Treasury Committee with financial
                  related material</li>
            </ol>


            <p className="mt-5">
              <small className="d-block text-muted">Article IX</small> 
              <strong>AMENDING THE CONSTITUTION</strong>
            </p>

            <ol>
              <li>Any member of the Student Congress may propose amendments to the
            Constitution. An amendment must receive at least 75% of the student
            Congress vote to be enacted</li>
            <li>Ratified by the votes of the Student Congress members from 9-12. A
            75% vote must approve the Constitution in order for it to be submitted
            to the Administration.</li>
            </ol>


            <p className="mt-5">
              <small className="d-block text-muted">Article X</small> 
              <strong>IMPEACHMENT</strong>
            </p>
              <p>Impeachment of a member of the Student Congress may result when a
              student commits serious violation of conduct and responsibility. One specific
              example of serious misconduct is the absence from three Congress meetings per
              year. If a member of Congress receives academic or disciplinary probation, they
              will be automatically expelled from Congress.</p>

            <p>In the case of member conduction such a violation, the Entire congress
            will hold an impeachment vote. Should 75% or more of the members&#39; present
            vote to impeach the member in question; the member will be expelled from the
            student Congress for the remainder of the year.</p>


            <p className="mt-5">
              <small className="d-block text-muted">Article XI</small> 
              <strong>STANDING COUNCILS</strong>
            </p>

            <p>The following councils will act as individual unions, but will be part of the
            student Congress and will be accountable to the President. The President will not
            chair the councils, but he/she will have veto power over any action plan the
            councils may come up with.</p>
            <ol>
              <li>Senior Council (Chaired by the Sr. Vice President)</li>
              <li>Junior Council (Chaired by the Jr. Vice President)</li>
            </ol>

            <p className="mt-5">
              <small className="d-block text-muted">Article XII</small> 
              <strong>TRANSER OF PROCEEDS</strong>
            </p>

              <p>A minimum amount of BD 450 must be transferred to the following year&#39;s
              Student Congress.</p>


              <p className="mt-5">
              <small className="d-block text-muted">Article XIV</small> 
              <strong>ADVISOR</strong>
            </p>

            <p>Faculty advisor(s) will be appointed by the administration. He/she will
            assume an advisory role acting as a liaison between the Student congress and
            Administration. There must be one faculty advisor attending each Student
            Congress general meeting.</p>
            
            <hr />

            <p className="content-title mt-5">Student Congress Presidents</p>

            <div className="col-md-10">
              <table className="table table-bordered">
               <tbody>
                <tr>
                <td className="align-middle">Farouk Hadeed</td>
                <td className="col-md-2 text-center ">1992/1993</td>
                </tr>

                <tr>
                <td className="align-middle">Ola Ayaso</td>
                <td className="col-md-2 text-center">1993/1994</td>
                </tr>
                
                <tr>
                <td className="align-middle">Mohamed Darwish AlTattan</td>
                <td className="col-md-2 text-center">1994/1995</td>
                </tr>

                <tr>
                <td className="align-middle">Mohamed Adel Fakhro</td>
                <td className="col-md-2 text-center">1995/1996</td>
                </tr>

                <tr>
                <td className="align-middle">Mashaal AlBayat</td>
                <td className="col-md-2 text-center">1996/1997</td>
                </tr>

                <tr>
                <td className="align-middle">Noor AlHamar</td>
                <td className="col-md-2 text-center">1997/1998</td>
                </tr>

                <tr>
                <td className="align-middle">Tareq Saleh</td>
                <td className="col-md-2 text-center">1998/1999</td>
                </tr>

                <tr>
                <td className="align-middle">Mohamed Ahmed Fakhro</td>
                <td className="col-md-2 text-center">1999/2000</td>
                </tr>

                <tr>
                <td className="align-middle">Reem Ahmadi</td>
                <td className="col-md-2 text-center">2000/2001</td>
                </tr>

                <tr>
                <td className="align-middle">Tala Saleh</td>
                <td className="col-md-2 text-center">2001/2002</td>
                </tr>

                <tr>
                <td className="align-middle">Abdulla Rashed Isa</td>
                <td className="col-md-2 text-center">2002/2003</td>
                </tr>

                <tr>
                <td className="align-middle">Bassam Darwish AlTattan</td>
                <td className="col-md-2 text-center">2003/2004</td>
                </tr>

                <tr>
                <td className="align-middle">Hassan Mahmoud</td>
                <td className="col-md-2 text-center">2004/2005</td>
                </tr>

                <tr>
                <td className="align-middle">Aysha AlMoayyed</td>
                <td className="col-md-2 text-center">2005/2006</td>
                </tr>

                <tr>
                <td className="align-middle">Faisal Rashed Isa</td>
                <td className="col-md-2 text-center">2006/2007</td>
                </tr>

                <tr>
                <td className="align-middle">Moayyed AlMoayyed</td>
                <td className="col-md-2 text-center">2007/2008</td>
                </tr>

                <tr>
                <td className="align-middle">Eshaq Eshaq</td>
                <td className="col-md-2 text-center">2008/2009</td>
                </tr>

                <tr>
                <td className="align-middle">Abdulla Mhamood</td>
                <td className="col-md-2 text-center">2009/2010</td>
                </tr>

                <tr>
                <td className="align-middle">Hala Hashemi</td>
                <td className="col-md-2 text-center">2010/2011</td>
                </tr>

                <tr>
                <td className="align-middle">Ahmed Awadhi</td>
                <td className="col-md-2 text-center">2011/2012</td>
                </tr>

                <tr>
                <td className="align-middle">Yacoub Hamar</td>
                <td className="col-md-2 text-center">2012/3013</td>
                </tr>

                <tr>
                <td className="align-middle">Fahed AlJaber</td>
                <td className="col-md-2 text-center">2013/2014</td>
                </tr>

                <tr>
                <td className="align-middle">Alya AlMulla</td>
                <td className="col-md-2 text-center">2014/2015</td>
                </tr>

                <tr>
                <td className="align-middle">Noor Abdulla</td>
                <td className="col-md-2 text-center">2015/2016</td>
                </tr>

                <tr>
                <td className="align-middle">Fahad AlKhaja</td>
                <td className="col-md-2 text-center">2016/2017</td>
                </tr>


                <tr>
                <td className="align-middle">Alia Buachala</td>
                <td className="col-md-2 text-center">2017/2018</td>
                </tr>

                <tr>
                <td className="align-middle">Yasmeen Nooreddine</td>
                <td className="col-md-2 text-center">2018/2019</td>
                </tr>

                <tr>
                <td className="align-middle">Lulwa Al Khaja</td>
                <td className="col-md-2 text-center">2019/2020</td>
                </tr>

                <tr>
                <td className="align-middle">Maya Bseisu</td>
                <td className="col-md-2 text-center">2020/2021</td>
                </tr>

                <tr>
                <td className="align-middle">Shareefa Kanoo</td>
                <td className="col-md-2 text-center">2021/2022</td>
                </tr>




              </tbody>
              </table>
            </div>


            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


