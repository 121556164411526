export const SidebarLinks = [
  {
    label: "Academic Integrity",
    path: "/academic-integrity"
  },
  {
    label: "IKNS Academic Honesty Policy",
    path: "/academic-honesty-policy"
  },
];

export default SidebarLinks;
