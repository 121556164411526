export const GRAPHQL_API = "https://wp.ikns.info/graphql";

export const GET_POSTS_SIDEBAR = `
query posts {
  posts(where: {categoryName: "news"}){
    nodes {
      slug
      title
      date
      excerpt
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
}`