
export const SidebarLinks = [
  {
    label: "Strategic Plan 2023-2030",
    path: "/strategic-plan"
  },

  {
    label: "Strategic Plan 2015-2022",
    path: "/strategic-plan/2015-2022"
  },

];

export default SidebarLinks;
