import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/external-exam-results';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const Map = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="external_exam_results" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#">External Exam Results</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Measure of Academic Progress (MAP)</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">Measure of Academic Progress (MAP)</h4>
                </div>        
              </div>

              <p>
              IKNS conducts the MAP tests two times a year in Grades 3 to 9 in English Reading, Mathematics and Science.
              </p>
              
              <p>
                The MAP is a computerized test that provides accurate information 
                and data about the students' growth in these subjects across time. 
                The test is flexible and adaptive. MAP adjusts its level according 
                to the student's answers and allows students as much time as 
                needed. The MAP design permits the school to compare the academic 
                achievement of any group of students against worldwide age-related 
                academic expectations set by the Northwest Evaluation Association (NWEA).
              </p>

              <p>
              Teachers use the MAP data to improve the students' mastery of the 
              learning objectives of the school's Primary Years Program (PYP) 
              and the Middle Year Program (MYP).
              </p>

              <p>
              IKNS reports MAP results to parents at the end of each semester.
              </p>

              <p className="content-title mt-5">
              What is MAP Growth Video
              </p>

              <div className="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/wNuR9SnpCFI" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen 
                title="Embedded youtube"
                />
                </div>
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


