export const SidebarLinks = [
  {
    label: "The IB Middle Years Programme (G1-G10)",
    path: "/myp"
  },
  
  {
    label: "Assessment In the MYP",
    path: "/myp-assessment"
  },
  
  {
    label: "MYP Presentations & Activities",
    path: "/myp-presentations-Activities"
  },
 ];

export default SidebarLinks;
