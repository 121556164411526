import { Link } from "react-router-dom";

export const Navigation = () => {
  return (
    <div className="bg-black">
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src={require('../assets/img/logo_light.png')} alt="logo" className="main-logo" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item"><a className="nav-link" aria-current="page" href="https://www.ikns.info/family" target="_blank" rel="noreferrer"> Parent Portal</a></li>
              <li className="nav-item"><Link className="nav-link" aria-current="page" to="/contact-us">Contact Us</Link></li>
              <li className="nav-item d-none d-sm-block"><Link to="/recruitment" className="round-btn btn btn-primary">
                Recruitment</Link></li>
            </ul>
          </div>
        </div>
      </nav>

      <nav className="navbar navbar-expand-lg navbar-darkx secondary-navbar">
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="main_nav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown" to="#" data-bs-toggle="dropdown">Key Info</Link>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to="/attendance-dismissal-times">Attendance & Dismissal Times</Link></li>
                  <li><Link className="dropdown-item" to="/the-student-teacher-handbook">The Student & Parent Handbook</Link></li>

                  {/* <li><Link className="dropdown-item" to="/back-to-school-operational-plan">Back to School Operational Plan</Link></li> */}
                  {/* <li><Link className="dropdown-item" to="#"> Dropdown item 2</Link>
            <ul className="submenu dropdown-menu">
              <li><Link className="dropdown-item" to="#">Submenu item 1</Link></li>
              <li><Link className="dropdown-item" to="#">Submenu item 2</Link>
                  <ul className="submenu dropdown-menu">
                  <li><Link className="dropdown-item" to="#">Multi level 1</Link></li>
                  <li><Link className="dropdown-item" to="#">Multi level 2</Link></li>
                  </ul>
              </li> 
            </ul>
          </li>  */}


                  <li><Link className="dropdown-item" to="/bell-schedule">Bell Schedule</Link></li>
                  <li><Link className="dropdown-item" to="/school-calendar">School Calendar</Link></li>
                  <li><Link className="dropdown-item" to="/cycle-calendar">Middle/Secondary 6-day Cycle Calendar</Link></li>

                  {/* <li><Link className="dropdown-item" to="#">Operational Plans, Previous Years</Link>
            <ul className="submenu dropdown-menu">
              <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1etRzvA-hi-lWqnqxLdgt3C2OtoK92k6P/view?usp=sharing">Operational Plan 2021-2022</a></li>
              <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1mqZZPvpCwva7phrfhzHm9A-DSTH7vgOP/view?usp=sharing">MoE Back to School Guide</a></li>
              <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/19Zg8PH2NzH_prapB8fEu4gaB8pnVX3S3/view?usp=sharing">Operational Plan 2020-2021</a></li>
            </ul>
          </li>  */}
                  <li><Link className="dropdown-item" to="/operational-plans">Operational Plans, Previous Years</Link></li>
                  <li><Link className="dropdown-item" to="/students-device-specifications">Students Device Specifications</Link></li>
                  {/* <li><Link className="dropdown-item" to="/virtual-learning-preriod">Activities During Virtual Learning Period</Link></li> */}
                  <li><Link className="dropdown-item" to="/kg-and-elementary">Kindergarten and Elementary (K-5)</Link></li>
                  <li><Link className="dropdown-item" to="/middle-school">Middle School (6-8)</Link></li>
                  <li><Link className="dropdown-item" to="/secondary-school">Secondary School (9-12)</Link></li>
                  <li><Link className="dropdown-item" to="/school-fees">School Fees</Link></li>
                  <li><Link className="dropdown-item" to="/uniform">School Uniform</Link></li>
                  <li><Link className="dropdown-item" to="/accreditation-affiliation">Accreditation and Affiliation</Link></li>
                  <li><Link className="dropdown-item" to="/school-policies">School Policies</Link></li>
                  <li><Link className="dropdown-item" to="/post-responsibility">Posts of Responsibility</Link></li>
                  {/* <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1n0K-Kazmi7hbQKZRF5ABatNRWiBzmvq-/view?usp=sharing">Posts of Responsibility</a></li> */}
                  <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1ZWg1a8K694TdP2LxDaVrf1P20NVoB3dC/view?usp=sharing">IKNS Facts Sheet</a></li>
                </ul>
              </li>
              {/* OUR SCHOOL */}
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown" to="#" data-bs-toggle="dropdown">Our School</Link>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to="/president-welcome">President's Welcome</Link></li>
                  <li><Link className="dropdown-item" to="/teacher-testimonial">Teacher's Testimonials</Link></li>
                  <li><Link className="dropdown-item" to="/the-school">The School</Link></li>
                  <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1XJrrrg6XDY7Lm9QI6ccj_0ws2Tl_7bOW/view?usp=sharing">IKNS Organization Chart</a></li>
                  <li><Link className="dropdown-item" to="/teaching-and-learning">Teaching and Learning at IKNS</Link></li>

                  <li><Link className="dropdown-item" to="#">IKNS Philosophy & Other Foundation Documents</Link>
                    <ul className="submenu dropdown-menu">
                      <li><Link className="dropdown-item" to="/philosophy-statement">Philosophy Statement</Link></li>
                      <li><Link className="dropdown-item" to="/mission-vision">Mission & Vision</Link></li>
                      <li><Link className="dropdown-item" to="/core-values">IKNS Core Values</Link></li>
                    </ul>
                  </li>

                  <li><Link className="dropdown-item" to="/graduate-profile">IKNS Graduate Profile</Link></li>
                  <li><Link className="dropdown-item" to="/governing-body">Governing Body</Link></li>
                  <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1MFXtGGUxa0rbGY-JFubzRQInJJR5NCLw/view">Constitution of the Board of Trustees</a></li>
                  <li><Link className="dropdown-item" to="/history">History</Link></li>
                  {/* <li><Link className="dropdown-item" to="/ptsa">P.T.S.A.</Link></li> */}
                  <li><Link className="dropdown-item" to="/strategic-plan">Strategic Plan</Link></li>
                  <li><Link className="dropdown-item" to="#">Education Training Quality Authority Reports</Link>
                    <ul className="submenu dropdown-menu">
                      <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1t6O7dGk6QokiUjOtSv01KeJKuiXgZsRA/view">Second Cycle Report, March 2017</a></li>
                      <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/179omjtyUSfAKstUFoY21W6BGq3ucOQZ1/view">First Cycle Report, September 2013</a></li>
                    </ul>
                  </li>

                  <li><Link className="dropdown-item" to="#">External Accreditation</Link>
                    <ul className="submenu dropdown-menu">
                      <li><Link className="dropdown-item" to="#">Accreditation Cycle 2023-2030</Link>
                        <ul className="submenu dropdown-menu">
                          <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/169Kb3Vwco2PCtrNwnMkXdXgEzI2ZYdRY/view">Application and School-Wide Action Research Proposal Approval</a></li>
                          <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1d4owhsgxxoDUm0Hq8eLRgGfBnx8h_l8D/view?usp=sharing">Application and School-Wide Action Research Proposal</a></li>
                        </ul>
                      </li>

                      <li><Link className="dropdown-item" to="#">Accreditation Cycle 2016-2023</Link>
                        <ul className="submenu dropdown-menu">
                          <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1ExgptQ_YNOI9o1lvcmf4Ze5hYTjljyXm/view">MSA Accreditation Award Letter, 2016</a></li>
                          <li><a className="dropdown-item" target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1RxpWQRILsrq0B6utWsRm8DTP5EQIPJSt/view">MSA Accreditation Team Report, 2016</a></li>
                        </ul>
                      </li>
                      <li><Link className="dropdown-item" to="/why-msa-accreditation">Why MSA Accreditation</Link></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown" to="#" data-bs-toggle="dropdown">IKNS Community</Link>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to="/student-life">Student's Life</Link></li>
                  <li><Link className="dropdown-item" to="/parents">P.T.S.A</Link></li>
                  {/* <li><Link className="dropdown-item" to="/faculty-and-staff">Faculty and Staff</Link></li> */}
                  <li><a className="dropdown-item" target="_blank" rel="noreferrer" href=" https://www.instagram.com/iknsalumni">Alumni</a></li>
                  <li><Link className="dropdown-item" to="/bahrain-the-country">Bahrain the Country</Link></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown" to="#" data-bs-toggle="dropdown">Academics</Link>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to="/pyp">IB Primary Years Programme (KG1-G5)</Link></li>
                  <li><Link className="dropdown-item" to="/myp">IB Middle Years Programme (G6-G10)</Link></li>
                  <li><Link className="dropdown-item" to="/ibdp">IB Diploma Programme (G11-G12)</Link></li>
                  <li><Link className="dropdown-item" to="/american-high-school-diploma">American High School Diploma (G11-G12)</Link></li>
                  <li><Link className="dropdown-item" to="/ib-results">IBDP Results</Link></li>
                  <li><Link className="dropdown-item" to="/college-placement-and-career-counseling">College Placement and Career Counseling</Link></li>
                  <li><Link className="dropdown-item" to="/high-school-profile">High School Profile</Link></li>
                  <li><Link className="dropdown-item" to="/graduation-requirements">Graduation Requirements</Link></li>
                  <li><Link className="dropdown-item" to="/ib-results">External Exam Results</Link></li>
                  <li><Link className="dropdown-item" to="/academic-integrity">Academic Integrity</Link></li>
                </ul>
              </li>
              <li className="nav-item"><Link className="nav-link" to="/admission">Admissions</Link></li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown" to="#" data-bs-toggle="dropdown">Media</Link>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to="/photo-gallery">Photo Gallery</Link></li>
                  <li><Link className="dropdown-item" to="/video-gallery">Video Gallery</Link></li>
                  <li><a href="https://www.ikns.edu.bh/IKNS-brochure-2023-October.pdf" className="dropdown-item" target="_blank" rel="noreferrer">IKNS Brochure</a></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown" to="#" data-bs-toggle="dropdown">Resources</Link>
                <ul className="dropdown-menu">
                  <li><a href="https://ikns.info" className="dropdown-item" target="_blank" rel="noreferrer">Faculty & Staff Portal</a></li>
                  <li><a href="https://ikns.info" className="dropdown-item" target="_blank" rel="noreferrer">Powerschool Tutorial</a></li>
                  <li><a href="https://ikns.info" className="dropdown-item" target="_blank" rel="noreferrer">Schoology Tutorial</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>


      {/* <div className="modal fade" id="schoolCalModal" tabIndex="-1" aria-labelledby="schoolCalModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="schoolCalModalLabel">School Calendar</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="alert alert-secondary" role="alert">
                <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1LIZCb2EK2-kb3dC57qmSnDAPYQ1QYCzn/view?usp=sharing"><i className="fa-solid fa-file-pdf"></i> School Calendar 2022-2023</a>
              </div>
              <div className="alert alert-secondary" role="alert">
                <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1ke-3QB17r2DF7mVm78uAniJZkr5-FTDo/view?usp=sharing"><i className="fa-solid fa-file-pdf"></i> School Calendar 2023-2024</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="bellschedModal" tabIndex="-1" aria-labelledby="bellschedModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="bellschedModalLabel">IKNS Bell Schedule Summary 2023-2024</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              <p className="text-sm">On Tuesdays, students in Grades 1 to 12 follow a short day schedule where they are dismissed earlier than the rest of the week. This adjustment has been made to allocate time within the schedule for weekly collaborative planning meetings among our faculty members.</p>
              <p className="text-sm">The Tuesday bell schedule is referred to as the Short Day Schedule and the bell schedule for Sunday, Monday, Wednesday and Thursday is referred to as the Regular Day Schedule. Below are the Starting and Dismissal timings for students in the different sections of the school, for both the regular school day and and the short day.</p>
              <p className="text-sm">The KG students follow a regular schedule Sunday to Thursday.</p>

              <table className="table table-bordered text-xs align-middle">
                <thead>
                  <tr className="align-middle text-center">
                    <th scope="col">School Section</th>
                    <th scope="col">Starting Time</th>
                    <th scope="col">Regular Day Dismissal Time</th>
                    <th scope="col">Short Day Dismissal Time</th>
                    <th scope="col">After School Activities Time (Regular Day)</th>
                    <th scope="col">After School Activities Time (Short Day)</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr>
                    <td>Kindergarten<br />KG 1 & 2</td>
                    <td>7:40 AM</td>
                    <td>12:30 PM</td>
                    <td>Not applicable</td>
                    <td>12:30 PM - 1:50 PM</td>
                    <td>12:30 PM - 1:10 PM</td>
                  </tr>

                  <tr>
                    <td className="text-center">Elementary School<br />Grades 1 - 5</td>
                    <td>7:40 AM</td>
                    <td>1:50 PM</td>
                    <td>1:10 PM</td>
                    <td>2:00 PM - 2:50 PM</td>
                    <td>1:20 PM - 2:10 PM</td>
                  </tr>

                  <tr>
                    <td className="text-center">Middle School<br />Grades 6 to 8</td>
                    <td>7:45 AM</td>
                    <td>2:55 PM</td>
                    <td>1:25 PM</td>
                    <td>3:10 PM - 4:10 PM</td>
                    <td>1:40 PM - 2:40 PM</td>
                  </tr>
                  <tr>
                    <td className="text-center">Secondary School<br />Grades 9 to 12</td>
                    <td>7:45 AM</td>
                    <td>2:55 PM</td>
                    <td>1:25 PM</td>
                    <td>3:10 PM - 4:10 PM</td>
                    <td>1:40 PM - 2:40 PM</td>
                  </tr>
                </tbody>
              </table>




              <div className="alert alert-secondary" role="alert">
                <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1QP2LbEHgxPiZIZiJhlC79BaUTtiog2hK/view?usp=sharing"><i className="fa-solid fa-file-pdf"></i> KG and Elementary Bell Schedule</a>
              </div>

              <div className="alert alert-secondary" role="alert">
                <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1x0Zqm6p8z_zrbMmzxptPD6YwqNKbUes9/view?usp=sharing"><i className="fa-solid fa-file-pdf"></i> Middle and Secondary Bell Schedule</a>
              </div>

              <div className="alert alert-secondary" role="alert">
                <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/11eXTNyVDrherHCqmANJh0zmQFZ_r23w2/view?usp=sharing"><i className="fa-solid fa-file-pdf"></i> Ramadan Bell Schedule</a>
              </div>

            </div>
          </div>
        </div>
      </div> */}

    </div>
  )
}

