import { Link,useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/strategic-plan';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const StrategicPlan20152022 = () => {
  const location = useLocation();

  return (
    <div>
      <Navigation />

      <div id="strategic_plan" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
        <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Our School</Link>
                  </li>                 
                  <li className="breadcrumb-item">
                    <Link to="#">
                      <strong>Strategic Plan</strong>
                    </Link>
                  </li>
                </ol>
                <h4 className="mt-5 page-title">
                Strategic Plan Executive Summary 2015-2022
                </h4>
              </div>
            </div>


          
            <p className="mt-3">The strategic plan of 2015-2022 continues a journey that began more than 30 years ago. The vision of IKNS is to be a high performing bilingual school where students and educators are accomplished life-long learners; a school that actively promotes a culture that values a strong sense of belonging to the Kingdom of Bahrain and open to the world.</p>
            <p>In the previous strategic plan for 2009-2014, the school secured the foundation of this vision. The plan recognized a need to establish a common language for all stakeholders to enhance transparency and accountability. This direction led the school to start reviewing, updating as well as modernizing its policies and procedures, curricula, and appraisal systems according to the best international principles and practices.</p>

            <p>The new seven-year strategic plan focuses on four goals: (1) Improve Student Achievement; (2) Educator Development; (3) Manage Resources; (4) School Culture.</p>
            <p>The strategic plan is a comprehensive one. Achieving our vision depends on achieving these goals. The four goals are aligned to strategic objectives and implementation initiatives that will drive our thinking and actions in the coming seven years. Ultimately, the achievement of these four goals will serve each IKNS family and the Bahraini community in general by graduating bilingual life-long learners ready for university study and prepared to assume leading roles at the national and international levels.</p>
            <p>The strategic goals provide guidance for the IKNS leadership in the development of policies and procedures, objectives, and initiatives to close the gap between current and desired performance. The map in the following pages communicates how the goals, objectives and initiatives fit together to achieve our strategic vision.</p>

            <p className="content-title mt-5">IKNS Mission Statement</p>
            <p>As a recognized leading school in the region, IKNS offers rigorous bilingual (Arabic and English) educational programmes that are based on the International Baccalaureate mission. The learning experiences of students are enhanced through differentiated instruction and the use of appropriate educational technologies, and the teaching experiences of faculty members are refined through continuous professional training.</p>


            <p className="content-title mt-5">IKNS Vision Statement</p>
            <p>IKNS is a community of learners that is dedicated to helping its members becoming knowledgeable, inquirers, thinkers, communicators, risk-takers, principled, caring, open-minded, balanced, and reflective.</p>

<p className="content-title mt-5">Goal 1: Improve Student Achievement</p>
<p>IKNS students will be college and career ready and accomplished bilingual life-long learners who have a strong sense of belonging to Bahrain</p>

<p className="mt-3"><u>Strategic Objectives</u></p>
<ol>
  <li>Deliver a rigorous standard - based curriculum</li>
  <li>Continue to develop and invest in differentiated instruction</li>
</ol>


<p className="mt-3"><u>Key Performance Indicators</u></p>
<ul>
  <li>Student Achievement in the Measure of Academic Performance (MAP) in English, Mathematics and Science</li>
<li>Student Achievement in the International Baccalaureate Diploma Program</li>
<li>Student Attendance</li>
<li>Students Results in SAT, TOEFL and IELTS</li>
<li>IKNS End of Year School results</li>
<li>University Acceptances</li>
<li>Community Service Participation</li>
</ul>



<p className="content-title mt-5">Goal 2: Educator Development</p>
<p>Recruit and retain highly-qualified educators and continually develop, recognize and compensate staff</p>

<p className="mt-3"><u>Strategic Objectives</u></p>
<ol>
  <li>Recruit and retain highly-qualified educators</li>
  <li>Continually develop, recognize and compensate staff</li>
</ol>

<p className="mt-3"><u>Key Performance Indicators</u></p>
<ul>
  <li>Parents and Students Surveys</li>
  <li>Teacher and Leader retention</li>
<li>Professional Development Offerings</li>
</ul>


<p className="content-title mt-5">Goal 3: Manage Resources</p>
<p>Continue to improve efficiency while also making decisions that are grounded in the strategic academic direction and data.</p>

<p className="mt-3"><u>Strategic Objectives</u></p>
<ol>
  <li>Prioritize resources based on student need</li>
  <li>Continually improve and maintain facilities</li>
</ol>

<p className="mt-3"><u>Key Performance Indicators</u></p>
<ul>
  <li>Spending Directed to Instruction</li>
  <li>Technology to Support Teaching and Learning</li>
  <li>School Safety and Security</li>
</ul>




<p className="content-title mt-5">Goal 4: School Culture</p>
<p>Continue to build trust and promote connections with the community and continue to engage all stakeholders who are invested in the vision of IKNS</p>

<p className="mt-3"><u>Strategic Goals</u></p>
<ol>
  <li>Promote a caring culture of trust and collaboration</li>
  <li>Communicate and engage with families and stakeholders</li>
</ol>


<p className="mt-3"><u>Key Performance Indicators</u></p>
<ul>
<li>IKNS communication and Engagement with School Families and the community at large.</li>
<li>Staff Engagement</li>
<li>Employee Attendance
</li>
</ul>


              <div className="contact-section col-lg-12 mx-auto">
              <h4 className="mt-3 page-title">Contact Us</h4>
              <p>If you have questions about the IKNS Strategic Plan, we are here to help.</p>
              <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
              </div>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
