import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/student-life';
import {useState} from 'react'
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const StudentLife = () => {
  
  const location = useLocation();
  const [modalState, setModalstate] = useState({ ytId: "", isOpen: false });

  const handleClick = (ytId, isOpen) => {
    setModalstate({ ytId, isOpen });
    console.log(setModalstate);
  };

  return (
    <div>
      <Navigation/>
      <div id="student_life" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">IKNS Community</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Students&#39; Life</strong></Link></li>
                  </ol>
                </div>        
              </div>
              

              
              <h4 className="mt-3 page-title text-center">Students&#39; Life</h4>
              <p className="text-center">
              Students at Ibn Khuldoon National School get involved in several initatives that enhance their 
              leadership and organisational skills, all while having fun and making friends for life.
              </p>


              <div className="col-md-12 mx-auto">

              <div className="mx-auto col-lg-8 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("-02-ZL4Y04w", true)}>
                      <img src={require('../../assets/img/student_life/video_thumbnail/btv_interview.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <small className="d-block">Team Nasser, BTV Interview, House Renovation</small>
                          </p>
                      </div>
                    </div>                  
                  </div>                          




                <div className="row mt-5">
                  
                  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("cDRskxR1WK8", true)}>
                      <img src={require('../../assets/img/student_life/video_thumbnail/trip_to_india.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <small className="d-block">Me 2 We Trip to India, February 2020</small>
                          </p>
                      </div>
                    </div>                  
                  </div>
           
                  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("oyB9JarIJQ8", true)}>
                      <img src={require('../../assets/img/student_life/video_thumbnail/ikns_student_2019.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <small className="d-block">IKNS Students, 2019</small>
                          </p>
                      </div>
                    </div>                  
                  </div>


                  
                  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("KgZGmTIXu6U", true)}>
                      <img src={require('../../assets/img/student_life/video_thumbnail/ikns_student_2018.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <small className="d-block">IKNS Class of 2018 Dedications</small>
                          </p>
                      </div>
                    </div>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalVideo channel='youtube' autoplay isOpen={modalState.isOpen} videoId={modalState.ytId} onClose={() => setModalstate(false)} />


      <Footer/>
    </div>
  )
}

