import { Link } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const HighSchoolProfile = () => {
  

  return (
    <div>
      <Navigation/>
      <div id="highschool_profile" className="container-fluid col-md-12 page-header"></div>

        <div id="one-page" className="wrapper">


              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>High School Profile</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">High School Profile</h4>
                </div>        
              </div>
              {/* <p>.</p> */}
              <p>
                Ibn Khuldoon National School is fully accredited by the Middle States 
                Association of Colleges and Schools Since 1994 (most recent re-accreditation 
                in 2016), and the Ministry of Education in Bahrain since its inception in 1983. 
                The school is authorized by the International Baccalaureate Organization to 
                offer the Diploma Program since 1990 (Most recently reviewed in 2018), the 
                Middle Years Program (authorized in 2022) and the Primary Years Program 
                (authorized in 2020). IKNS is also part of the UNESCO Associated Schools 
                Network (ASPnet). The Bahrain National "Education and Training Quality Authority" 
                (BQA) awarded the school a rating of "Outstanding" in September 20213 and March 
                2017. In November 2020, the Authority awarded the School Category "A" for the 
                Evaluation of School Practices During Exceptional Circumtances. 
              </p>
              
              <p><a href="https://drive.google.com/file/d/1Z1JcH5mfU6Ycul9_uidmeg2sF8_zm5Zp/view" target="_blank" rel="noreferrer" className="text-primary">
                Ibn Khuldoon National School High School Profile 2022-2023
              </a></p>

              <p><a href="/" target="_blank" rel="noreferrer" className="text-primary">
                Ibn Khuldoon National School High School Profile 2021-2022
              </a></p>


        </div>
      <Footer/>
    </div>
  )
}


