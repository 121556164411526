import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/external-exam-results';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const Timss = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="external_exam_results" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#">External Exam Results</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>The International Measures Trends in Mathematics and Science Study (TIMSS)</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">The International Measures Trends in Mathematics and Science Study (TIMSS)</h4>
                </div>        
              </div>

              {/* <p>.</p> */}
              <p>
                TIMSS measures trends in mathematics and science achievement in 
                the fourth and eighth grades and, at the same time, monitors curricular 
                implementation to identify promising instructional practices. TIMSS is 
                conducted on a regular 4-year cycle. The study aims to collect a rich 
                array of background information to provide comparative perspectives on 
                trends in achievement in the context of different educational systems, 
                school organizational approaches, and instructional practices.
              </p>

              <p>
              Recent participation of IKNS in TIMSS was conducted in 2015 and 2019.
              </p>
              
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


