import { Link } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import "../../styles/innerpage.css";
import '../../styles/headers.css';

export const StudentsDeviceSpecifications = () => {

  return (
    <div>
      <Navigation/>
      <div id="students_device_specifications" className="container-fluid col-md-12 page-header"></div>
        <div id="one-page" className="wrapper">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Student Device Specifications and Minimum Requirements</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">Student Device Specifications and Minimum Requirements</h4>
                </div>
          
                <div className="col-md-2 text-center mt-3 d-none d-sm-block d-sm-none d-md-block">
                  <a href="https://drive.google.com/file/d/1WikR4z5_VQnLkihNv2u4zyiDZqbffqu5/view" target="_blank" rel="noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16"> <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/> <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/> </svg> Print
                  </a>
                </div>
              </div>

              <p>IKNS implements a Bring Your Own Device (BYOD) Policy with regard to the students' computing devices.</p>
              <p>The students in Grades 3-12 are expected to bring their own computing devices to school every day. This 
                personal computing device needs to meet the device specifications that are recommended by the school as 
                listed below.</p>
              
              <p>The device specifications identify the minimum system requirements/hardware specifications for student 
                devices and offer guidance on some suggested makes and models so that parents are fully informed about 
                requirements.</p>

              <p className="content-title title-spacer block">Educational Software requirements:</p>
              <p>The device must meet the following functional requirements pertaining to software</p>
            
              <table className="table table-bordered">
              <tbody>
                <tr>
                  <td className="col-md-3 align-middle text-center bg-secondary">
                    <strong>Educational Software</strong>
                    </td>
                  <td className="col-md-9 align-baseline">
                    <ul>
                      <li>Web browser; Google Chrome, Mozilla Firefox, Safari</li>
                      <li>Word Processor, Spreadsheet & Presentation Package: 
                        Examples include Microsoft Office, Apple iWork, OpenOffice, and Google Apps.</li>
                      <li>PDF Reader Adobe Acrobat Reader or compatible</li>
                      <li>Java (latest versions)</li>
                      <li>Additional subject-specific software may be required.</li>
                    </ul>  
                  </td>
                </tr>
              </tbody>
              </table>

              <p className="content-title title-spacer block">Important Considerations:</p>
              <p>When purchasing a tablet, iOS or Android-based, please make sure to buy a 
                physical keyboard for the students to be able to perform specific tasks 
                that may be requested by their teachers.</p>
              <p>In the Elementary School, the students experience difficulty with typing 
                applications and other applications they access on iPads and tablets. 
                We recommend purchasing a Bluetooth keyboard to use with these 
                devices, should they be your option of choice.</p>

              <p>Windows 10 tablets work well with a keyboard. IKNS has used Apple computers 
                for many years with great success.</p>
              
              <p>Most Chromebooks as well as Android tablets will meet the minimum requirements 
                set forth by IKNS, however, please note that many devices of this nature require 
                full-time access to the Internet in order to access the applications. If you 
                expect your child to work in areas without wireless Internet access, please ensure 
                the device is capable of working in “offline mode” before making a purchase decision. 
                These devices are also limited by applications that are available in Google Apps 
                and what is available in the Google Play Store.</p>

              <p>Please ensure your child's device is documented with its serial number at home. 
                Place a label with the student's name, grade and homeroom on the device along 
                with all cables and accessories. Password protection of the device is essential.</p>

              <p className="mt-5"><strong>Smart phones are not permitted in the classroom.</strong></p>

              <p className="content-title title-spacer block">Device Recommendation:</p>
              <div className="table-responsive">
              <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="bg-secondary" scope="col">Device Type</th>
                  <th className="bg-secondary" scope="col">Windows Laptop</th>
                  <th className="bg-secondary" scope="col">Windows Tablet</th>
                  <th className="bg-secondary" scope="col">Apple Laptop</th>
                  <th className="bg-secondary" scope="col">Apple Tablet</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center bg-secondary"><strong>Operating System</strong></td>
                  <td className="text-center">Windows 10</td>
                  <td className="text-center">Windows 10</td>
                  <td className="text-center">OS X 11 or higher</td>
                  <td className="text-center">iOS 14 or higher</td>
                </tr>
                <tr>
                  <td className="text-center bg-secondary"><strong>Wireless Capability</strong></td>
                  <td className="text-center">802.11a/b/g/n/ac</td>
                  <td className="text-center">802.11a/b/g/n/ac</td>
                  <td className="text-center">802.11a/b/g/n/ac</td>
                  <td className="text-center">802.11a/b/g/n/ac</td>
                </tr>
                <tr>
                  <td className="text-center bg-secondary"><strong>Screen Size</strong></td>
                  <td className="text-center">13"</td>
                  <td className="text-center">10"</td>
                  <td className="text-center">13"</td>
                  <td className="text-center">10"</td>
                </tr>
                <tr>
                  <td className="text-center bg-secondary"><strong>Storage Capacity</strong></td>
                  <td className="text-center">128 GB or higher</td>
                  <td className="text-center">64 GB or higher</td>
                  <td className="text-center">128 GB or higher</td>
                  <td className="text-center">64 GB or higher</td>
                </tr>
                <tr>
                  <td className="text-center bg-secondary"><strong>Memory</strong></td>
                  <td className="text-center">8 GB</td>
                  <td className="text-center">4 GB</td>
                  <td className="text-center">8 GB</td>
                  <td className="text-center">4GB</td>
                </tr>
                <tr>
                  <td className="text-center bg-secondary"><strong>Battery Life</strong></td>
                  <td className="text-center">6 hours</td>
                  <td className="text-center">6 hours</td>
                  <td className="text-center">6 hours</td>
                  <td className="text-center">6 hours</td>
                </tr>
                <tr>
                  <td className="text-center bg-secondary"><strong>Suggested Accessories</strong></td>
                  <td className="text-center">Protective case/cover Headphones Charger</td>
                  <td className="text-center">Protective case/cover Headphones Charger</td>
                  <td className="text-center">Protective case/cover Headphones/Charger</td>
                  <td className="text-center">Protective case/cover Headphones/Charger</td>
                </tr>
                <tr>
                  <td className="text-center bg-secondary"><strong>Device Example</strong></td>
                  <td className="text-center">Dell XPS Lenovo/Thinkpad</td>
                  <td className="text-center">Microsoft Surface</td>
                  <td className="text-center">Apple MacBook</td>
                  <td className="text-center">Apple iPad</td>
                </tr>
              </tbody>
              </table>
              </div>

              <p className="content-title title-spacer block">The device must meet the following requirements:</p>
              <table className="table table-bordered">
              <tbody>
                <tr>
                  <td className="col-md-3 align-middle text-center bg-secondary"><strong>Form Factor</strong></td>
                  <td className="col-md-9">Laptop, Tablet or convertible device A convertible device must have a physical English/Arabic keyboard 
                  attachment with separate keys for A - Z and 0 - 9 and which physically move when depressed. 
                  It must run one of the specified operating systems.</td>
                </tr>
                <tr>
                  <td className="col-md-3 align-middle text-center bg-secondary"><strong>Physical Dimensions</strong></td>
                  <td className="col-md-9">Minimum Screen Size: 9"<br />Maximum Screen Size: 15"</td>
                </tr>
                <tr>
                  <td className="col-md-3 align-middle text-center bg-secondary"><strong>Operating System</strong></td>
                  <td className="col-md-9">Microsoft Windows 10 or newer<br /> Apple MacOS X 11 or higher<br /> Apple iOS 14 or higher</td>
                </tr>
                <tr>
                  <td className="col-md-3 align-middle text-center bg-secondary"><strong>Wireless Compatibility</strong></td>
                  <td className="col-md-9">
                  "Dual Band Wireless", "802.11abgn", "802.11agn", "802.11ac" or "Gigabit Wireless"
                  </td>
                </tr>
                <tr>
                  <td className="col-md-3 align-middle text-center bg-secondary"><strong>Battery Life</strong></td>
                  <td className="col-md-9">
                  Advertised battery life of at least six hours
                  </td>
                </tr>
                <tr>
                  <td className="col-md-3 align-middle text-center bg-secondary"><strong>Additional Recommendations</strong></td>
                  <td className="col-md-9">
                  <ul>
                    <li>Maximum weight: 2kg</li>
                    <li>Minimum RAM (laptops): 8GB</li>
                    <li>Disk configuration (laptops): Solid State disk</li>
                  </ul>
                  </td>
                </tr>
              </tbody>
              </table>

              <p className="text-danger text-center"><strong>Kindly note that any loss or damage to the device is the sole responsibility of the student</strong></p>


        </div>
      <Footer/>
    </div>
  )
}


