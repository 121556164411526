import { Link } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import '../../styles/innerpage.css';

export const VirtualLearningBlog = () =>{
  return (
    <div>
      <Navigation/>
      <div id="virtual_learning_period" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              </div>   
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Activities During the Virtual Learning Period</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">Activities During the Virtual Learning Period</h4>
                </div>
          
                {/* <div className="col-md-2 text-center mt-3 d-none d-sm-block d-sm-none d-md-block">
                  <a href="" target="_blank" rel="noreferrer">
                  <i className="fa-solid fa-file-pdf fa-2x"></i><br />
                  <small>View PDF</small>
                  </a>
                </div> */}
              </div>


              <h6 className="sub-title">School Competitions</h6>
              <img src={require('../../assets/img/virtual_learning/trade_quest_check.png')} className="img-fluid" alt='...'/>  
              <p>
                <em>IKN continued to participate in the TradeQuest virtual activities and 
                  presentation as organized by Bahrain Bourse. The IKNS team won the third Award of the TradeQuest Quest competition.</em></p>


              <div className="row mt-5">
                <div className="col-md-6">
                <p className="sub-title"><strong>INJAZ Bahrain - The Company Program</strong></p>
                <p>IKNS continued to participate in the Young Entrepreneurship 
                Competition organized by INJAZ Bahrain</p>
                <p>The INKS Team won the first place, Company of the Year, and is now 
                representing Bahrain at the INJAZ ARAB regional competition that is taking 
                place virtually.</p>
                </div>
                <div className="col-md-6">
                <img src={require('../../assets/img/virtual_learning/injaz.png')} className="img-fluid" alt='...'/>  
                </div>
              </div>
          
              <img src={require('../../assets/img/virtual_learning/injaz2.png')} className="mt-3 img-fluid" alt='...'/>  

              <p className="mt-5"><span className="sub-title">Sahim App</span> that connects social NGOs in Bahrain with potential donors on one platform</p>
              <a href="https://www.youtube.com/watch?v=Htlh9O9vfS8" target="_blank" rel="noreferrer">
              <img src={require('../../assets/img/virtual_learning/sahim_app.png')} className="img-fluid" alt='...'/>  
              </a>
            
              <p className="mt-5 sub-title">Arabic Reading Challenge</p>
              <img src={require('../../assets/img/virtual_learning/arabic_reading.png')} className="img-fluid" alt='...'/>  


              <p className="mt-5 sub-title">The Debate Club</p>
              <p>The club meets on a weekly basis. They have plans to join debate teams in other schools.</p>
              <img src={require('../../assets/img/virtual_learning/debate.png')} className="img-fluid" alt='...'/>  
              <img src={require('../../assets/img/virtual_learning/debate2.png')} className="img-fluid" alt='...'/>  


              <div className="row mt-5">
                <div className="col-md-8">
              <p className="sub-title">Honor Society Students Tutor Peers in Schoology and Academics</p>
              <p>A group of Honor Society Students have come together to plan tutoring for their 
              Peers in academics and the use of Schoology platform.</p>
              <p>Virtual tutoring meeting are held every Tuesday after school, from 1:30 to 2:30pm.</p>
              <p>The session this week (Tuesday, 24 November) focused on the three sciences. Sessions
              In the previous weeks focused on the languages and mathematics.</p>
              </div>
              <div className="col-md-4">
              <a href="https://www.youtube.com/watch?v=rmu5IV9nCCc&feature=emb_title" target="_blank" rel="noreferrer">
              <img src={require('../../assets/img/virtual_learning/schoology_tutor.png')} className="img-fluid" alt='...'/>  
              </a>
              </div>
              </div>

              <p className="mt-5 sub-title">
              IB CAS (Creativity Action & Service) at home: Origami/ Crochet/ Painting/ Digital Design/ Cooking
              </p>
              <img src={require('../../assets/img/virtual_learning/ib_cas.png')} className="img-fluid" alt='...'/>  


              <p className="mt-5 sub-title">
              IB CAS -IB Students Help Sudan  ese Victims
              </p>
              <p>IB Students, as part of the IB Community, Action & Service hours had 
                the opportunity to help the victims of the floods in Sudan after they 
                suffered from the terrible flood season. They were left other necessities. 
                Some students collaborated to donate boxes filled with clothes. 
                <em>"I felt happy and thankful for having the chance to help others in 
                times of need."</em></p>
                <img src={require('../../assets/img/virtual_learning/ib_cas2.png')} className="mb-3 img-fluid" alt='...'/>  


            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


