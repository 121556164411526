import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/pyp';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const PrimaryYearsProgramme = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="pyp" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>The IB Primary Years Programme (KG1-G5)</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">The IB Primary Years Programme (KG1-G5)</h4>
                </div>        
              </div>

              <p>
              The IB connects a worldwide community of learners who celebrate a common 
              humanity and share a belief that education can help to build a better 
              and more peaceful world. Schools offering the Primary Years 
              Programme (PYP) bring a shared commitment to the IB's mission through 
              the learning community.
              </p>
              
              <p>
                Ibn Khuldoon National School is an authorized school for the 
                International Baccalaureate (IB) Primary Years Programme (PYP) 
                as of June 2020, and pursuing authorization as an IB World School. 
                IB World Schools share a common philosophy—a commitment to improving 
                the teaching and learning of a diverse and inclusive community of 
                students by delivering challenging, high-quality programmes of 
                international education that share a powerful vision.
              </p>
             
              <p className="content-title mt-5">
              What does the PYP stand for
              </p>
              
              <p>
                PYP stands for Primary Years Programme. The PYP is for children 
                aged 3 - 12 (KG1 - G5). It aims to nurture and develop young 
                students as caring and active participants in a lifelong journey 
                of learning.
              </p>

              <p>
                Engaging learning environments that inspire the imagination and 
                creativity of learners, and encourage the process of inquiry, 
                action and reflection. These environments provide opportunities 
                for emerging inquiries; students may take their learning in new 
                and unexpected directions, developing and demonstrating the 
                attributes of the IB learner profile.
              </p>

              <div className="img-right col-md-6">
                <img src={require('../../assets/img/pyp/pyp-model-2018-en.png')} className="img-fluid" alt='pyp'/>              
              </div>

              <p className="content-title mt-5">
              The PYP Programme Model
              </p>
              
              <p>
                The PYP Programme model shows how the different aspects of 
                the PYP fit together to develop the curriculum framework.
              </p>
              
              <p>
                The PYP is an integrated curriculum that is relevant, challenging 
                and engaging for learners from Kindergarten to Grade 5. It is an 
                inquiry-driven, concept-based curriculum framework that actively 
                encourages students to ask questions and seek answers about the 
                world around them.
              </p>

              <p className="content-title mt-5">
                The Five Essential Elements of the PYP
              </p>
            
              <p>
                There are five essential elements to the IB PYP curriculum. 
                These are Concepts, Skills, Knowledge, Attitudes and Action. 
                The table below introduces what each of them means.
              </p>
            
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Concepts</td>
                    <td>What ideas do we want students to explore?</td>
                  </tr>
                  <tr>
                    <td>Skills</td>
                    <td>What do students need to be able to succeed in a changing world?</td>
                  </tr>
                  <tr>
                    <td>Knowledge</td>
                    <td>What significant relevant subjects matter do we want students to know?</td>
                  </tr>
                  <tr>
                    <td>Attitudes</td>
                    <td>What fundamental values, feelings and beliefs do we want students to have?</td>
                  </tr>
                  <tr>
                    <td>Action</td>
                    <td>How do we want students to act?</td>
                  </tr>
                  </tbody>
                </table>
                <small className="d-block">Source: <a href="https://drive.google.com/file/d/1RifjuVQuPJeZUjsYGYPyiH3BjbYZjgkh/view" target="_blank" rel="noreferrer" className="text-primary">
                  Iborganization. "Written Curriculum." International Baccalaureate®, 2005, www.ibo.org/programmes/primary-years-programme/curriculum/written-curriculum.</a></small>
                  
                <p className="content-title mt-5">
                  The First Essential Element: Knowledge
                </p>
                <p>
                  The PYP has six transdisciplinary themes that students investigate 
                  throughout the school year. These themes are globally significant 
                  and support the acquisition of concepts that are interconnected. 
                  The themes can be addressed in all subject areas, and can be applied 
                  to real life. They capture human commonalities that are significant 
                  and relevant across cultures, geographic regions, and student 
                  learning stages.
                </p>

                <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Who We Are</td>
                    <td>Inquiry into the nature of the self; beliefs and values; person, physical, mental, social and spiritual health; and human relationships.</td>
                  </tr>
                  <tr>
                    <td>Where We Are in Place and Time</td>
                    <td>Inquiry into our orientation in place and time; personal histories; the discoveries and explorations of humankind; and the interconnectedness of individuals and civilizations.</td>
                  </tr>                  
                 
                  <tr>
                    <td>How We Express Ourselves</td>
                    <td>Inquiry into the ways in which we discover and express ideas, feelings, nature, culture, beliefs and values.</td>
                  </tr>                  

                  <tr>
                    <td>How We Organise Ourselves</td>
                    <td>Inquiry into the interconnectedness of human-made systems and communities; the structure and function of organizations; and their impact on humankind and the environment.</td>
                  </tr>                  
                  <tr>
                    <td>How the World Works</td>
                    <td>Inquiry into the natural world and its laws, the interaction between the natural world and human societies; the impact of scientific and technological advances on society and on the environment.</td>
                  </tr>                  
                  <tr>
                    <td>Sharing the Planet</td>
                    <td>Inquiry into rights and responsibilities in the struggle to share finite resources with other people and other living things; access to equal opportunities; and peace and conflict resolution.</td>
                  </tr>                  
                  </tbody>
                </table>
                <small className="d-block">Source: <a href="https://www.ibo.org/information-for-parents/pyp-for-parents" target="_blank" rel="noreferrer" className="text-primary">
                Iborganization. "PYP for Parents." International Baccalaureate®, 2005, www.ibo.org/information-for-parents/pyp-for-parents.
                  </a></small>


                <p className="content-title mt-5">
                  The Second Essential Element: Skills
                </p>
                <p>
                  The world in which we live is constantly changing and evolving. We, 
                  therefore, need to equip our students with sets of relevant skills 
                  that help them to thrive and adapt in any profession, challenges or 
                  situations they find themselves in.
                </p>

                <p>
                  The PYP identifies <strong>five categories</strong> of interrelated skills, 
                  that are referred to as Approaches To Learning (ATL), that aim to 
                  support the learning of students of all ages.
                </p>

                <p>
                  ATL supports the IB belief that a large influence on a student's 
                  education is not only what you learn but also how you learn. They 
                  are intrinsically linked with the attributes of the IB learner 
                  profile, aiming to enhance student learning and assist student 
                  preparation for life after high school.
                </p>

                <p className="content-title mt-5">
                The Third Essential Element: Key Concepts
                </p>
                <p>
                  Concepts represent ideas that are broad, abstract, timeless and 
                  universal. The IB recognises eight key concepts that drive 
                  instructions through inquiry, questions, and investigation. 
                  The eight key concepts are as follows:
                </p>
                <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Form</td>
                    <td>What is it like?</td>
                  </tr>
                  <tr>
                    <td>Function</td>
                    <td>How does it work?</td>
                  </tr>                                
                  <tr>
                    <td>Change</td>
                    <td>How is it changing?</td>
                  </tr>                                
                  <tr>
                    <td>Causation</td>
                    <td>Why is it like this?</td>
                  </tr>                                
                  <tr>
                    <td>Connection</td>
                    <td>How is it connected to other things?</td>
                  </tr>                                
                  <tr>
                    <td>Perceptive</td>
                    <td>What are the points of view?</td>
                  </tr>                                
                  <tr>
                    <td>Responsibility</td>
                    <td>What is our responsibility?</td>
                  </tr>                                
                  <tr>
                    <td>Reflection</td>
                    <td>How do we know?</td>
                  </tr>                                
                  </tbody>
                </table>

                <p className="content-title mt-5">
                The Fourth Essential Element: Attitudes
                </p>
                <p>
                There are twelve attitudes we want students to value and exhibit. 
                They are interwoven throughout every aspect of the curriculum.
                </p>
                <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Appreciation</td>
                    <td>Cooperation</td>
                    <td>Empathy</td>
                    <td>Integrity</td>
                  </tr>
                  <tr>
                    <td>Commitment</td>
                    <td>Creativity</td>
                    <td>Enthusiasm</td>
                    <td>Respect</td>
                  </tr>
                  <tr>
                    <td>Confidence</td>
                    <td>Curiosity</td>
                    <td>Independence</td>
                    <td>Tolerance</td>
                  </tr>
                  </tbody>
                </table>

                <p className="content-title mt-5">
                The Fifth Essential Element: Action
                </p>
                <p>
                  Students are encouraged to reflect, to make informed 
                  choices and to take action that will help their peers, 
                  school staff, and the wider community.
                </p>
                <p>
                  These are student initiated responses to what they are 
                  understanding and learning about themselves and the world 
                  in which they live.
                </p>

            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


