import React from 'react'
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const AcademicIntegrityPolicy = () => {
  return (
    <div>
      <iframe
        class="fp-iframe"
        width="100%"
        height="684"
        src="https://heyzine.com/flip-book/8887255014.html"
        title="Introduction To WiseGPT"
        frameborder="0"
        allowfullscreen="allowfullscreen"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>


    </div>

  )
}
