import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import "../../styles/innerpage.css";
import '../../styles/headers.css';
import React from "react";
import FormComponent from '../../components/ContactForm';

export const ContactForm = () => {

  return (
    <div>
      <Navigation />
      <div id="facilities" className="container-fluid col-md-12 page-header-article"></div>

      <div id="one-page" className="wrapper">
       
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">
                      <strong>Contact Us</strong>
                    </Link>
                  </li>
                </ol>
               
              </div>
            </div>

            <div className="col-md-12">
              <div className="row">                
                  <div className="col-md-6">
                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <iframe title="map" id="gmap_canvas" src="https://maps.google.com/maps?q=Ibn%20Khuldoon%20National%20School&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                        </iframe>
                      </div>
                    </div>
                  
                    <div className="row">
                      <div className="col-md-4">
                      <p className="small"><strong>WORKING HOURS</strong></p>
                      </div>

                      <div className="col-md-8 text-end">
                      <p className="small">Sunday - Thursday: 07:30 AM - 03:00 PM</p>
                      </div>
                    </div>

                    <div className="col-md-6 mt-5">
                      <p className="small"><strong>PHONE NUMBERS</strong></p>
                    </div>

                    <table className="table small">
                    <tbody>
                      <tr>
                        <td>Kindergarten</td>
                        <td className="text-end">+973 17 780 661 ext. 103</td>
                      </tr>
                      <tr>
                        <td>Elementary School</td>
                        <td className="text-end">+973 17 780 661 ext.  102</td>
                      </tr>
                      <tr>
                        <td>Middle School</td>
                        <td className="text-end">+973 17 780 661 ext. 101</td>
                      </tr>
                      <tr>
                        <td>Secondary School</td>
                        <td className="text-end">+973 17 780 661 ext. 100</td>
                      </tr>
                      <tr>
                        <td>Business Office</td>
                        <td className="text-end">+973 17 780 661 ext. 141</td>
                      </tr>
                      <tr>
                        <td>Fax</td>
                        <td className="text-end">+973 17 689 028</td>
                      </tr>

                      <tr>
                        <td>Email</td>
                        <td className="text-end">info@ikns.edu.bh</td>
                      </tr>

                      <tr>
                        <td>Postal Addess</td>
                        <td className="text-end">
                        <p>Ibn Khuldoon National School<br />
                          P.O. Box 20511,<br />
                          Manama,<br />
                          Kingdom of Bahrain</p>
                        </td>
                      </tr>

                      <tr>
                        <td>Street Addess</td>
                        <td className="text-end">
                        <p>Ibn Khuldoon National School<br />
                        Building no. 161, Road no. 4111,<br />
                        Block 841, Isa Town,<br />
                        Kingdom of Bahrain</p>
                        </td>
                      </tr>                      
                    </tbody>
                  </table>
                  </div>

              <div className="col-md-6">
              <FormComponent />                      
              </div>          
            </div>
          </div>          
        </div>
      <Footer />
    </div>
  );
};
