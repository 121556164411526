import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const AcademicIntegrity = () => {


  return (
    <div>
      <Navigation />
      <div id="academic_integrity" className="container-fluid col-md-12 page-header"></div>

      <div id="one-page" className="wrapper">
        <div className="row">


          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Academic</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>Academic Integrity Policy</strong></Link></li>
            </ol>
            <h4 className="mt-3 page-title">Academic Integrity Policy</h4>
          </div>
        </div>


        <p className="text-sm">Ibn Khuldoon National School (IKNS) is committed to academic integrity and strives to ensure that all IKNS learners and the school community are aware of the school&#39;s ethical codes, policies, procedures and the subsequent measures and consequences of breaching the academic integrity policy .</p>




        <p>
          <a target="_blank" className="text-link" rel="noreferrer" href="/academic-integrity-policy"><i className="fa-solid fa-file-pdf"></i> View Academic Integrity Policy</a>
        </p>


        {/* <iframe allowfullscreen="allowfullscreen" scrolling="no" class="fp-iframe" src="https://heyzine.com/flip-book/8887255014.html" style="border: 1px solid lightgray; width: 100%; height: 400px;"></iframe> */}

        <div className="contact-section col-lg-9 mx-auto mt-5">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the Academic Integrity Policy, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>



      </div>
      <Footer />
    </div>
  )
}


