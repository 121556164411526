import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const SchoolFees = () => {

  return (
    <div>
      <Navigation />

      <div id="school_fees" className="container-fluid col-md-12 page-header"></div>

      <div id="one-page" className="wrapper">
        <div className="row">


          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>School Fees</strong></Link></li>
            </ol>
            <h4 className="mt-3 page-title">School Fees for the School Year starting in September 2023</h4>
          </div>
        </div>

        <p>By the end of January 2020, the schools in the Kingdom of Bahrain were wisely
          instructed by the Ministry of Education to transform the teaching and learning
          from "on campus" to "distance learning". At that time and before the
          coronavirus was declared a pandemic, we were hopeful that its impact was
          temporary.</p>

        <p>In the two and a half school years since then, the schools in Bahrain and
          around the world had to move fast to face new realities. Schools have moral,
          ethical, philosophical and legal obligations to continue to put "education"
          on the table, which is a basic necessity to keep the fabric of any society
          intact. Teachers rose to the challenge and parents turned, in a blink of
          an eye, into tutors and general practitioners in addition to assuming the
          many other roles they play.</p>

        <p>Institutions and individuals took on the challenge and prevailed. We are
          now stronger than before and better equipped to tackle future challenges.</p>

        <p>We are grateful for the Government of Bahrain, first and foremost, for the
          health care the esteemed government provided to everyone who lives on this
          loving island. We also feel for the families and individuals who suffered
          the inevitable and pray for those who departed this world.</p>

        <p>We are also grateful for the financial support the esteemed government
          provided us with. Whether it is waving the water and electricity bills for
          three months or the wage support for Bahraini members of the faculty and
          staff. This was indeed helpful and kept us afloat.</p>

        <p>The school was able to minimize and cut down on expenses on many fronts.
          Yet there were also several other expenses, hidden and direct.</p>

        <p>We upgraded our learning management systems and IT services to continue to
          offer the teaching and learning, both on campus and virtually. We continued
          to provide the infirmary support to all students, faculty and staff to the
          best of our ability. We kept the school environment safe, clean and healthy
          for the students to enjoy learning and the teachers to teach. This school
          year, we resumed all school activities and encouraged all students to
          attend classes on campus. We also kept the distance learning option
          ongoing for the students and families who wanted to continue using it.
          I am glad to inform you that the on campus attendance in the fourth
          quarter of this school year was 95%.</p>

        <p>We also continued with our strategic plans and were able to receive the
          authorization of the International Baccalaureate to offer the Primary
          Years Programme and later the Middle Years Program.</p>

        <p>With regards to the school fees, the initial plan was to apply an
          increase for the school year starting in September 2022. However, the
          Board of Directors took a decision early this school year to keep
          the fees unchanged for another school year. We appreciate that
          families and businesses were impacted by the coronavirus and we do
          not want to add to their financial burdens. At the same time, to
          continue with the services we offer, we must apply an increase in
          the fees for the school year 2023-2024, which starts in September 2023.
          We believe that giving the parents over a year's notice would help
          them better manage their financial plans.</p>

        <p>Currently, we have a chunk of the fees that are due. I strongly urge the
          parents who have not paid their due fees to do so at their earliest
          convenience. The Business Office will continue to be open during the
          summer holiday if you want to drop by. Also, we will share with you soon
          a safe and secure payment gateway to facilitate the payment of fees.</p>

        <h4 className="page-title mt-5">School Fees for the School Year 2023-2024</h4>
        <div className="col-md-12 mx-auto">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="col-md-6 text-center bg-gray" scope="col">Installment Due Date</th>
                <th className="text-center bg-gray" scope="col">KGI</th>
                <th className="text-center bg-gray" scope="col">KGII</th>
                <th className="text-center bg-gray" scope="col">1-5</th>
                <th className="text-center bg-gray" scope="col">6-8</th>
                <th className="text-center bg-gray" scope="col">9-10</th>
                <th className="text-center bg-gray" scope="col">11-12</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>First Installment (Re-enrollment)</strong> <small className="d-block">(Invoice due in March)</small></td>
                <td className="text-center">1000</td>
                <td className="text-center">1000</td>
                <td className="text-center">1000</td>
                <td className="text-center">1000</td>
                <td className="text-center">1000</td>
                <td className="text-center">1000</td>
              </tr>

              <tr>
                <td><strong>Second Installment</strong><small className="d-block">(Invoice due in August)</small></td>
                <td className="text-center">1015</td>
                <td className="text-center">1040</td>
                <td className="text-center">1390</td>
                <td className="text-center">1495</td>
                <td className="text-center">1745</td>
                <td className="text-center">1990</td>
              </tr>

              <tr>
                <td><strong>Third Installment</strong> <small className="d-block">(Invoice due in January)</small></td>
                <td className="text-center">935</td>
                <td className="text-center">935</td>
                <td className="text-center">1260</td>
                <td className="text-center">1490</td>
                <td className="text-center">1740</td>
                <td className="text-center">1990</td>
              </tr>

              <tr>
                <td className="bg-gray"><span className="text-link">Total Annual School Fees</span></td>
                <td className="bg-gray text-center"><span className="text-link">2950</span></td>
                <td className="bg-gray text-center"><span className="text-link">2975</span></td>
                <td className="bg-gray text-center"><span className="text-link">3650</span></td>
                <td className="bg-gray text-center"><span className="text-link">3985</span></td>
                <td className="bg-gray text-center"><span className="text-link">4485</span></td>
                <td className="bg-gray text-center"><span className="text-link">4980</span></td>
              </tr>
            </tbody>
          </table>
        </div>



        <div className="col-md-12 mx-auto">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td className="col-md-6 text-xs">
                  The school fees for students in KG1 to Grade 5 include the cost of notebooks, textbooks
                  and educational software applications used to support the learning of the students, while
                  the students in Grades 6 to 12 are to buy their own textbooks and notebooks.
                </td>
                <td className="col-md-6 text-xs" dir="rtl" lang="ar">
                  تشمل الرسوم المدرسية الخاصة بصفوف الروضة األولى إلى الصف الخامس ثمن الدفاتر والكتب الدراسية
                  والتطبيقات التربوية المبرمجة التي تدعم تعلم الطلبة، في حين يقوم طالب صفوف السادس إلى الثاني عشر
                  بشراء الكتب المدرسية والدفاتر الخاصة بهم.
                </td>
              </tr>
              <tr>
                <td className="bg-gray text-center"><span className="text-link">Fees Additional</span></td>
                <td className="bg-gray text-center"><span className="text-link">رسوم إضافية</span></td>
              </tr>
              <tr>
                <td>
                  <ul className="text-xs normal-list">
                    <li>Yearly Locker Rental (BD20) to be paid by students in Grades 6 to 12 and is to be invoiced in August.</li>
                    <li>The cost of the material used in the Art, Drama and Pottery courses to be paid by the
                      students in grades 6 to 12 who are registered in any of these courses, and is to be
                      invoiced separately in the second semester.</li>
                    <li>The IBMYP and IBDP Examinations Fees are to be paid by the Grade 10 and Grade 12
                      students (respectively) who register to sit for these IB external examinations. These
                      fees are invoiced separately in October.</li>
                    <li>The Graduation Ceremony fee to be paid by the Grade 12 students who wish to
                      participate in the graduation ceremony.</li>
                  </ul>
                </td>
                <td>
                  <ul className="text-xs normal-list" dir="rtl" lang="ar">
                    <li>أجرة استخدام خزائن الطلبة )٢٠ دينار( ويدفعها سنويا طالب صفوف السادس إلى الثاني عشر وتضاف
                      قيمتها إلى الفاتورة التي تصدر في شهر أغسطس.</li>
                    <li>أثمان المواد التي يستخدمها طالب صفوف السادس إلى الثاني عشر الذين يدرسون مساقات الفن
                      والدراما والخزف، ويتم ارسال فاتورة منفصلة بهذه المبالغ خالل الفصل الثاني من العام الدراسي.</li>
                    <li>رسوم المشاركة في امتحانات البكالوريا الدولية الخارجية يدفعها طالب صفي العاشر والثاني عشر الذين
                      يرغبون بالتقدم لهذه االمتحانات ضمن برنامج دبلوم البكالوريا الدولية وبرنامج البكالوريا الدولية
                      للسنوات المتوسطة، ويتم ارسال فاتورة منفصلة بهذه المبالغ في شهر أكتوبر.</li>
                    <li>رسم حفل التخرج ويدفعه طالب الصف الثاني عشر الذي يرغبون بالمشاركة في حفل التخرج.</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <small><a href="https://drive.google.com/file/d/1MmEMf5CGffS6ON4uL5I0HIof5fiNd_Gj/view?usp=sharing" className="text-primary" target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16"> <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" /> <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" /> </svg> Print</a></small>

        </div>



        <div className="row mt-5">
          <div className="col-md-6">
            <p><strong>Refund Policy:</strong></p>
            <ul>
              <li><small>The first installment is nonrefundable.</small></li>
              <li><small>Withdrawals within the first 20 school days, 70% of the Second Installment will be refunded</small></li>
              <li><small>Withdrawals within the 21 to 40 school days, 40% of the Second Installment will be refunded Withdrawals after 40 school days, no refund is payable</small></li>
              <li><small>The cost of textbooks is nonrefundable</small></li>

            </ul>
          </div>
          <div className="col-md-6"><p dir="rtl" lang="ar"><strong>سياسة استرجاع األقساط المدفوعة:</strong></p>
            <ul dir="rtl" lang="ar">
              <li><small>القسط األول غير مسترجع.</small></li>
              <li><small>يعاد ٧٠ ٪فقط من القسط الثاني في حالة انسحاب الطالب خالل ٢٠ يوم من بداية العام الدراسي.</small></li>
              <li><small>يعاد ٤٠ ٪فقط من القسط الثاني في حالة انسحاب الطالب خالل ٢١ إلى ٤٠ يوم من بدء العام الدراسي.</small></li>
              <li><small>ال تعاد الرسوم في حال انسحاب الطالب بعد ٤٠ يوم من بداية العام الدراسي.</small></li>
              <li><small>أسعار الكتب المدرسية غير مسترجعة.</small></li>
            </ul></div>
        </div>




        <div className="col-md-12 mt-3 mb-5">
          <div className="card border-0">
            <div className="card-body">


            </div>
          </div>
        </div>


        <hr />

        <h4 className="page-title">Previous School Fees</h4>

        <p><a href="https://drive.google.com/file/d/1nPfZOpFj0xDECT_LlbKkHH2YTfg3-akq/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">
          <u>School Fees for the School year 2022 - 2023</u>
        </a></p>

        <p><a href="https://drive.google.com/file/d/1jIoVTFI1iKCnvTdAl7OwKVQUeEzjU9GB/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">
          <u>School Fees for the School year 2021 - 2022</u>
        </a></p>

        <p><a href="https://drive.google.com/file/d/1WFvSn1IWmUM6UI9nFkmtjv_e2HNrx7Kv/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">
          <u>School Fees for the School year 2020 - 2021</u></a></p>


        {/* <div className="col-md-12 mx-auto mt-3">
                  <table className="table table-bordered">                  
                  <tbody>
                    <tr>
                      <td><Link to="#" target="_blank"><i className="fa-solid fa-file-pdf fa-2x"></i> School Fees for the School Year 2022-2023</Link></td>
                    </tr>

                    <tr>
                      <td><Link to="#" target="_blank"><i className="fa-solid fa-file-pdf fa-2x"></i> School Fees for the School Year 2021-2022</Link></td>
                    </tr>
                    <tr>
                      <td><Link to="#" target="_blank"><i className="fa-solid fa-file-pdf fa-2x"></i> School Fees for the School Year 2020-2021</Link></td>
                    </tr>
                  </tbody>
                  </table>
                  </div> */}

      </div>

      <Footer />
    </div>
  )
}


