import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/innerpage.css';
import '../../styles/headers.css';

export const AttendanceDismissalTimes = () =>{
  return (
    <div>
      <Navigation/>
      <div id="attendance_dismissal_times" className="container-fluid col-md-12 page-header"></div>

        <div id="one-page" className="wrapper">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Attendance & Dismissal Times starting September 2022, a Message from the President</strong></Link></li>
                  </ol>
                  {/* <h4 className="mt-3 page-title">Attendance & Dismissal Times starting September 2022, a Message from the President</h4> */}
                </div>
              </div>

              {/* <p className="content-title block mt-5">Attendance and Dismissal Times starting September 2022</p> */}

              <p>15 June 2022</p>
              <p>Dear Parents and Students,</p>
              <p><strong>Attendance and Dismissal Times starting September 2022</strong></p>
                  
              <p>As the school year is approaching its end, I would like to take this opportunity to congratulate you on a successful year and wish the students all the best in their end of year assessment tasks.</p>    
              <p>As the different sectors in Bahrain and worldwide, private and public, returned to conducting business as usual following a period of disruption caused by covid-19, and in preparation for the coming school year that starts in September 2022, I would like to inform you that IKNS will restore it pre-covid attendance and dismissal times, yet learning and benefiting from the experiences gained over the past two years.</p>
              <p>Firstly, attendance as of September 2022 will be in-person for all students and in all grade levels. We believe that the school campus is where the students belong. It is the place where the students build their experiences and create their future memories. The virtual world will continue to exist and develop and it should not isolate the students from the real world.</p>
              <p>Secondly, the students' dismissal time will be restored to the pre-covid timings for a better coverage of the syllabi and to help reduce the traffic around the school.</p>


              <table className="table table-bordered">
          
                  <tbody>
                    <tr>
                      <td>Kindergarten</td>
                      <td>12:30pm</td>
                      <td>Dismissal time for KG1 and KG2 will be slightly staggered to avoid congestion</td>
                    </tr>

                    <tr>
                      <td>Elementary</td>
                      <td>2:00pm</td>
                      <td>Dismissal time for Grades 1, 2 & 3 and Grades 4 & 5 will be slightly staggered to avoid congestiont</td>
                    </tr>

                    <tr>
                      <td>Middle & Secondary</td>
                      <td>3:00pm</td>
                      <td>The Short Day schedule will be restored on Tuesdays in the Middle and Secondary sections of the school</td>
                    </tr>                  
                  </tbody>
                </table>
                
                <p>I would also like to inform you that the Yearbook is being finalized. We will notify you via SMS and Schoology once it is ready. It will be sold at the Bookstore.</p>
                <p>We are excited to announce that IKNS will have an on-campus uniform shop for the <strong>2023-2024</strong> academic year. Please note that the school uniform, 
                including the PE gear, will be newly designed for the school year 2023-2024. The change will take effect as of <strong>September 2023</strong>, and not this coming September.</p>
                <p>Looking forward to an exciting summer and another great year ahead.</p>

                <p>Kind regards,</p>

                <p>Dr. Kamal Abdel-Nour<br />
                School President</p>

        </div>
      <Footer/>
    </div>
  )
}


