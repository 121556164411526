import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/philosophy_statement';
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const PhilosophyStatement = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="philosophy_statement" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Our School</Link></li>
                    <li className="breadcrumb-item"><Link to="#">IKNS Philosophy</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>IKNS Philosophy Statement</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">IKNS Philosophy Statement</h4>
                </div>        
              </div>

              <img src={require('../../assets/img/our_school/IKNSPhilosophyStatement.jpg')} className="mt-3 img-fluid rounded" alt='...'/>              

               <p className="mt-3">
               Ibn Khuldoon National School (IKNS) provides an advanced model of education that upholds Arab and Islamic values, allowing students to develop in harmony with their culture and tradition, and meets the high academic standards of international education, thus preparing students to assume leading roles in the future at the national and international levels.
              </p>
              <p>Ibn Khuldoon is a national school with an international perspective. It is private, non-profit, self-supporting, coeducational and bilingual at all levels. IKNS is open to students who qualify for admission without discrimination.</p>             
              <p>The school is committed to providing a curriculum that maintains a balance among the social, personal, intellectual, physical, and esthetic areas of development. It strives to help individual students to develop in each area to the maximum of their ability, with pride and self-esteem, through effective programmes that provide for enrichment and reinforcement, and in which high expectations are set for students, supported by the concern, care, and guidance of the faculty.</p>
              <p>The school extends its services to the community beyond the education of children, through providing faculty with professional development opportunities and setting a model of education that fulfills the needs of the country.</p>
            </div>
          </div>
        </div>


      <Footer/>
    </div>
  )
}


