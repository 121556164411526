import { Link } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const BahrainTheCountry = () => {

  return (
    <div>
      <Navigation/>
      <div id="bahrain_the_country" className="container-fluid col-md-12 page-header"></div>

        <div id="one-page" className="wrapper">
          

              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">IKNS Community</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Bahrain the Country</strong></Link></li>
                  </ol>
                  {/* <h4 className="mt-3 page-title">Parents</h4> */}
                </div>        
              </div>

              <img src={require('../../assets/img/community/bahrain_the_country_cover.png')} className="mt-3 img-fluid rounded" alt='...'/>              

               <p>
               The Kingdom of Bahrain is an archipelago of more than 33 islands; the largest amongst them is Bahrain Island. The Kingdom of Bahrain is situated on the western shores of the Arabian Gulf. It covers a total land area of 765 km2, and is approximately 60 km long and 18 km wide.
              </p> 
              
              <p>Bahrain&#39;s capital and largest city is Manama, a major financial center, and home to a very diverse population. Bahrain International Airport is located in Muharraq, the second largest island after Bahrain. Moreover, the 25km-long King Fahad Causeway links the Kingdom of Bahrain with the Eastern region of the Kingdom of Saudi Arabia.</p>
              <p>The &#39;Al Khalifa&#39; family has ruled Bahrain since 1783, overseeing its development into a major commercial and banking centre.</p>
              <p>Bahrain&#39;s total population is estimated to be around 1,200,000, including a significant number of expatriates, making it a highly cosmopolitan society.</p>
            </div>


      <Footer/>
    </div>
  )
}


