import { Link } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const PresidentWelcome = () => {
    
  return (
    <div>
      <Navigation/>
       <div id="president" className="container-fluid col-md-12 page-header"></div>

            <div id="one-page" className="wrapper">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Our School</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>President's Welcome</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">President&#39;s Welcome</h4>
                </div>
              </div>
                
              <div className="drkamal text-center">
              <img src={require('../../assets/img/president_welcome/drkamal.png')} className="img-fluid rounded" alt='...'/>                    
              </div>

              <p>Dear Visitors of the IKNS Website,</p>    
              <p>We welcome you to our website that aims to introduce you to the school&#39;s philosophy and practices.</p>
              <p>IKNS is a non-profit self-supporting institution that is dedicated to providing Bahraini and non-Bahraini students with a high quality internationally recognised education.</p>
              <p>The school is coeducational from KG1 to Grade 12. It offers a bilingual program of study for the students throughout their years at IKNS. We are very proud of our students who can access both Arabic and English with ease and lucidity from an early stage of their lives.</p>
              <p>IKNS first opened its doors in 1983. The first group of students to walk up the graduation stage was in June 1992. Since then, the IKNS Alumni are the pride of the school and the gems of Bahrain.</p>
              <p>IKNS is authorized to offer three of the International Baccalaureate (IB) educational programmes from KG1 to Grade 12. Hence, IKNS is an IB Continuum School.</p>
              <p>The School has been affiliated with the IB since the year 1990, as a Diploma Programme (DP) provider. The first group of students sat for the IBDP examinations in May 1992. The IBDP results of our students are among the best on the island.</p>
              <p>In 2020, IKNS was authorized by the International Baccalaureate to offer the IB Primary Years Program (PYP) and in 2022, it was authorized to offer the IB Middle Years Program (MYP).</p>
              <p>The school also offers the American High School Diploma Programme in Grades 11 and 12 as a parallel programme to the IBDP.</p>
              <p>The School was accredited by the Middle States Association of Colleges and Schools (MSA) in the year 1994, and it continues to be in good standing with the association. The accredited status of the school affirms that it provides “the level of quality in its educational programs, services, activities, and/or resources expected by its community of stakeholders and endorsed by the education world".</p>
              <p>The Directorate of Private Schools & Kindergartens Reviews, which is a part of the Bahrain Education & Training Quality Authority (BQA), recognised IKNS as an “Outstanding” School in its First and Second Review Cycles that were conducted in 2013 and 2017. In 2020, as a reaction to the covid-19 pandemic, BQA developed the Framework for Evaluating School Practices During Exceptional Circumstances and IKNS was recognised as a “Category A” school.</p>
              <p>As a community of learners, we are proud of our school and the achievements we accomplished so far. We are equally dedicated to continuing to enhance our curricula, facilities and services to serve our community of learners in the best way possible.</p>
              <p>Our Faculty Members are highly qualified. They come with different accents and dialects, whether it is Arabic or English, providing a diverse, rich and supportive learning environment for our students. The faculty is supported by a dedicated team of administrative, IT and support staff, who work hard to pave the way for education to happen.</p>
              <p>At the top level of the school&#39;s governance, stand the school Board and the Educational, Technical, Finance and Legal Committees. The Board and the specialized Committees&#39; Members serve the School voluntarily as pay back for the society. They provide their expertise, time and effort to ensure that the school is always heading in a forward direction.</p>
              <p>Communication between the school and parents is invaluable. There is a wonderful group of parents who support the school under the umbrella of the Parents, Teachers and Students Association (PTSA). The PTSA is one way for delivering the voice of the Parents to help the School look back, adjust and move forward. Together, we can make things better. The PTSA meets regularly to organize activities for the IKNS community at large. They are also involved in organizing activities for charitable causes.</p>
              <p>I hope this website gives you an idea about our school. At the same time, you are always welcome to visit our facility and enjoy the hospitality of our staff.</p>


              <p><strong>Dr. Kamal Abdel-Nour, Ed.D, MSc.Ed, B.Sc</strong>
              <span className="d-block">School President</span>
              </p>       
              
              
            </div>

      <Footer/>
    </div>
  );
}
 
