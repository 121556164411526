import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/pyp';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const KgElementaryActivities = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="pyp" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>KG & Elementary Activities</strong></Link></li>
                  </ol>

                </div>        
              </div>

              {/* <h4 className="mt-3 page-title">KG & Elementary Activities</h4> */}
               <p className="mt-5 text-center">This Page is Under Construction. Come Back Soon.</p>

            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


