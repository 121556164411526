export const SidebarLinks = [

  {
    label: "General",
    path: "/video-gallery"
  },
  {
    label: "Honor Society",
    path: "/honor-society"
  },

];

export default SidebarLinks;
