export const GRAPHQL_API = "https://wp.ikns.info/graphql";

export const GET_POSTS_QUERY = `
query posts {
  posts(first:200 where: {categoryName: "news"}){
    nodes {
      slug
      title
      date
      excerpt
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
}`