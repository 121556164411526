export const GRAPHQL_API = "https://wp.ikns.info/graphql";

export const GET_POSTS_DETAILS = `
query posts ($slug: String){
  posts(where: {name:$slug}){
    nodes {
      slug
      title
      date
      excerpt
      content
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
}`