export const Bandlinks = () => {
  return (
    <div>
      <div id="wrap_splink" className="container-fluid text-center">
        <div className="row">
          <div className="col sp-link">
            <a href="https://ikns.info/elearning/" target="_blank" rel="noreferrer">
              <i className="fas fa-globe-americas"></i><br />
              <span>E-learning</span></a>
          </div>

          <div className="col sp-link">
            <a href="https://ikns.info" target="_blank" rel="noreferrer">
              <i className="fas fa-laptop"></i><br />
              <span>Faculty Portal</span></a>
          </div>
          <div className="col sp-link">


            <a href="https://payment.ikns.edu.bh" target="_blank" rel="noreferrer">
              <i className="far fa-credit-card"></i><br />
              <span>Online Payment</span></a>
          </div>
        </div>
      </div>
    </div>

  )
}