import { useState } from "react";

export default function ContactForm() {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const baseUrl = process.env.REACT_APP_API;


  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const sendEmail = async () => {
    let dataSend = {
      sender: 'no-reply@ikns.edu.bh',
      email: email,
      subject: `Inquiry from IKNS Website - ${subject}`,
      message: message,
    };

    try {
      setIsLoading(true);
      const res = await fetch(`${baseUrl}/email/sendEmail`, {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*", // Add the CORS header here
        },
      });

      if (res.ok) {
        setSuccessMessage("Inquiry sent successfully!");
      } else {
        // Handle error response
        // You can display an error message or perform any other actions
        console.log("Error:", res.status);
        console.log("data send:", dataSend);
        setSuccessMessage("Failed to send email.");
      }
    } catch (error) {
      // Handle network error or other exceptions
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form>
        {successMessage && (
          <div className="alert alert-primary" role="alert">
            {successMessage}
        </div>

        )}

        <div className="form-group mb-3">
          <label className="small">Section:</label>
          <select
            className="form-select"
            onChange={handleChange}
          >
            <option value="r.kady@ikns.edu.bh">Admissions Office</option>
            <option value="f.fakhroo@ikns.edu.bh">KG & Elementary School</option>
            <option value="f.adas@ikns.edu.bh">Middle School</option>
            <option value="z.matrook@ikns.edu.bh">Secondary School</option>
            <option value="k.algosaibi@ikns.edu.bh">Human Resources</option>
            <option value="infirmary@ikns.edu.bh">Infirmary</option>
            <option value="it@ikns.edu.bh">IT Department</option>
          </select>
        </div>


        <div className="form-group mb-3">
          <label className="small">Your email:</label>
          <input
            type="email"
            className="form-control"
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Message:</label>
          <textarea
            className="form-control"
            rows="10"
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>

        <div className="col-md-12 text-center mt-3">
          <button
            type="submit"
            className="btn btn-success"
            onClick={() => sendEmail()}
           
        
            disabled={isLoading}
          >
            {isLoading ? "Sending" : "Send Message"}
          </button>
        </div>
      </form>
    </>
  );
}
