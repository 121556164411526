import { Link,useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/strategic-plan';
import "../../styles/innerpage.css";
import '../../styles/headers.css';


export const StrategicPlan = () => {
  const location = useLocation();

  return (
    <div>
      <Navigation />

      <div id="strategic_plan" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
        <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Our School</Link>
                  </li>                 
                  <li className="breadcrumb-item">
                    <Link to="#">
                      <strong>Strategic Plan</strong>
                    </Link>
                  </li>
                </ol>
                <h4 className="mt-5 page-title">
                Strategic Plan Executive Summary 2023-2030
                </h4>
              </div>
            </div>



              <p className="mt-3">The Strategic Plan of 2023-2030 continues a story that began almost 40 years ago 
                and extends the legacy of the Ibn Khuldoon National School as a high performing 
                bilingual school. The school, in its vision, principles, and teaching actively 
                promotes a culture that creates a strong sense of belonging to the Kingdom of 
                Bahrain while maintaining an openness to the world.</p>

              <p>
              Today, in addition to offering the High School Diploma Program and the International 
              Baccalaureate Diploma Program (DP), the school also offers
              two other International Baccalaureate programs, the Primary Years Program (PYP) 
              and the Middle Years Program (MYP).
              </p>

              <p>
              Delivered by highly qualified and diverse faculty, the IKNS K-12 curriculum aims to develop inquiring, knowledgeable, and caring young people 
              who are willing to help create a better and more peaceful world. IKNS has a cohesive educational program that promotes the IB Learner Profile, 
              which includes Twenty-First Century skills and embraces the values of sustainable development.
              </p>

              <p>One of the targets, part of the educational goal, Bahrain&#39;s 2030 vision addresses is education for sustainable development. IKNS plans to
cooperate with the Ministry of Education and the Bahrain Quality Assurance Agency to support and promote it in the coming seven years.</p>

<p>While the longer-term effects of the pandemic are still unknown, the Strategic Plan of 2023-2030 is designed with the flexibility that ensures that
IKNS adapts to future challenges with strength and continues to be one of the leading schools in Bahrain and the region.</p>


<p>The design of the new Strategic Plan connects the school's philosophy and mission to the Bahrain 2030 vision. The 2023-2030 Strategic Plan sets
out four goals to offer IKNS students opportunities to become engaged, compassionate, lifelong learners in an inclusive and equitable environment
with a global outlook.</p>


<p className="content-title mt-5">IKNS Philosophy Statement</p>
<p>The purpose of Ibn Khuldoon National School (IKNS) is to provide an advanced model of education that builds on Arab and Islamic values,
allowing students to develop in harmony with their culture and tradition, and meet the high academic standards of international education, thus
preparing students to assume leading roles in the future at the national and international levels.</p>

<p>Ibn Khuldoon is a national school with an international perspective. It is private, non-profit, self-supporting, coeducational and bilingual at all
levels. IKNS is open to students who qualify for admission without discrimination.</p>

<p>The school is committed to providing a curriculum that maintains a balance among the social, personal, intellectual, physical, and esthetic areas
of development. It strives to help individual students to develop in each area to the maximum of their ability, with pride and self-esteem,
through effective programs that provide for enrichment and reinforcement, and in which high expectations are set for students, supported by the
concern, care, and guidance of the faculty.</p>


<p>The school extends its services to the community beyond the education of children, through providing faculty with professional development
opportunities and setting a model of education that fulfills the needs of the country.</p>

<p className="content-title mt-5">IKNS Mission</p>

<p>As a recognized leading school in the region, IKNS offers rigorous bilingual (Arabic and English) educational programs that are based on the
International Baccalaureate mission. The learning experiences of students are enhanced through differentiated instruction and the use of
appropriate educational technologies, and the teaching experiences of faculty members are refined through continuous professional training.</p>

<p className="content-title mt-5">IKNS Vision Statement</p>

<p>
IKNS is a community of learners that is dedicated to helping its members becoming knowledgeable, inquirers, thinkers, communicators,
risk-takers, principled, caring, open-minded, balanced, and reflective.
</p>


<p className="content-title mt-5">Goal 1: Curriculum</p>
<p>The School offers a rigorous curriculum that combines the academic excellence of the International Baccalaureate Organization programs with the
unique identity of the Bahraini community. The IKNS educational programs aim to prepare students to become leaders in the future at the national
and international levels.</p>

<p className="content-title mt-5">Curriculum Priorities</p>
<ul>
  <li>IKNS will continue to provide a cohesive and balanced K-12 curriculum that challenges students to achieve their full potential.</li>
  <li>IKNS will continue to offer a rigorous curriculum that focuses on excellence in the four main subjects: Arabic, English, Mathematics and
Science to prepare students for university and future careers.</li>
  <li>IKNS is committed to providing a rigorous Arabic language program to deepen the students' understanding and sense of belonging to the
culture of the Kingdom of Bahrain.</li>
  <li>IKNS will continue to use the latest technologies to inspire creativity, collaboration, and independent research.</li>
</ul>

<p className="content-title mt-5">Curriculum Progress Indicators</p>
<ul>
<li>Students Results in MAP, SAT, TOEFL and IELTS</li>
<li>IBDP Results</li>   
<li>MYP E-Assessment Results</li>    
<li>Arabic Standardized Test Results</li>  
<li>Spending on Technology to Enhance Learning</li>
<li>University Acceptances</li>
</ul>


<p className="content-title mt-5">Goal 2: Connection to 2030 Vision</p>
<p>The school will design and implement units of study that allow students to acquire the knowledge and skills needed to promote sustainable
development, and at the same time, offer them opportunities for individual as well as collective action to deepen their appreciation for
sustainable lifestyles. The guiding principles of sustainability, fairness, and competitiveness provide the school with an overarching framework to
encompass the specific goals of the school.</p>

<p className="content-title mt-5">Connection to 2030 Vision Priorities</p>
<ul>
  <li>IKNS is committed to promoting the UN&#39;s Sustainable Development Goals by integrating them into its IB programs, extra-curricular and
community service activities and events. (Sustainability)</li>
<li>IKNS will continue every effort to promote the diversity of its student population. (Fairness)</li>
<li>IKNS will continue to increase its accessibility to benefit the community by publishing clear admission procedures and keeping its tuition
predictable. (Fairness)</li>
<li>IKNS will continue to help students understand that their knowledge of Science, Technology, Engineering, the Arts and Mathematics
(STEAM) is essential to unlocking opportunities for a better future. (Competitiveness)</li>
</ul>


<p className="content-title mt-5">Connection to 2030 Vision Progress Indicators</p>
<ul>
  <li>PYP: Students Performance in the Exhibition</li>
<li>MYP: Interdisciplinary Units Summative Results</li>
<li>Quality of the CAS program</li>
<li>Participation in programs such as Injaz, Trade Quest, MUN, Team Nasser, etc.</li>
</ul>


<p className="content-title mt-5">Goal 3: Educator Development</p>
<p>IKNS will continue to attract and retain highly-qualified educators to provide the best possible learning outcomes for its students.</p>


<p className="content-title mt-5">Educator Development Priorities</p>
<ul>
  <li>Recruitment at IKNS values and promotes inclusion and celebrates diversity, including nationality, race, gender and religion.</li>
<li>IKNS teachers and staff bring experiences from all over the world, which will continue to help students and parents understand their
options better in the post-IKNS stage.</li>
<li>IKNS staff will continue to help students navigate the college and university admissions process.</li>
<li>IKNS will continue to provide its teachers with regular, meaningful, and helpful feedback and training to support their professional
development.</li>
</ul>


<p className="content-title mt-5">Educator Development Progress Indicators</p>
<p>Spending Directed Towards Creating facilities to serve all Students</p>
<p>Teacher Appraisal Yearly Reports</p>
<p>Parents and students satisfaction surveys</p>
<p>Teacher and Leader retention</p>
<p>Professional Development Offerings</p>


<p className="content-title mt-5">Goal 4: Managing Facilities and Social Spaces</p>
<p>
The design of IKNS facilities and the management of their social spaces will continue to promote the person’s well-being, prioritizing the
promotion of learning in an inclusive and compassionate environment.
</p>


<p className="content-title mt-5">Managing Facilities and Social Spaces Priorities</p>
<ul>
  <li>IKNS will continue to maintain and renovate its buildings and grounds to provide social spaces that create and promote favorable learning
conditions for all students.</li>
<li>IKNS will continue to anticipate and respond to health and safety risks.</li>
<li>IKNS will create a safe and healthy environment for its community. The school operations promote the students’ personal, social and
physical well-being.</li>
<li>IKNS will take appropriate measures and actions to prevent and respond to harmful expressions of behaviors.</li>
</ul>

<p className="content-title mt-5">Managing Facilities and Social Spaces Progress Indicators</p>
<ul>
  <li>Records of Learning Diversity Unit</li>
<li>Discipline Records</li>
<li>Student Attendance</li>
<li>Support for gifted and talented students</li>
<li>Employee Attendance</li>
<li>IBDP Enrollment</li>
<li>Government of Bahrain certification regarding health and safety</li>
</ul>


              <div className="contact-section col-lg-12 mx-auto">
              <h4 className="mt-3 page-title">Contact Us</h4>
              <p>If you have questions about the IKNS Strategic Plan, we are here to help.</p>
              <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
