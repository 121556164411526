import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import {useState} from 'react'
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const TeacherTestimonial = () => {
  
  const [modalState, setModalstate] = useState({ ytId: "", isOpen: false });

  const handleClick = (ytId, isOpen) => {
    setModalstate({ ytId, isOpen });
    console.log(setModalstate);
  };

  return (
    <div>
      <Navigation/>
      <div id="testimonial" className="container-fluid col-md-12 page-header"></div>

        <div id="one-page" className="wrapper">
          <div className="row">
          <h4 className="mt-3 page-title text-center">Teacher Testimonial</h4>
          <p className="text-center">What's it like to teach at Ibn Khuldoon National Schools?</p>
                           
                  <div className="mx-auto col-lg-6 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("a_7BBA0IHrs", true)}>
                      <img src={require('../../assets/img/testimonials/ZANOUN_Ayman.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <strong>Ayman Zanoun</strong>
                          <small className="d-block">Curriculum Coordinator (K-12)</small>
                          </p>
                      </div>
                    </div>                  
                  </div>                          


                <div className="row mt-5">                  
                  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("bC8pyCHzQlU", true)}>
                      <img src={require('../../assets/img/testimonials/JOHN_Rose.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <strong>John Rose</strong>
                          <small className="d-block">Assistant Principal, Secondary School</small>
                          </p>
                      </div>
                    </div>                  
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("v8Jjj7K6saw", true)}>
                      <img src={require('../../assets/img/testimonials/3.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <strong>Afrah Jassim</strong>
                          <small className="d-block">Principal, Kindergarten and Elementary</small>
                          </p>
                      </div>
                    </div>                  
                  </div>
                
                  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("BNc-lac-7zU", true)}>
                      <img src={require('../../assets/img/testimonials/4.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <strong>Samuel Barton</strong>
                          <small className="d-block">English Teacher</small>
                          </p>
                      </div>
                    </div>                  
                  </div>

                  

                  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("O6Is3AVlbkg", true)}>
                      <img src={require('../../assets/img/testimonials/5.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <strong>Juhaina Sadoon</strong>
                          <small className="d-block">Arabic Teacher</small>
                          </p>
                      </div>
                    </div>                  
                  </div>


                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("0Refl77CX1U", true)}>
                      <img src={require('../../assets/img/testimonials/6.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <strong>Jeffrey Diprizito</strong>
                          <small className="d-block">Secondary Teacher</small>
                          </p>
                      </div>
                    </div>                  
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("z-Gnba1qhjo", true)}>
                      <img src={require('../../assets/img/testimonials/7.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <strong>Fadi Hassoun</strong>
                          <small className="d-block">Secondary Arabic Teacher</small>
                          </p>
                      </div>
                    </div>                  
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("fXAmvMe3eJc", true)}>
                      <img src={require('../../assets/img/testimonials/8.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <strong>Masuma Ali</strong>
                          <small className="d-block">Secondary Teacher</small>
                          </p>
                      </div>
                    </div>                  
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("nmeMkAP9UP4", true)}>
                      <img src={require('../../assets/img/testimonials/9.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <strong>Amanda Sepp</strong>
                          <small className="d-block">Elementary Teacher</small>
                          </p>
                      </div>
                    </div>                  
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("SK4FGQirzMs", true)}>
                      <img src={require('../../assets/img/testimonials/10.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <strong>Rosy Johnson</strong>
                          <small className="d-block">PYP Coordinator</small>
                          </p>
                      </div>
                    </div>                  
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 mb-sm-0">                    
                    <div className="card border-0 custom-pointer" onClick={() => handleClick("40E54d5u3gI", true)}>
                      <img src={require('../../assets/img/testimonials/11.png')} className="rounded-0 card-img-top" alt="..." />
                      <div className="card-body">
                        <p className="text-center">
                          <strong>Bianca Sepp</strong>
                          <small className="d-block">Elementary Teacher</small>
                          </p>
                      </div>
                    </div>                  
                  </div>
                </div>

             

          </div>
        </div>

        <ModalVideo channel='youtube' autoplay isOpen={modalState.isOpen} videoId={modalState.ytId} onClose={() => setModalstate(false)} />



      <Footer/>
    </div>
  )
}

