import { Link,useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/video-gallery';
import "../../styles/innerpage.css";
import '../../styles/headers.css';
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

export const VideoGallery = () => {
  const location = useLocation();
  const [toggler, setToggler] = useState(false);
  const [toggler2, setToggler2] = useState(false);
  const [toggler3, setToggler3] = useState(false);
  const [toggler4, setToggler4] = useState(false);
  const [toggler5, setToggler5] = useState(false);
  const [toggler6, setToggler6] = useState(false);

  return (
    <div>
      <Navigation />
      <div id="video_gallery" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
        <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/">Video Gallery</Link>
                  </li>

                  <li className="breadcrumb-item">
                    <Link to="#">
                      <strong>General</strong>
                    </Link>
                  </li>
                </ol>
               
              </div>
            </div>

            <div className="col-md-12">

              <div className="row custom-pointer">
                <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
                  <img src={require('../../assets/img/video_gallery/general/thumb/legacy_and_mission.png')} 
                  className="w-100 shadow-1-strong rounded mb-4" 
                  onClick={() => setToggler(!toggler)} 
                  alt="..."/>
                  <img src={require('../../assets/img/video_gallery/general/thumb/chair_bot.png')} 
                  className="w-100 shadow-1-strong rounded mb-4"
                  onClick={() => setToggler2(!toggler2)}  
                  alt="..."/>
                </div>

                <div className="col-lg-4 mb-4 mb-lg-0">
                  <img src={require('../../assets/img/video_gallery/general/thumb/mother_tongue.png')} 
                  className="w-100 shadow-1-strong rounded mb-4"
                  onClick={() => setToggler3(!toggler3)}                     
                  alt="..."/>

                  <img src={require('../../assets/img/video_gallery/general/thumb/myp.png')} 
                  className="w-100 shadow-1-strong rounded mb-4" 
                  onClick={() => setToggler4(!toggler4)}   
                  alt="..."/>
                </div>

                <div className="col-lg-4 mb-4 mb-lg-0">
                  <img src={require('../../assets/img/video_gallery/general/thumb/btv_interview.png')} 
                  className="w-100 shadow-1-strong rounded mb-4"
                  onClick={() => setToggler5(!toggler5)}   
                  alt="..."/>

                  <img src={require('../../assets/img/video_gallery/general/thumb/6.png')} 
                  className="w-100 shadow-1-strong rounded mb-4" 
                  onClick={() => setToggler6(!toggler6)}   
                  alt="..."/>
                </div>
              </div>

              <FsLightbox 
              toggler={toggler} 
              sources={[
                "https://www.youtube.com/watch?v=wRT5xeeBx78",
              ]}
              />

            <FsLightbox 
              toggler={toggler2} 
              sources={[              
                "https://www.youtube.com/watch?v=ZvADd4pdWI8",
              ]}
              />

            <FsLightbox 
              toggler={toggler3} 
              sources={[              
                "https://www.youtube.com/watch?v=6QOeOCA6PlA",
              ]}
              />

            <FsLightbox 
              toggler={toggler4} 
              sources={[              
                "https://www.youtube.com/watch?v=edS25rN6ojE&t",
              ]}
              />

            <FsLightbox 
              toggler={toggler5} 
              sources={[              
                "https://www.youtube.com/watch?v=-02-ZL4Y04w&t",
              ]}
              />
            <FsLightbox 
              toggler={toggler6} 
              sources={[              
                "https://www.youtube.com/watch?v=tWLPKrhZB4o&t",
              ]}
              />
              
            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
