import { Link } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const OperationalPlan = () => {

  return (
    <div>
      <Navigation/>

      <div id="operational_plan" className="container-fluid col-md-12 page-header"></div>
      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
                <li className="breadcrumb-item"><Link to="#"><strong>Operational Plans</strong></Link></li>
            </ol>
          </div>        
        </div>
        
    
        <div className="row g-0">
          <div className="col-md-12 col-lg-6">
            <img src={require('../../assets/img/operational_plan/cover.png')} className="w-100 h-100 img-fluid" alt='...'/>              
          </div>
          <div className="col-md-12 col-lg-6 bg-gray p-3">
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-self-center">
               <h4 className="mt-3 page-title">Operational Plans</h4>
               <p className="mt-3">
               To ensure that all students&#39; needs are met in the upcoming school year, 
               we have devoted significant time to creating focused school-wide priorities 
               and implementing key strategies to achieve our important goals. Detailed 
               information regarding these priorities and strategies can be found in our 
               School Operational Plan.
                </p>

                  <p><a href="https://drive.google.com/file/d/1etRzvA-hi-lWqnqxLdgt3C2OtoK92k6P/view" className="text-link" target="_blank" rel="noreferrer">
                  Operational Plan 2021-2022 <i className="fas fa-arrow-right text-right"></i></a></p>

                  <p><a href="https://drive.google.com/file/d/1mqZZPvpCwva7phrfhzHm9A-DSTH7vgOP/view" className="text-link" target="_blank" rel="noreferrer">
                  The MoE Back to School Guide <i className="fas fa-arrow-right text-right"></i></a></p>

                  <p><a href="https://drive.google.com/file/d/19Zg8PH2NzH_prapB8fEu4gaB8pnVX3S3/view" className="text-link" target="_blank" rel="noreferrer">
                  Operational Plan 2020-2021 <i className="fas fa-arrow-right text-right"></i></a></p>

              </div>
            </div>            
          </div>
        </div>

        <div className="contact-section col-lg-9 mx-auto">
        <h4 className="mt-3 page-title">Contact Us</h4>
        <p>If you have questions about the Operational Plans, we are here to help.</p>
        <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

        </div>
      <Footer/>
    </div>
  )
}


