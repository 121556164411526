export const SidebarLinks = [
  {
    label: "Students' Life",
    path: "/student-life"
  },
  {
    label: "Students Congress",
    path: "/student-congress"
  },
  // {
  //   label: "Athlete of the Year",
  //   path: "/athlete-of-the-year"
  // },
  {
    label: "Samar Al Ansari Awards for Excellence",
    path: "/samar-al-ansari-awards-for-excellence"
  },

];

export default SidebarLinks;
