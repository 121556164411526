export const SidebarLinks = [
  // {
  //   label: "External Exam Results",
  //   path: "/external-exam-results"
  // },
  {
    label: "IBDP Results",
    path: "/ib-results"
  },
  {
    label: "MAP",
    path: "/measure-of-academic-progress"
  },
  {
    label: "TIMSS",
    path: "/the-international-measures-trends-in-mathematics-and-science-study"
  },
  {
    label: "PIRLS",
    path: "/the-progress-in-international-reading-literacy-study"
  },
];

export default SidebarLinks;
