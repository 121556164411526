
export const SidebarLinks = [
  {
    label: "Teaching and Learning at IKNS",
    path: "/teaching-and-learning"
  },

];

export default SidebarLinks;
