import { Link } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const Admission = () => {
  
  return (
    <div>
      <Navigation/>
      <div id="admission" className="container-fluid col-md-12 page-header"></div>

        <div id="one-page" className="wrapper">

 
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Admission</strong></Link></li>
                  </ol>
                  {/* <h4 className="mt-3 page-title">Academic Integrity</h4> */}
                </div>        
              </div>



              <div className="row g-0">
          <div className="col-md-12 col-lg-6">
            <img src={require('../../assets/img/admission/cover.png')} className="w-100 h-100 img-fluid" alt='...'/>              
          </div>
          <div className="col-md-12 col-lg-6 bg-gray p-3">
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-self-center">
               <h4 className="mt-3 page-title">Admission</h4>
               <p className="mt-3">
               Are you looking for a School that offers the PYP, MYP, DP, and American High School 
              Diploma programs? IKNS is committed to provide an advanced model of education 
              that builds on Arab and Islamic values, allowing students to develop in harmony 
              with their culture and tradition, and meet the high academic standards of 
              international education.
                </p>

                <a href="/admission-procedure" className="text-link" target="_blank" rel="noreferrer">Apply - New Student</a>

                <a href="https://drive.google.com/file/d/1i-21V5b3E8cEmkSDVCO2gwZR_4ZJhi_3/view?usp=sharing" className="mt-3 text-link" target="_blank" rel="noreferrer">KG1 Admission Interview - Parent Guide</a>

                <a href="/school-fees" className="mt-3 text-link" target="_blank" rel="noreferrer">School Fees</a>


              </div>
            </div>            
          </div>
        </div>


        <div className="contact-section col-lg-9 mx-auto">
        <h4 className="mt-3 page-title">Contact Us</h4>
        <p>If you have questions about the School Admission, we are here to help.</p>
        <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>
       
             

        </div>
      <Footer/>
    </div>
  )
}


