import { Routes, Route } from 'react-router-dom'
import { Homepage } from './view/homepage'
import { BackToSchoolOperationalPlan } from './view/back_to_school_operational_plan'
import { SchoolCalendar } from './view/school-calendar';
import { StudentsDeviceSpecifications } from './view/students_device_specifications/';
import { VirtualLearningBlog } from './view/virtual_learning_blog/';
import { KgAndElementary } from './view/kg_and_elementary/';
import { MiddleSchool } from './view/middle_school';
import { SecondarySchool } from './view/secondary_school';
import { SchoolFees } from './view/school_fees';
import { Uniform } from './view/uniform';
import { AccreditationAffiliation } from './view/accreditation_affiliation';
import { SchoolPolicies } from './view/school-policies';
import { PresidentWelcome } from './view/president_welcome';
import { TeacherTestimonial } from './view/teacher_testimonial';
import { TheSchool } from './view/the_school';
import { TeachingAndLearning } from './view/teaching_learning';
import { AttendanceDismissalTimes } from './view/attendance_dismissal_times';
import { AcademicIntegrity } from './view/academic';
import { AcademicHonestyPolicy } from './view/academic/academic-honesty-policy';
import { ExternalExamResults } from './view/external-exam-results';
import { IbdpResults } from './view/external-exam-results/ib-results'
import { Map } from './view/external-exam-results/map'
import { Timss } from './view/external-exam-results/timss'
import { Pirls } from './view/external-exam-results/pirls'
import { GraduationRequirements } from './view/graduation-requirements'
import { HighSchoolProfile } from './view/high-school-profile'
import { CollegePlacementAndCareerCounseling } from './view/college-placement-career-counseling'
import { PrimaryYearsProgramme } from './view/pyp'
import { PypatIkns } from './view/pyp/pyp-at-ikns'
import { ProgrammeofInquiry } from './view/pyp/programme-of-inquiry'
import { AssessmentInPyp } from './view/pyp/assessment-in-pyp'
import { KGElementaryRulesAndProcedures } from './view/pyp/kg-elementary-rules-and-procedures'
import { KgElementaryActivities } from './view/pyp/kg-elementary-activities'
import { MiddleYearsProgramme } from './view/myp'
import { MypAssessment } from './view/myp/assessment'
import { MypPresentationsActivities } from './view/myp/myp-presentations-activities'
import { IbDiplomaProgramme } from './view/ibdp'
import { IbdpSubjectOffered } from './view/ibdp/subject-offered'
import { TheoryofKnowledge } from './view/ibdp/tok'
import { TheExtendedEssay } from './view/ibdp/extended-essay'
import { CreativityActivityService } from './view/ibdp/creativity-activity-service'
import { IbdpAssessment } from './view/ibdp/assessment'
import { UniversityRecognition } from './view/ibdp/university-recognition'
import { CourseSelection } from './view/ibdp/course-selection'
import { AmericanHighSchoolDiploma } from './view/american-highschool-diploma'
import { CoursesOfferedAhsd } from './view/american-highschool-diploma/courses-offered'
import { CourseSelectionAhsd } from './view/american-highschool-diploma/course-selection'
import { StudentLife } from './view/student-life';
import { StudentCongress } from './view/student-life/students-congress';
import { SamarAlAnsari } from './view/student-life/samar-al-ansari-awards-for-excellence';
import { Parents } from './view/parent';
import { ParentVolunteers } from './view/parent/parent-volunteers';
import { FacultyAndStaff } from './view/faculty-and-staff';
import { Alunmi } from './view/alumni';
import { BahrainTheCountry } from './view/bahrain-the-country';
import { PhilosophyStatement } from './view/philosophy-statement';
import { MissionVision } from './view/philosophy-statement/mission-vision';
import { CoreValues } from './view/philosophy-statement/core-values';
import { WhyMsaAcccreditation } from './view/why-msa-accreditation'
import { StrategicPlan } from './view/strategic-plan';
import { StrategicPlan20152022 } from './view/strategic-plan/2015-2022';
import { History } from './view/history';
import { GraduateProfile } from './view/graduate-profile';
import { GoverningBody } from './view/governing-body';
import { Committees } from './view/governing-body/committees';
import { Constitution } from './view/governing-body/constitution';
import { Admission } from './view/admission';
import { AdmissionGuide } from './view/admission/admission-guide';
import { AdmissionProcedure } from './view/admission/procedure';
import { PhotoGallery } from './view/photo-gallery';
import { FacilitiesPhoto } from './view/photo-gallery/facilities';
import { VideoGallery } from './view/video-gallery';
import { TheStudentTeacherHandbook } from './view/student-teacher-handbook';
import { OperationalPlan } from './view/operational-plan';
import { PostResponsibility } from './view/post-responsibility';
import { ArticleDetails } from './view/article'
import { HonorSociety } from './view/video-gallery/honor-society';
import { ContactForm } from './view/contact';
import { Recruitment } from './view/recruitment';
import { ApproachestoTeaching } from './view/recruitment/approaches_teaching';
import { SupportPositions } from './view/recruitment/support-positions';
import { TeachingPositions } from './view/recruitment/teaching-positions';
import { TeacherJobDescription } from './view/recruitment/teacher-job-description';
import { LeadershipPosition } from './view/recruitment/leadership-position';
import { BellSchedule } from './view/bell-schedule';
import { CycleCalendar } from './view/cycle-calendar';
import { AcademicIntegrityPolicy } from './view/academic/academic-integrity-policy'

function App() {
  return (
    <Routes>
      <Route path='/' element={<Homepage />} />
      <Route path='back-to-school-operational-plan' element={<BackToSchoolOperationalPlan />} />
      <Route path='school-calendar' element={<SchoolCalendar />} />
      <Route path='bell-schedule' element={<BellSchedule />} />
      <Route path='cycle-calendar' element={<CycleCalendar />} />
      <Route path='students-device-specifications' element={<StudentsDeviceSpecifications />} />
      <Route path='virtual-learning-preriod' element={<VirtualLearningBlog />} />
      <Route path='kg-and-elementary' element={<KgAndElementary />} />
      <Route path='middle-school' element={<MiddleSchool />} />
      <Route path='secondary-school' element={<SecondarySchool />} />
      <Route path='school-fees' element={<SchoolFees />} />
      <Route path='uniform' element={<Uniform />} />
      <Route path='accreditation-affiliation' element={<AccreditationAffiliation />} />
      <Route path='school-policies' element={<SchoolPolicies />} />
      <Route path='president-welcome' element={<PresidentWelcome />} />
      <Route path='teacher-testimonial' element={<TeacherTestimonial />} />
      <Route path='the-school' element={<TheSchool />} />
      <Route path='teaching-and-learning' element={<TeachingAndLearning />} />
      <Route path='attendance-dismissal-times' element={<AttendanceDismissalTimes />} />
      <Route path='academic-integrity' element={<AcademicIntegrity />} />
      <Route path='academic-honesty-policy' element={<AcademicHonestyPolicy />} />
      <Route path='external-exam-results' element={<ExternalExamResults />} />
      <Route path='ib-results' element={<IbdpResults />} />
      <Route path='measure-of-academic-progress' element={<Map />} />
      <Route path='the-international-measures-trends-in-mathematics-and-science-study' element={<Timss />} />
      <Route path='the-progress-in-international-reading-literacy-study' element={<Pirls />} />
      <Route path='graduation-requirements' element={<GraduationRequirements />} />
      <Route path='high-school-profile' element={<HighSchoolProfile />} />
      <Route path='college-placement-and-career-counseling' element={<CollegePlacementAndCareerCounseling />} />
      <Route path='pyp' element={<PrimaryYearsProgramme />} />
      <Route path='pyp-at-ikns' element={<PypatIkns />} />
      <Route path='programme-of-inquiry' element={<ProgrammeofInquiry />} />
      <Route path='assessment-in-pyp' element={<AssessmentInPyp />} />
      <Route path='kg-elementary-rules-and-procedures' element={<KGElementaryRulesAndProcedures />} />
      <Route path='kg-elementary-activities' element={<KgElementaryActivities />} />
      <Route path='myp' element={<MiddleYearsProgramme />} />
      <Route path='myp-assessment' element={<MypAssessment />} />
      <Route path='myp-presentations-Activities' element={<MypPresentationsActivities />} />
      <Route path='ibdp' element={<IbDiplomaProgramme />} />
      <Route path='ibdp-subjects-offered-at-ikns' element={<IbdpSubjectOffered />} />
      <Route path='theory-of-knowledge' element={<TheoryofKnowledge />} />
      <Route path='the-extended-essay' element={<TheExtendedEssay />} />
      <Route path='creativity-activity-service' element={<CreativityActivityService />} />
      <Route path='ibdp-assessment' element={<IbdpAssessment />} />
      <Route path='university-recognition' element={<UniversityRecognition />} />
      <Route path='course-selection' element={<CourseSelection />} />
      <Route path='american-high-school-diploma' element={<AmericanHighSchoolDiploma />} />
      <Route path='courses-offered-in-the-ahsd-programme' element={<CoursesOfferedAhsd />} />
      <Route path='courses-offered-in-the-ahsd-programme' element={<CoursesOfferedAhsd />} />
      <Route path='ahsd-course-selection' element={<CourseSelectionAhsd />} />
      <Route path='student-life' element={<StudentLife />} />
      <Route path='student-congress' element={<StudentCongress />} />
      <Route path='samar-al-ansari-awards-for-excellence' element={<SamarAlAnsari />} />
      <Route path='parents' element={<Parents />} />
      <Route path='parent-volunteers' element={<ParentVolunteers />} />
      <Route path='faculty-and-staff' element={<FacultyAndStaff />} />
      <Route path='alumni' element={<Alunmi />} />
      <Route path='bahrain-the-country' element={<BahrainTheCountry />} />
      <Route path='philosophy-statement' element={<PhilosophyStatement />} />
      <Route path='mission-vision' element={<MissionVision />} />
      <Route path='core-values' element={<CoreValues />} />
      <Route path='why-msa-accreditation' element={<WhyMsaAcccreditation />} />
      <Route path='strategic-plan' element={<StrategicPlan />} />
      <Route path='strategic-plan/2015-2022' element={<StrategicPlan20152022 />} />
      <Route path='history' element={<History />} />
      <Route path='graduate-profile' element={<GraduateProfile />} />
      <Route path='governing-body' element={<GoverningBody />} />
      <Route path='committees' element={<Committees />} />
      <Route path='constitution' element={<Constitution />} />
      <Route path='admission' element={<Admission />} />
      <Route path='admission-procedure' element={<AdmissionProcedure />} />
      <Route path='kg1-admission-interview-parent-guide' element={<AdmissionGuide />} />
      <Route path='photo-gallery' element={<PhotoGallery />} />
      <Route path='video-gallery' element={<VideoGallery />} />
      <Route path='honor-society' element={<HonorSociety />} />
      <Route path='facilities-photo' element={<FacilitiesPhoto />} />
      <Route path='the-student-teacher-handbook' element={<TheStudentTeacherHandbook />} />
      <Route path='operational-plans' element={<OperationalPlan />} />
      <Route path='post-responsibility' element={<PostResponsibility />} />
      <Route path='/article/:slug' element={<ArticleDetails />} />
      <Route path='contact-us' element={<ContactForm />} />
      <Route path='recruitment' element={<Recruitment />} />
      <Route path='approaches-to-teaching' element={<ApproachestoTeaching />} />
      <Route path='support-positions' element={<SupportPositions />} />
      <Route path='teaching-positions' element={<TeachingPositions />} />
      <Route path='teacher-job-description' element={<TeacherJobDescription />} />
      <Route path='leadership-positions' element={<LeadershipPosition />} />
      <Route path='academic-integrity-policy' element={<AcademicIntegrityPolicy />} />


    </Routes>
  );
}

export default App;
