import { Link } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";

import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const History = () => {
    
  return (
    <div>
      <Navigation/>
       <div id="history" className="container-fluid col-md-12 page-header"></div>

            <div id="one-page" className="wrapper">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Our School</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>History</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">Ibn Khuldoon National School History</h4>
                </div>
              </div>
                
              {/* <div className="drkamal text-center">
              <img src={require('../../assets/img/president_welcome/drkamal.png')} className="img-fluid rounded" alt='...'/>                    
              </div> */}

<p>IKNS opened its doors to students on the 22nd of September 1983. It all started as a dream for Bahraini parents who sought an academic institution that would be bi-lingual and coeducational, would meet high international educational standards and would cater for the needs of Bahraini and Arab children.</p>
              
<p>Private education available until that time had been conducted by a number of foreign schools which provided a high level of education, with Arabic taught as an optional subject and with no particular emphasis on Islamic studies. Government schools, on the other hand, did have Arabic as the medium of education but they did not provide the standard of education that these parents wanted for their children.</p>              
<p>And so the idea of the Society of The Ibn Khuldoon National School was born as an alternative which was unique, and which blended the teaching of Arabic as a language and as a culture, with a truly superior standard of education.</p>
<p>With this intent, 44 prominent Bahraini men and women met formally as Founding Members for the first time at the Bahrain Chamber of Commerce & Industry, on the 21st November 1978. The Society obtained Government approval during December 1979, and the process became fully official with the publishing of the Society's Articles of Association in the March 1980 issue of the Official Gazette.</p>
<p>The first General Assembly was held, again at the Chamber of Commerce & Industry, on 26th August 1980; the first Board of Directors was elected that day, and met four days later at the offices of Mr. Mohamed Salahuddin, where Dr. Ali Mohamed Fakhro was elected Chairman. Committees were formed within the Board to set up the school. The project received the whole hearted support of Government of H.H. The Amir, Sheikh Isa Bin Sulman Al Khalifa who donated 40,000 square metres of land in the Isa Town Educational Area and furthermore graciously advanced an interest-free loan of BD 1 million to start the building of the school.</p>
<p>On 8th September 1982, at the offices of Dr. Ali Mohamed Fakhro, the contract to erect the school buildings was signed with Ali Abdulla Karime & Sons as main contractors, for a total value of BD 1 million. The completion forecast by the Consultants, M/s. Associated Consulting Engineers ( ACE) was one year. Indeed, the buildings were handed over on 31st August 1983.</p>
<p>On the morning of Saturday 22nd September 1983, the school officially opened its doors to the first students. Initially it had 2 kindergarten and 4 primary classes, with one class added each subsequent year. By the beginning of the 1987/88 school year,the school had 2 kindergartens (8 sections), 6 primary (18 sections) and 2 intermediate classes (3 sections). The intermediate level was completed by September 1988.</p>
<p>The school signed an Advisory Services Contract with the Educational Resource Centre, a division of the International College of Beirut. This contract was later substituted by a similar contract with Al Muftah Institution for Educational Services, which acted as the school's technical and educational consultants firm, until the school became fully independent few years later.</p>
<p>The Ibn Khuldoon National School was also unique, in that all parents were members of the General Assembly and therefore could contribute in setting the general policies of the school through the Board of Directors, which is elected bi-annually from the General Assembly. Since 1996, the school operates under a Board of Trustees. Trustees elect a Board of Directors for three years at a time (please refer to the “Board of Committees/Constitution” page) The school also elects a Parents-Teachers Association which acts as a bridge between parents and the school.</p>
<p>The Ibn Khuldoon National School has continued to develop over a comparatively short span of time. Every year since its opening, a new class has been added and ideas introduced. The success in the realization of its dream is now prompting the school to consider further expansion.</p>
            </div>

      <Footer/>
    </div>
  );
}
 
