export const SidebarLinks = [
  {
    label: "The IB Diploma Programme (G11-G12)",
    path: "/ibdp"
  },
  {
    label: "IBDP subjects offered at IKNS",
    path: "/ibdp-subjects-offered-at-ikns"
  },
  {
    label: "Theory of Knowledge",
    path: "/theory-of-knowledge"
  },
  {
    label: "The Extended Essay",
    path: "/the-extended-essay"
  },
  {
    label: "Creativity, Activity, Service",
    path: "/creativity-activity-service"
  },
  {
    label: "IBDP Assessment",
    path: "/ibdp-assessment"
  },

  {
    label: "University Recognition",
    path: "/university-recognition"
  },
  {
    label: "Course Selection",
    path: "/course-selection"
  },

  


];

export default SidebarLinks;
