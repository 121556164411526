import { Link,useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/recruitment';
import "../../styles/innerpage.css";
import '../../styles/headers.css';

export const ApproachestoTeaching = () => {
  const location = useLocation();

  return (
    <div>
      <Navigation />

      <div id="teaching_and_learning" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
        <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Our School</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">
                      <strong>Recruitment</strong>
                    </Link>
                  </li>
                </ol>
               
              </div>
            </div>

            <h4 className="mt-3 page-title">Approaches to Teaching</h4>
            <div className="col-md-12">
              <p>The International Baccalaureate identifies six elements that are referred 
                to as Approaches to Teaching. Teaching at IKNS is inspired by these six 
                elements as means for effective teaching and learning.</p>
            
            <ul>
            <li>Teaching through inquiry</li>
            <li>Teaching through concepts</li>
            <li>Teaching developed in local and global contexts</li>
            <li>Teaching focused on effective teamwork and collaboration</li>
            <li>Teaching differentiated to meet the needs of all learners</li>
            <li>Teaching informed by formative and summative assessment</li>
            </ul>
            

            <p><small>
              Source: <em>Approaches to teaching and learning. International Baccalaureate, 
              2014, <a href="https://www.ibo.org/globalassets/digital-tookit/flyers-and-artworks/approaches-to-teaching-learning-dp-en.pdf" target="_blank" rel="noreferrer" className="text-primary">
              https://www.ibo.org/globalassets/digital-tookit/flyers-and-artworks/approaches-to-teaching-learning-dp-en.pdf.</a></em></small>
            </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
