import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from "../../components/sidebar/governing-body"
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const GoverningBody = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="governing_body" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
            </div> 
          </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Our School</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>The Governing Body</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">The Governing Body</h4>
                </div>        
              </div>

              <p>IKNS operates under a Board of Trustees, who volunteer their expertise and time to support the school. The Board of Trustees is responsible for overseeing the operation of the school and making decisions and establishing procedures to that effect.</p>
              <p>The Board of Trustees is composed of the founding members, who were authorized to establish the School by the decision of the Minister of Education No. 558 of 1996, in addition to members who joined them according to the conditions and criteria set in the by-laws of the Board of Trustees. The 24 members of the Board of Trustees include 4 representatives from the Parent, Teacher and Student Association (PTSA), who are ex-officio members.</p>
              <p>The Trustees elect among themselves a Board of Directors for three years at a time. The Board of Directors is the executive body that supervises all educational, financial, technical and legal matters of the school, in order to ensure the proper implementation of the policies and plans approved by the Board of Trustees. The Board of Directors operates through four main committees, The Educational Committee, the Finance Committee, the Technical Committee and the Legal Committee. The Chair of the PTSA is an ex-officio member of the Board of Directors.</p>

              <p className="content-title mt-5">Board Of Trustees</p>
              <img src={require('../../assets/img/governing_body/board_of_trustees.png')} className="rounded img-fluid" alt='img'/>              

              <p className="content-title mt-5">Board Of Directors</p>
              <img src={require('../../assets/img/governing_body/board_of_directors.png')} className="rounded img-fluid" alt='img'/>              

              

            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


