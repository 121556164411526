import { Link,useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/recruitment';
import "../../styles/innerpage.css";
import '../../styles/headers.css';

export const TeacherJobDescription  = () => {
  const location = useLocation();

  return (
    <div>
      <Navigation />

      <div id="teaching_and_learning" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
        <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Our School</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">
                      <strong>Recruitment</strong>
                    </Link>
                  </li>
                </ol>
               
              </div>
            </div>


            <div className="col-md-12">
            <h4 className="mt-3 page-title">Teacher Job Description</h4>
              <p className="mt-3">Position: Teacher All School - Updated February 2023</p>       

              <p className="mt-5"><strong>The Teacher&#39;s responsibilities Include:</strong></p>
              <ul className="small-list">
              <li>Reviews and updates the Yearly Plan on Atlas Rubicon</li>
              <li>Prepares and updates the Unit Plans on Atlas Rubicon</li>
              <li>Prepares student-centered differentiated Lesson Plans to be submitted to the HoD/Coordinator/facilitator for review</li>
              <li>Incorporates technology applications to facilitate teaching and learning</li>
              <li>Communicates effectively with students, parents and administration using the School&#39;s main Learning Management System and other means made available by the school</li>
              <li>Creates a calendar of course events and deadlines using the School&#39;s Learning Management System</li>
              <li>Uses technology to share course content, instructional materials, assignments and deadlines with students</li>
              <li>Assesses constantly the progress of students using a variety of techniques and provides meaningful feedback to them</li>
              <li>Maintains an accurate record of assessment, including formative and summative tasks, on the School&#39;s Learning Management System and the PowerSchool Gradebook.</li>
              <li>Links assessment tasks to the course standards as defined in the Unit Plans</li>
              <li>Keeps an accurate record of the students&#39; attendance</li>
              <li>Maintains a tidy and orderly classroom and decorates the bulletin boards inside the classroom and in the corridor with students&#39; work and useful instructional related materials</li>
              <li>Participates in school, faculty, collaboration and departmental meetings as requested by the school</li>
              <li>Collaborates with other teachers from within the department and from other departments to enrich Unit Plans, ensure vertical and horizontal alignment of curricula and explore opportunities for cross-curricular activities</li>
              <li>Plans and executes in-class and outdoor educational activities and events</li>
              <li>Creates and maintains a positive and healthy learning environment inside the classroom that is based on mutual respect and respect of students&#39; intellect</li>
              <li>Observes and understands students&#39; behavior and psyche and reports suspicions of neglect, abuse, etc.</li>
              <li>Develops and enriches his/her professional skills and knowledge by attending workshops, seminars, conferences, etc.</li>
              <li>Makes the best use of the resources available inside and outside the school to provide a global perspective for the taught material</li>
              <li>Plans class activities that help students develop their Approaches to Learning Skills and the attributes of the IB Learner Profile</li>
              </ul>



              <p className="mt-5"><strong>Teachers&#39; Desired Qualities</strong></p>
              <ul className="small-list">
              <li>Thorough knowledge of teaching best practices and legal educational guidelines partnered with a willingness to follow the school's policies and procedures</li>
              <li>Excellent communication and interpersonal skills</li>
              <li>Knowledge in the use of technology and software application to facilitate teaching and learning</li>
              <li>Well-organized and committed</li>
              <li>Creative and energetic</li>
              <li>Strong moral value system</li>
              <li>Appreciates teamwork and collaboration</li>
              <li>Appreciates exchanging ideas and sharing knowledge</li>
              <li>Enjoys working with students</li>
              <li>Knowledge in IB Primary Years Programme or IB Middle Years Programme or the IB Diploma Programme is preferable</li>
              </ul>


              <p className="mt-5"><strong>Minimum Requirements</strong></p>
              <ul className="small-list">
              <li>A bachelor&#39;s degree in Education/Teaching or in a specialized subject with a Teaching Diploma or recognized teaching certification</li>
              <li>A minimum of three years of full-time teaching experience. The school may accept applications from fresh graduates who completed their bachelor programme of education in recognised universities.</li>
              <li>University degrees and related transcripts must be notarized by an entity in the country from which the degree was obtained.</li>
              <li>University degrees and related transcripts must be attested as per the requirements of the Ministry of Education in Bahrain.</li>
              </ul>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
