import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/philosophy_statement';
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const MissionVision = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="philosophy_statement" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Our School</Link></li>
                    <li className="breadcrumb-item"><Link to="#">IKNS Philosophy</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>IKNS Mission Statement</strong></Link></li>
                  </ol>
                  
                </div>        
              </div>

              {/* <img src={require('../../assets/img/our_school/IKNSPhilosophyStatement.jpg')} className="mt-3 img-fluid rounded" alt='...'/>               */}

              <h4 className="mt-3 page-title">IKNS Vision Statement</h4>
              <p>IKNS is an inclusive and equitable learning environment that offers students with opportunities to become lifelong learners who engage compassionately in local and global matters, with a sense of belonging to their language and culture.</p>

              <h4 className="mt-5  page-title">IKNS Mission Statement</h4>
              <p>As a recognized leading K to 12 school in the region, IKNS continues to offer rigorous bilingual (Arabic and English) educational programmes that are based on the International Baccalaureate mission and the Bahrain 2030. The learning experiences of the students are enhanced through differentiated instructions and the use of appropriate educational technologies.</p>


            </div>
          </div>
        </div>


      <Footer/>
    </div>
  )
}


