import { Link } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";

import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const AccreditationAffiliation = () =>{
  return (
    <div>
      <Navigation/>

      <div id="accreditation_affiliation" className="container-fluid col-md-12 page-header"></div>
        <div id="one-page" className="wrapper">
          <div className="row">
            <div className="col-md-10">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
                <li className="breadcrumb-item"><Link to="#"><strong>Accreditation & Affiliation</strong></Link></li>
              </ol>
            </div>
          
            <h4 className="mt-3 page-title text-center">Accreditation & Affiliation</h4>               
          </div>


          <div className="row g-0">
            <div className="col-md-12 col-lg-6">
            <img src={require('../../assets/img/accreditation-affiliation/ib.png')} className="w-100 h-100 img-fluid" alt='...'/>              
            </div>
          
            <div className="col-md-12 col-lg-6 p-3">
              <div className="container d-flex h-100">
                <div className="row justify-content-center align-self-center">
                  <h4 className="mt-3 page-title">International Baccalaureate Organization</h4>
                  <p className="mt-3">
                  Ibn Khuldoon 
                  National School has been affiliated with the International 
                  Baccalaureate Organization since September of 1990. The first 
                  group of IKNS graduates sat for the IB examinations in May 1992.
                  </p>
               </div>
              </div>            
            </div>
          </div>



          <div className="row g-0 mt-3">
            <div className="col-md-12 col-lg-6 p-3">
              <div className="container d-flex h-100">
                <div className="row justify-content-center align-self-center">
                  <h4 className="mt-3 page-title">The Middle States Association of Colleges and Schools (MSA)</h4>
                  <p className="mt-3">
                  Ibn Khuldoon National 
                  School is fully accredited by Middle States Association of Colleges and Schools (MSA) 
                  since the year 1994, and by the Ministry of Education in Bahrain since its inception 
                  in 1983.
                  </p>
               </div>
              </div>            
            </div>
            <div className="col-md-12 col-lg-6">
            <img src={require('../../assets/img/accreditation-affiliation/msa.png')} className="w-100 h-100 img-fluid" alt='...'/>              
            </div>
          </div>


          <div className="row g-0 mt-3">
          <div className="col-md-12 col-lg-6">
            <img src={require('../../assets/img/accreditation-affiliation/maiba.png')} className="w-100 h-100 img-fluid" alt='...'/>              
            </div>

            <div className="col-md-12 col-lg-6 p-3">
              <div className="container d-flex h-100">
                <div className="row justify-content-center align-self-center">
                  <h4 className="mt-3 page-title">Middle East IB Association (MEIBA)</h4>
                  <p className="mt-3">
                  Ibn Khuldoon National School has been involved in founding MEIBA during 
                  the 1994-95 academic year and has been affiliated with it since then. MEIBA provides a 
                  forum for the IB schools of the Middle East to meet and discuss matter of common 
                  interest and communicates with the office of the Regional Director of the IBAEM 
                  (Africa/Europe/Middle East).
                  </p>
               </div>
              </div>            
            </div>
          </div>


        <div className="contact-section col-lg-9 mx-auto">
        <h4 className="mt-3 page-title">Contact Us</h4>
        <p>If you have questions about the Accreditation & Affiliation, we are here to help.</p>
        <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>
      </div>
    <Footer/>
    </div>
  )
}


