export const SidebarLinks = [
  {
    label: "Recruitment",
    path: "/recruitment"
  },
  {
    label: "Leadership Positions",
    path: "/leadership-positions"
  },
  {
    label: "Teaching Positions",
    path: "/teaching-positions"
  },

  {
    label: "Support Positions",
    path: "/support-positions"
  },

  {
    label: "Approaches to Teaching",
    path: "/approaches-to-teaching"
  },

];

export default SidebarLinks;
