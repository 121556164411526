import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
import sidebarLinks from '../../components/sidebar/academic-integrity';

export const AcademicHonestyPolicy = () => {
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="academic_integrity" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>            
            </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>IKNS Academic Honesty Policy</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">IKNS Academic Honesty Policy</h4>
                </div>        
              </div>

              <div className="img-right col-md-6">
                <img src={require('../../assets/img/academic/students_talk.jpg')} className="img-fluid rounded" alt='...'/>              
              </div>

              <p>
                Since IKNS made the decision that the IB Learner's Profile is the best expression 
                of its Philosophy and Mission Statement, and since this decision was spelled out in 
                great detail in the document entitled "IKNS Framework for Teaching", the unavoidable 
                conclusion is that trust and honesty are central to having teaching and learning 
                flourish at IKNS.
              </p>
                
                <p className="mt-5">In contrast, acts of academic dishonesty replace 
                the independent learning that we strive to achieve with undesirable outcomes - such 
                as dependency on others - that may lead to the students' lack of self-confidence, 
                or worse, to their lack of self-worth.</p>
                
                <p>The key to creating an IKNS culture that rejects academic honesty is to 
                  increase the level of awareness among the members of its community. 
                  The list of definitions below defines what are considered to be academically 
                  dishonest acts. It includes acts we are thus far aware of. The list is by no 
                  means exhaustive. These definitions cover some but not all possibilities, 
                  especially as technology advances.</p>


                  <p className="content-title mt-5">Definitions:</p>
                  <p><strong>Cheating:</strong> includes using unauthorized notes, study aides, or 
                    information on an examination; altering a graded work after 
                    it has been returned and then resubmitting that work; submitting 
                    identical or similar papers for credit in more than one course 
                    without prior permission from the course instructors.</p>

                  <p><strong>Plagiarism:</strong> submitting material that in part or whole is not 
                    entirely one's own work without attributing those same portions 
                    to their correct source.</p>
                  
                  <p><strong>Fabrication:</strong> falsifying or inventing any information, data or citation, 
                    presenting data that were not gathered in accordance with standard guidelines; 
                    not defining the appropriate methods by which the data were gathered or collected.</p>

                  <p><strong>Obtaining an Unfair Advantage:</strong> (a) stealing, reproducing, circulating or 
                    otherwise gaining access to examination materials prior to the time 
                    authorized by the instructor; (b) unauthorized collaboration on an 
                    academic assignment.</p>
                  
                  <p><strong>Aiding and Abetting Academic Dishonesty:</strong> (a) providing material, 
                    information, or other assistance to another person with the knowledge 
                    that such aid could be used in any of the violations stated above, 
                    or (b) providing false information in connection with any inquiry 
                    regarding academic integrity.</p>

                <p>
                  <strong>Falsification of Records and Official Documents:</strong> 
                  altering documents affecting academic records, forging signatures 
                  of authorization or falsifying information on an official academic 
                  document, grade report, letter of permission, petition, drop/add 
                  form, ID card, or any other official school document.
                </p>

                <p className="content-title mt-5">
                  Prevention of Academic Dishonesty
                </p>  
                
                <p>
                  IKNS encourages all teachers to submit students' work to external 
                  bodies for verification and evaluation of sources. Teachers are also 
                  encouraged to give clear instructions to students to keep all rough 
                  notes and drafts. The existence of documents that show how the students' 
                  work evolved over time allows students and teachers to guard themselves 
                  against acts of academic dishonesty.
                </p>              

                <p className="content-title mt-5">
                  Procedure for Investigating Suspected Cases of Academic Dishonesty
                </p>
                
                <p>
                  If a teacher suspects that a student may have breached the school's policy of academic 
                  honesty, the teacher will inform the relevant Head of Department or Coordinator. 
                  Together they will investigate the suspected case, and will inform the student of 
                  the concerns of the teacher, giving the student a chance to reply to the 
                  accusations.
                </p>
                
                <p>
                  In general, there should be clear evidence that the student committed an act of 
                  dishonesty. For example, students will be found guilty of plagiarism only if 
                  there is clear evidence in the form of source material that has been copied; 
                  which is an act that clearly shows the student's intent to be dishonest. 
                  Suspicion of plagiarism is not enough.
                </p>
                
                <p>
                  If it can be shown that inappropriate work (plagiarized, copied from other 
                  students, previously handed in, and so on) has been submitted, the Head of 
                  Department or Coordinator will make a recommendation to the principal of 
                  the school as to whether or not the case is one of academic dishonesty. 
                  The determining factor in settling such disputes is the school's ability 
                  to build a case to clearly establish the student's real intent.
                </p>

                <p className="content-title mt-5">
                  The Consequences of Academic Dishonesty
                </p>
                <p>
                  <strong>Consequences for 1st offense:</strong> Assessment tasks are no 
                  longer valid if students choose to be academically dishonest. 
                  IKNS does not award grades for invalid work. The teachers will complete 
                  an incident report and a copy of the report will go into the student's 
                  file. The principal will notify parents and may initiate a conference 
                  with the parent and student.
                </p>
                <p><strong>Possible consequences for 2nd offense:</strong> The student 
                will be referred to the Disciplinary Committee. Possible consequences 
                to be determined by the committee may include, but are not limited to, 
                suspension, course credit loss, and removal from sports teams and school 
                societies and other activities.</p>
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


