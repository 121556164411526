import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const SchoolCalendar = () => {

  return (
    <div>
      <Navigation />
      <div id="school_fees" className="container-fluid col-md-12 page-header"></div>
      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>School Calendar</strong></Link></li>
            </ol>
            <h4 className="mt-3 page-title">School Calendar</h4>
          </div>
        </div>

        <p>Our school calendar is designed to keep our students, parents, and staff informed about important dates, events, and activities throughout the academic year.</p>
        <p>At Ibn Khuldooon National School, we understand the significance of effective planning and scheduling. Our school calendar serves as a valuable resource to help everyone stay organized and engaged in the school&#39;s vibrant community.</p>



        <p><a target="_blank" rel="noreferrer" className="text-link" href="https://drive.google.com/file/d/1YCNub522AmWbEc_7W9lPjCK09DMQrNix/view?usp=sharing"><i className="fa-solid fa-file-pdf"></i> Ibn Khuldoon National School School Calendar 2023 - 2024</a></p>

        <div className="contact-section col-lg-9 mx-auto mt-5">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the  School Calendar, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

      </div>
      <Footer />
    </div>
  )
}


