export const SidebarLinks = [
  {
    label: "American High School Diploma (G11-G12)",
    path: "/american-high-school-diploma"
  },
  
  {
    label: "Courses Offered in the AHSD Programme",
    path: "/courses-offered-in-the-ahsd-programme"
  },
  
  {
    label: "Course Selection",
    path: "/ahsd-course-selection"
  },
 
  



];

export default SidebarLinks;
