import React from 'react'

export const Hero = () => {
  return (
    <div className="hero-slider">
      <div id="heroSliderControl" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">

          <div className="carousel-item active">
            <img src={require('../../assets/img/hero/1.jpg')} className="d-block w-100" alt='...' />
          </div>

          <div className="carousel-item">
            <img src={require('../../assets/img/hero/2.jpg')} className="d-block w-100" alt='...' />
          </div>
          <div className="carousel-item">
            <img src={require('../../assets/img/hero/3.jpg')} className="d-block w-100" alt='...' />
          </div>
          <div className="carousel-item">
            <img src={require('../../assets/img/hero/4.jpg')} className="d-block w-100" alt='...' />
          </div>
          <div className="carousel-item">
            <img src={require('../../assets/img/hero/5.jpg')} className="d-block w-100" alt='...' />
          </div>
          <div className="carousel-item">
            <img src={require('../../assets/img/hero/6.jpg')} className="d-block w-100" alt='...' />
          </div>
          <div className="carousel-item">
            <img src={require('../../assets/img/hero/7.jpg')} className="d-block w-100" alt='...' />
          </div>

        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#heroSliderControl" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#heroSliderControl" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  )
}

