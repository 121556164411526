import { Link } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const GraduateProfile = () => {
    
  return (
    <div>
      <Navigation/>
       <div id="graduate_profile" className="container-fluid col-md-12 page-header"></div>

            <div id="one-page" className="col-md-10 col-lg-10 mx-auto">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Our School</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Graduate Profile</strong></Link></li>
                  </ol>

                </div>
              </div>
                
              <h4 className="mt-3 mb-3 page-title text-center">Graduate Profile</h4>



            <div className="row g-0">
              <div className="col-md-12 col-lg-6">
                <img src={require('../../assets/img/graduate_profile/accomplished_life_long_learners.png')} className="w-100 h-100 img-fluid" alt='...'/>              
              </div>
              <div className="col-md-12 col-lg-6 bg-gray p-3">
                <div className="container d-flex h-100">
                  <div className="row justify-content-center align-self-center">
                  <h4 className="mt-3 page-title">Accomplished Life Long Learners</h4>
                  <p className="mt-3">IKNS graduates, having completed a rigorous academic program, are lifelong learners who 
                  think critically, are bilingual, communicate effectively in Arabic and English, and prepared for future career choices.</p>
                  </div>
                </div>            
              </div>
            </div>




            <div className="row g-0 mt-5">
              <div className="col-md-12 col-lg-6 bg-gray p-3">
                <div className="container d-flex h-100">
                  <div className="row justify-content-center align-self-center">
                  <h4 className="mt-3 page-title">Proud with Strong Sense of Belonging</h4>
                  <p className="mt-3">IKNS graduates have a sense of belonging to the local culture and language and are open to the international community.</p>
                  </div>
                </div>            
              </div>
              <div className="col-md-12 col-lg-6">
                <img src={require('../../assets/img/graduate_profile/proud_with_strong_sense_of_belonging.png')} className="w-100 h-100 img-fluid" alt='...'/>              
              </div>

            </div>


            
            <div className="row g-0 mt-5">
              <div className="col-md-12 col-lg-6">
                <img src={require('../../assets/img/graduate_profile/caring_and_open_minded.png')} className="w-100 h-100 img-fluid" alt='...'/>              
              </div>
              <div className="col-md-12 col-lg-6 bg-gray p-3">
                <div className="container d-flex h-100">
                  <div className="row justify-content-center align-self-center">
                  <h4 className="mt-3 page-title">Caring and Open-Minded</h4>
                  <p className="mt-3">IKNS graduates can effectively collaborate with and respect others from different cultures and countries.</p>
                  </div>
                </div>            
              </div>
            </div>



            <div className="row g-0 mt-5">
              <div className="col-md-12 col-lg-6 bg-gray p-3">
                <div className="container d-flex h-100">
                  <div className="row justify-content-center align-self-center">
                  <h4 className="mt-3 page-title">Value Service</h4>
                  <p className="mt-3">IKNS graduates recognize that we live in an interconnected world, and accept the responsibility to help others in need in the Bahraini community, and whenever possible, in other countries.</p>
                  </div>
                </div>            
              </div>
              <div className="col-md-12 col-lg-6">
                <img src={require('../../assets/img/graduate_profile/value_service.png')} className="w-100 h-100 img-fluid" alt='...'/>             
              </div>
            </div>
                     
              <div className="contact-section col-lg-9 mx-auto">
              <h4 className="mt-3 page-title">Contact Us</h4>
              <p>If you have questions about the IKNS Graduate Profile, we are here to help.</p>
              <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
              </div>

            </div>
      <Footer/>
    </div>
  );
}
 
