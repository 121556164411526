import { Link } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import '../../styles/innerpage.css';


export const BackToSchoolOperationalPlan = () =>{
  return (
    <div>
      <Navigation/>
      <div id="back_to_school_operational_plan" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              </div>   
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Back to School Operational Plan 2021-2022</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">Ibn Khuldoon National School Back to School Operational Plan, 2021-2022</h4>
                </div>
          
                <div className="col-md-2 text-center mt-3 d-none d-sm-block d-sm-none d-md-block">
                  <a href="https://drive.google.com/file/d/1etRzvA-hi-lWqnqxLdgt3C2OtoK92k6P/view?usp=sharing" target="_blank" rel="noreferrer">
                  <i className="fa-solid fa-file-pdf fa-2x"></i><br />
                  <small>View PDF</small>
                  </a>
                </div>
              </div>

              <p className="content-title block">The First Day of School for Students</p>
              <p>The first day of school for students in KG1 and Grades 1 to 12 is 
              Wednesday, 1 September 2021.</p>
                
              <p>The first day of school for KG2 students is Thursday, 2 September.
                Classes for students in Grades 1 to 12 will only be offered remotely 
                on Wednesday 1st and Thursday 2nd September. The school will transition 
                to the hybrid synchronous teaching and learning mode on Sunday, 5 September.</p>
                
              <p>KG1 students can attend classes in-person starting Wednesday, 1 September, 
                if their in-person attendance status is in line with the MoE, MoH and 
                Civil Aviation Affairs regulations.</p>
                
              <p>KG2 students can attend classes in person starting Thursday, 2 September 
                if their in-person attendance status is in line with the MoE, MoH and Civil 
                Aviation Affairs regulations.</p>
                
              <p>The Civil Aviation Affairs announced that all non-vaccinated students 
                will have to complete a mandatory 10 day period of remote learning upon 
                entering the Kingdom of Bahrain. This update starts from the midnight of 
                Sunday, 29 August 2021.</p>
                
              <p>As an added precaution, IKNS advises non-vaccinated K - 12 students, 
                who travelled out of Bahrain to complete 10 days in the country before 
                they start attending classes in-person, even if they arrived before Sunday, 
                29 August as announced  by the Civil Aviation Affairs.</p>



              <p className="content-title title-spacer block">Temperature Screening</p>
              <p>Temperature screening will continue for all staff, faculty and students 
              before entering the school building. Any individual with a high temperature 
              will follow the COVID-19 protocols set by the MoH and MoE.</p>

              <p>It is the parents' responsibility to keep their children at home if 
                they show flu-like symptoms or are feeling unwell.</p>


              <p className="content-title title-spacer block">Rapid Antigen Tests</p>
              <p>The school will continue to offer weekly rapid antigen tests. For those 
              who wish to register their child, a permission form will be sent at the 
              beginning of the school year.</p>

              <p className="content-title title-spacer block">Face Masks</p>
              <p>
              All faculty and staff are required to wear face masks while on the 
              school campus. Students in Grade 6 and above are required to wear a 
              mask while on campus. Students from KG to Grade 5 may choose to wear 
              a face mask or face shield while on campus.</p>

              <p className="content-title title-spacer block">Recess</p>
              <p>
              Students will have time to enjoy outdoor recess, while maintaining and 
              following social distancing safety guidelines. Outdoor mask policy will 
              be in-line with the Traffic Light System instructions. On the Green Level, 
              students may remove their masks during recess, following social distancing 
              safety guidelines.</p>

              <p className="content-title title-spacer block">Canteen</p>
              <p>
              The school canteens will be open for K to 12 students, in line with the 
              COVID-19 safety guidelines.</p>

              <p>Please note that the school canteens will start offering their 
                services to the students starting Sunday, 12 September. Therefore, 
                students in K-12 are asked to bring their own food/snack from home 
                until the school canteen is ready.</p>

              <p>Once all arrangements are finalized with the caterer, more 
                information about the canteen services will be shared.</p>

              <p className="content-title title-spacer block">Water Dispensers</p>
              <p>
              Water dispensers will be available for students, ensuring that 
              they are regularly cleaned and disinfected.</p>

              <p>Students are encouraged to bring their refillable water 
                bottles from home.</p>

              <p className="content-title title-spacer block">PE Classes</p>
              <p>  
              In person PE classes will resume under the Green, Yellow and 
              Orange traffic light system for students who are on campus.
              Students who choose to attend classes remotely under the 
              Green, Yellow and Orange traffic light system will be requested 
              to prepare PE projects that will be marked on a pass or fail basis.
              Students who are quarantining, self-isolating as well as students 
              who are requested by the Civil Aviation Affairs to complete 10 days 
              at home before they start learning in person since their arrival to 
              Bahrain, will be marked as Excused Absence. Passing the PE course 
              will continue to be part of the promotion policy for all students
              and part of the IKNS graduation requirements for 
              students in Grades 9 to 12.</p>

              <p className="content-title title-spacer block">Afterschool Clubs and Activitiess</p>
              <p>  
              After school clubs and activities will start soon after the start of 
              the school year. We will notify you in due time about the activities 
              available and their timings.</p>

              <p className="content-title title-spacer block">Middle and Secondary School Drop-off and Pick-up</p>
              <p>
              Middle and Secondary School students will continue to use pedestrian Gates 2 and 11.
              Gate 2 is located on Road 4111, while Gate 11 is located on the service road along 16
              December Highway.</p>
              <p>For morning drop-off, Gate 2 opens at 7:10am and Gate 11 opens at 7:30am. Please
              plan your trip accordingly and use the gate that suits your arrival time schedule.</p>
              <p>For afternoon pick-up, Gates 2 and 11 reopen at 2:00pm.</p>

              <p className="content-title title-spacer block">KG and Elementary School Drop-off and Pick-up</p>
              <p>
              All students in KG1 to Grade 5 will be accommodated in the new Kindergarten and
              Elementary campus. Every class is allocated a room, and the furniture in all rooms is
              laid out to maintain a minimum distance of 1 meter from the center of one desk to the
              other.</p>

              <p>Students in KG1 to Grade 5 can use Gates 5 and 7 (located on Road 4111) and Gate
              9A (located on the service road along 16 December Highway).</p>

              <p>Gates 5, 7 and 9A open at 7:10am. Supervision by teachers on duty 
                starts at 7:30am. Dropping the students before 7:30am is at the responsibility 
                of the parent.</p>

              <p>Gate 5 is a car gate that allows exit to the service road along 16 December 
                Highway only.</p>

              <p>Gate 7 has both a pedestrian entrance, and is also a car gate that allows 
                exit back to Road 4111.</p>

              <p>Gate 9A is a pedestrian gate on the service road along 16 December Highway.</p>

              <p>Elementary Parents may park their cars in the allocated car parking spaces 
                along the walkways on both sides of the service road. They can either enjoy 
                a morning stroll with their children into the school premises or drop off 
                their older children to give them the opportunity to walk unaccompanied by 
                an adult, provided they know their way into the school.</p>

              <p>There are plenty of car parking spaces inside and outside the school 
                premises all around the school. However, to avoid getting trapped in the 
                car parking areas inside the school premises, you may park your car 
                outside the school premises for either drop off or walking your child 
                inside the school.</p>

              <p>School drop-off and pick-up for KG and Elementary students is limited to one guardian
                only. Guardians are not permitted inside the school buildings.
                While on campus, guardians are required to wear a face mask and follow social
                distancing guidelines at all times.</p>

              <p>Dismissal times have been staggered to avoid congestion. Schedules have been
                shared in the Back to School survey dated 23 August, and are also available on the
                school website on the link: 
                <a href="https://www.ikns.edu.bh/bell-schedule-2021-2022" target="_blank" rel="noreferrer">https://www.ikns.edu.bh/bell-schedule-2021-2022</a></p>

              <p className="content-title title-spacer block">Student Allocation to Sections and issuing Student Schedules</p>
              <p>  
                The schedules of the students in KG1 to Grade 5 will be posted on Schoology on
                Sunday, 29 August. These schedules contain the links to the classes.</p>

              <p>The schedules of students in grades 6 to 12 will be made available to the 
                students by Tuesday, 31 August through PowerSchool. The principals are currently 
                balancing the number of students in the sections in adherence with the required 
                social distancing guidelines.</p>

              <p>Students in grades 6 to12 attending classes remotely will access their class links
                through their courses that will be available on Schoology. These links will be on
                Schoology by Tuesday, 31 August.</p>  

              <p className="content-title title-spacer block">Uniforms</p>
              <p>
                The school uniform is available at the House of Uniform and Al Shakar 
                Tailoring. Please make arrangements to buy or tailor the uniform in time.
                We request that all students, whether attending classes in-person or 
                remotely, to be in school uniform. A grace period until Thursday, 9 
                September will be given to students to have their uniforms tailored. 
                If the school uniform is not ready by 9 September, please inform the 
                school administration in writing.</p>

              <p className="content-title title-spacer block">Books, Stationery, Lockers for Grades 6 to 12 Students</p>
              <p>
                The textbooks and art and drama stationery for students in grades 6 to 12 
                are available for sale from the bookstore from 8am to 1pm, Sunday to Thursday.
                In the present circumstances and as a distancing measure, student lockers 
                will not be used.</p>
              
              <p className="content-title title-spacer block">Books and Stationery for KG1 to Grade 5 Students</p>
              <p>  
                Subject books, workbooks and stationery are part of the school 
                fees package for students in KG1 to Grade 5. The stationery includes: 
                Scissors, glue stick, markers, colored papers, ruler, Art books.</p>
                
              <p>Students attending classes in-person will receive their books, workbooks 
                and stationery on the first day of school.</p>

              <p>Students attending classes in-person must bring their own pencils, 
                eraser, sharpener, colored pencils/crayons. The stationery items will be 
                kept at school at all times. All subject books and notebooks (UOI, English, 
                Arabic, Math and Art) will be sent home every Thursday and must be returned 
                back to school on Sunday.</p>

              <p>Parents of students attending classes remotely will be requested to 
                collect their child's books, workbooks and stationery from school. 
                Ms. Afrah Jassim, Elementary School Principal, will send a Schoology 
                notification informing parents once the packages are ready for collection.</p>
                
              <p className="content-title title-spacer block">Grade 3 to Grade 12 Laptops and Headphones</p>
              <p>
                Students in grades 3 to 12 who are attending classes in-person are expected 
                to bring their laptops fully charged to school every day in case they need to 
                complete and submit in-class assignments. They are also encouraged to bring 
                their headphones with them (if available).</p>

              <p className="content-title title-spacer block">Dealing with a Confirmed or Suspected Case of COVID-19</p>
              <p>
              Suspected or confirmed cases of COVID -19 will be dealt with according to the
              procedures that have been circulated by the Ministry of Education.</p>

              <p>Partial or complete suspension of the school is subject to the procedures issued by the
              Ministry of Education and the Central Administration of the school.</p>

              <p className="content-title title-spacer block">Disinfection</p>
              <p>
              The students' desks will be cleaned and sanitized and the classrooms will be 
              disinfected every afternoon.</p>
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


