import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/myp';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const MiddleYearsProgramme = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="myp" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>The IB Middle Years Programme (G6-G10)</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">The IB Middle Years Programme (G6-G10)</h4>
                </div>        
              </div>

              <p>
              MYP stands for Middle Years Programme. It is designed for students aged 11 
              to 16 (G6 - G10). It provides a framework of learning which encourages 
              students to become creative, critical and reflective thinkers. The MYP 
              emphasizes intellectual challenge, encouraging students to make connections 
              between their studies in traditional subjects and to the real world. 
              It fosters the development of skills for communication, intercultural 
              understanding and global engagement-essential qualities for young people 
              who are becoming global leaders.
              </p>
            
            <p>
            The MYP is flexible enough to accommodate most national or local curriculum 
            requirements. It builds upon the knowledge, skills and attitudes developed 
            in the IB Primary Years Programme (PYP) and prepares students to meet the 
            academic challenges and requirements of the IB Diploma Programme (DP).
            </p>

            <small>
            Source: <a className="text-primary" href="https://www.ibo.org/globalassets/digital-tookit/brochures/myp-programme-brochure-en.pdf" target="_blank" rel="noreferrer">
              "The IB Middle Years Programme." International Baccalaureate, 2015, 
              www.ibo.org/globalassets/digital-tookit/brochures/myp-programme-brochure-en.pdf.</a>
            </small>

            <p className="mt-3">
            The MYP ensures breadth and depth of understanding through study in eight 
            subject groups. Addressing the intellectual, social, emotional and physical 
            well-being of students holistically, the MYP prepares them to take principled 
            action by participating in service within the community and requiring study of 
            at least two languages (at IKNS they are English and Arabic languages) to 
            support the understanding of their own culture and those of others.
            </p>

            <p>
            MYP students also engage with big ideas, rather than simply memorizing facts 
            in order to prepare for exams. As a result, they develop learning skills that 
            they can rely on throughout the course of their independent, 
            purpose-driven lives.
            </p>

            <small>
            Source: <a className="text-primary" href="https://www.ibo.org/globalassets/digital-tookit/brochures/1703-myp-at-a-glance-en.pdf" 
            target="_blank" rel="noreferrer">"The IB Middle Years Programme at a Glance." International Baccalaureate, 2016, 
            www.ibo.org/globalassets/digital-tookit/brochures/1703-myp-at-a-glance-en.pdf.</a>
            </small>

            <p className="mt-3"> 
            IKNS is a candidate school* for the IB Middle Years Programme (MYP) since July 2019 
            and is pursuing IB authorization to offer the programme.
            </p>
            <p>
            <em>*Only schools authorised by the IB Organisation can offer any of its four 
              programmes: the Primary Years Programme (PYP), the Middle Years 
              Programme (MYP), the Diploma Programme (DP) and the Career-related 
              Programme (CR). Candidate status gives no guarantee that authorization 
              will be granted. For further information about the IB and its programmes, 
              visit <a className="text-primary" href="https://www.ibo.org" target="_blank" rel="noreferrer">http://www.ibo.org</a></em>
            </p>


            <div className="col-md-12 mt-5">
              <img src={require('../../assets/img/myp/global_contexts.png')} className="img-fluid" alt='Global Contexts'/>              
            </div>

            <p className="content-title mt-3">
            Global Contexts
            </p>
            <p>
            These are the links to “real world” applications of academic content. 
            There are six designated MYP contexts: Identities and relationships, 
            Orientation in space and time, Personal and cultural expression, Scientific 
            and technical innovation, Globalization and sustainability, and Fairness and 
            development.
            </p>

            <p>
            In Grade 10 (MYP5), student's inquiry into one, personally chosen Global 
            Context is assessed through the Personal Project. The Personal Project is a 
            significant body of work produced over an extended period. It is a product of 
            student initiative and should reflect their experience of the MYP and their 
            ATL skills development.
            </p>

            <p className="content-title mt-5">
            The MYP Programme Model
            </p>
            <p>
            In the MYP, teachers organize the curriculum collaboratively, designing and 
            implementing a concept-driven curriculum. The MYP Curriculum Model comprises 
            eight subject groups. These are:
            </p>
            


            <div className="img-right col-md-6">
              <img src={require('../../assets/img/myp/myp-model-en.png')} className="img-fluid" alt='pyp'/>              
            </div>
            <ul className="mt-5">
              <li>Language Acquisition</li>
              <li>Language and Literature</li>
              <li>Individuals and Societies</li>
              <li>Sciences</li>
              <li>Mathematics</li>
              <li>Arts</li>
              <li>Physical and Health education</li>
              <li>Design</li>
            </ul>
              

            <p className="content-title mt-5">
            Approaches to Learning Skills
            </p>
            <p>
            Approaches to Learning (ATL) skills help students effectively 
            manage and evaluate their own learning. ATL skills (Communication, 
            Social, Self-management, Research and Thinking) are not always 
            formally assessed; however, such skills are taught in each unit of 
            study to emphasize the importance of reflection on learning how to learn.
            </p>

            <p>
            ATL skills can be learned and taught, improved with practice and developed 
            incrementally. They provide a solid foundation for learning independently 
            and with others. They help students prepare for, and demonstrate learning 
            through, purposeful and meaningful assessment. They provide a common 
            language that students and teachers can use to reflect on, and articulate 
            on, the process of learning.
            </p>



            <p className="content-title mt-5">
            Service As Action
            </p>
            <p>
            In Grades 6-10 (MYP1-5), students participate in service-based activities 
            where they have to take action. Activities vary by grade level/MYP year 
            and students will be given opportunities to take part in group projects, 
            school-based projects, on a voluntary basis and self-initiated projects. 
            The aim is for students to use and build upon their own interests and 
            experiences to come up with solutions, think critically and creatively, 
            build on their Approaches to Learning skills and become more involved in 
            their community and beyond.
            </p>


           
            <div className="col-md-12 mt-5">
            <img src={require('../../assets/img/myp/myp_projects.png')} className="img-fluid" alt='Global Contexts'/>              
            </div>
            <p className="content-title mt-3">
            MYP Projects
            </p>
            
            <p>Students will also have the opportunity to engage in both a community project 
              and a personal project. The community project and the personal project are 
              known together as MYP projects. At IKNS, the Community Project is done in 
              the third year of the programme (G8) and the Personal Project in the fifth 
              year of the MYP (G10).
            </p>
            <p>
            MYP projects are student-centred and age-appropriate, and they enable students 
            to engage in practical explorations through a cycle of inquiry, action and 
            reflection. MYP projects help students to develop the attributes of the IB 
            learner profile; provide students with an essential opportunity to demonstrate 
            Approaches to Learning (ATL) skills developed through the MYP; and foster the 
            development of independent, lifelong learners.
            </p>
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


