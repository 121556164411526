export const SidebarLinks = [
  {
    label: "The IB Primary Years Programme (KG1-G5)",
    path: "/pyp"
  },
  {
    label: "The PYP at IKNS",
    path: "/pyp-at-ikns"
  },
  {
    label: "Assessment in PYP",
    path: "/assessment-in-pyp"
  },
  {
    label: "KG and Elementary Rules and Procedures",
    path: "/kg-elementary-rules-and-procedures"
  },
  {
    label: "KG & Elementary Activities",
    path: "/kg-elementary-activities"
  },

];

export default SidebarLinks;
