import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from "../../components/sidebar/governing-body"
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const Constitution = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      
      <div id="governing_body" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
            </div> 
          </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Our School</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Constitution</strong></Link></li>
                  </ol>
                  {/* <h4 className="mt-3 page-title">Committees</h4> */}
                </div>        
              </div>

        
              <p className="mt-5 text-center">This Website is Page Construction. Come Back Soon.</p>             

              {/* <p>Finance Committee</p>
              <p>Technical Committee</p>
              <p>Education Committee</p> */}
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


