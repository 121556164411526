import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/philosophy_statement';
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const CoreValues = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="philosophy_statement" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Our School</Link></li>
                    <li className="breadcrumb-item"><Link to="#">IKNS Philosophy</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>IKNS Core Values</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">IKNS Core Values</h4>
                </div>        
              </div>

              {/* <img src={require('../../assets/img/our_school/IKNSPhilosophyStatement.jpg')} className="mt-3 img-fluid rounded" alt='...'/>               */}

              <p>Lifelong Learners: We know how to learn independently and with others.</p>

              <p>Compassionate: We show empathy and respect when we deal with others.</p>

<p>Active: We have a commitment to service that makes a positive impact on the local as well as the international community.</p>

<p>Connected: We have a sense of belonging to our culture and language and are open to the international community.</p>
            </div>
          </div>
        </div>


      <Footer/>
    </div>
  )
}


