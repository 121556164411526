export const SidebarLinks = [

  {
    label: "First Day of School",
    path: "/photo-gallery"
  },
  {
    label: "Facilities",
    path: "/facilities-photo"
  },

];

export default SidebarLinks;
