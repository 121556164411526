export const HomeAffiliation = () =>{
  
  return (
    <div className="col-md-9 mt-5 mx-auto">
    <div className="row">      
      <div className="col-md-4 text-center">
        <div className="card border-0">
          <div className="card-body">
            <a href="https://www.bqa.gov.bh" target="_blank" rel="noreferrer">
              <img src={require('../../assets/logo/bqa_logo.png')} className="img-fluid" alt='...'/>  
            </a>
            <p>
              <a className="text-muted d-block" href="https://www.bqa.gov.bh/En/Reports/SchoolsReports/SchoolReport/IKNS%20review%20report%20English.pdf" target="_blank" rel="noreferrer"><small>Second Cycle Report, March 2017</small></a>
              <a className="text-muted d-block" href="https://www.bqa.gov.bh/En/Reports/SchoolsReports/SchoolReport/Ibn%20Khuldoon%20National%20ENn.pdf" target="_blank" rel="noreferrer"><small>First Cycle Report, September 2013</small></a>
            </p>
          </div>
        </div>
      </div>
      
      <div className="col-md-4 text-center">
        <div className="card border-0">
          <div className="card-body">
            <a href="https://www.msa-cess.org/" target="_blank" rel="noreferrer">
              <img src={require('../../assets/logo/msa_logo.png')} className="img-fluid" alt='...'/>  
            </a>
            <p>
              <a className="text-muted d-block" href="msa-accreditation-award-letter" target="_blank" rel="noreferrer">
              <small>MSA Accreditation Award Letter</small>
              </a>
              
              <a className="text-muted d-block" href="msa-accreditation-team-report" target="_blank" rel="noreferrer">
              <small>MSA Accreditation Team Report</small>
              </a>
            </p>
          </div>
        </div>
      </div>


      <div className="col-md-4 text-center">
        <div className="card border-0">
          <div className="card-body">
            <a href="http://www.theptc.org" target="_blank" rel="noreferrer">
            <img src={require('../../assets/logo/ptc_logo.png')} className="img-fluid" alt='...'/>  
            </a>
            <p className="mt-3">
              <a className="text-muted" href="http://www.theptc.org" target="_blank" rel="noreferrer">
              <small>PTC Member School</small>
              </a>
            </p>
          </div>
        </div>
      </div>


    </div>
   </div>
  )
}