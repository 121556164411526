export const SidebarLinks = [
  {
    label: "Faculty and Staff",
    path: "/faculty-and-staff"
  },


];

export default SidebarLinks;
