import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from 'react-router-dom';
import axios from "axios";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import * as Constants from "../../queries/articleDetails";
import * as Sidebar from "../../queries/articleSidebar";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const ArticleDetails = () => {
  const { slug } = useParams();
  const location = useLocation();
  const [records, setRecords] = useState({ posts: [] });
  const [sidebar, setSidebar] = useState({ posts: [] });

  const fetchApi = async () => {
    const queryResult = await axios.post(
      Constants.GRAPHQL_API,
      {
        query: Constants.GET_POSTS_DETAILS,
        variables: {
          slug: slug
        }
      }
    );
    const result = queryResult.data.data.posts;
    setRecords({ posts: result.nodes });
  };

  const fetchSidebar = async () => {
    const queryResult = await axios.post(
      Sidebar.GRAPHQL_API,
      {
        query: Sidebar.GET_POSTS_SIDEBAR
      }
    );
    const result = queryResult.data.data.posts;
    setSidebar({ posts: result.nodes });
  };

  useEffect(() => {
    fetchSidebar();
  }, [slug]);

  useEffect(() => {
    fetchApi();
  });


  return (
    <div>
      <Navigation />
      <div id="article_header" className="container-fluid col-md-12 page-header-article"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebar.posts.map((link, index) => (
                  <div key={index} className={location.pathname === "/article/" + link.slug ? "active-menu" : "inactive"}>
                    <Link to={`/article/${link.slug}`} className="text-sm">
                      {link.title}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {records.posts.map((item, index) => (
            <div key={index} id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>{item.title}</strong></Link></li>
                  </ol>
                </div>
              </div>

              <img src={item.featuredImage?.node.sourceUrl} className="mt-3 rounded img-fluid" alt='...' />
              <h4 className="mt-3 page-title">{item.title}</h4>
              <article className="mt-3" dangerouslySetInnerHTML={{ __html: item.content }}></article>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
