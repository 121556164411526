import { Link,useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/why-msa-accreditation';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const WhyMsaAcccreditation = () => {
  const location = useLocation();

  return (
    <div>
      <Navigation />

      <div id="why_msa_accreditation" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
        <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Our School</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">External Accreditation</Link>
                  </li>

                  <li className="breadcrumb-item">
                    <Link to="#">
                      <strong>Why MSA Accreditation</strong>
                    </Link>
                  </li>
                </ol>
                <h4 className="mt-3 page-title">
                Why MSA Accreditation
                </h4>
              </div>
            </div>

            <div className="col-md-12">
              <div className="embed-responsive embed-responsive-16by9">
                <div className="video" 
                style={{
                    position: "relative",
                    paddingBottom: "56.25%" /* 16:9 */,
                    paddingTop: 25,
                    height: 0,
                  }}
                >
                  <iframe
                    title="Embeded"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    src={`https://www.youtube.com/embed/Fz_Uxua6oL0`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
