export const SidebarLinks = [
  {
    label: "Parents",
    path: "/parents"
  },
  {
    label: "Parent Volunteers",
    path: "/parent-volunteers"
  },


];

export default SidebarLinks;
