import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/external-exam-results';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const Pirls = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="external_exam_results" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#">External Exam Results</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>The Progress in International Reading Literacy Study (PIRLS)</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">The Progress in International Reading Literacy Study (PIRLS)</h4>
                </div>        
              </div>

              {/* <p>.</p> */}

              <p>
              PIRLS is an international assessment and research project designed 
              to measure reading achievement at the fourth-grade level, as well 
              as school and teacher practices related to instruction. Fourth-grade 
              students complete a reading assessment and questionnaire that addresses 
              their attitudes toward reading and reading habits. PIRLS is sponsored 
              by the International Association for the Evaluation of Educational 
              Achievement (IEA) and conducted in the United States by the National 
              Center for Education Statistics (NCES). PIRLS is administered every 
              five years worldwide.
              </p>

              <p>
              IKNS recent participation in PIRLS was conducted in 2016 and 2021.
              </p>
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


