import { Link } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const AdmissionProcedure = () => {
  
  return (
    <div>
      <Navigation/>
      <div id="admission" className="container-fluid col-md-12 page-header"></div>

        <div id="one-page" className="wrapper">

 
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Admission</strong></Link></li>
                  </ol>
                </div>        
              </div>


              <h4 className="mt-3 page-title">Admission Procedure</h4>
              <p>Ibn Khuldoon National School (IKNS) accepts applications for new students in December yearly. The application process opens to grade levels from KG1 to Grade 11.</p>
              <p>The Application Form is electronic. Parents interested in applying for their children to join the school can apply through the links found at the end of this page. Please make sure to read through the full procedure before you fill the application form.</p>
              <p>Following the receipt of all applications, and upon the availability and allocations of seats per grade level, the Admission Office will send an SMS to the parent advising him/her to proceed to pay the Application Fee via the IKNS payment portal found in links above on the school's website.</p>
              
              <p className="mt-5">Kindly note the following:</p>
              <ul>
                <li>The Application Fee is non-refundable.</li>
                <li>The Application Fee does not guarantee an admission offer. It is for processing the application.</li>
                <li>The Application Fee is only to be paid upon the receipt of an SMS from School. The Admission Office will not contact the parents who paid the non-refundable Application Fee without receiving an SMS from the school advising them to do so.</li>
              </ul>
              
              <p>The parents who receive the SMS and pay the Application Fee within the allocated time frame, will be contacted by the Admission Office inviting their child to do the entrance exams/interviews.</p>
              <p>The students applying for KG1 will be doing an entrance interview, and the students applying to KG2 to Grade 11 will be asked to sit for entrance exams. The length of each exam per grade level is shown in the table below.</p>
              
              <p>For more information regarding the KG1 interview, <a href="https://drive.google.com/file/d/1i-21V5b3E8cEmkSDVCO2gwZR_4ZJhi_3/view?usp=sharing" className="mt-3 text-danger" target="_blank" rel="noreferrer">KG1 Interview Parent Guide</a>.</p>

              <div className="col-md-12 mx-auto">
                <table className="table table-bordered">
                <thead>
                  <tr className="bg-secondary">
                    <th scope="col">Grades</th>
                    <th scope="col">Entrance Exams / Interviews</th>
                    <th scope="col">Duration</th>
                  </tr>
                </thead>
          
                <tbody>
                  <tr>
                    <th scope="row">KG 1</th>
                    <td>Interview by appointment</td>
                    <td>15 minutes</td>
                  </tr>

                  <tr>
                    <th scope="row">KG 11</th>
                    <td>Arabic, English, and Mathematics</td>
                    <td>15 minutes per subject</td>
                  </tr>
                
                  <tr>
                    <th scope="row">Grades 1 - 3</th>
                    <td>Arabic, English, and Mathematics</td>
                    <td>35-45 minutes per subject</td>
                  </tr>            

                  <tr>
                    <th scope="row">Grades 4 - 5</th>
                    <td>Arabic, English, and Mathematics</td>
                    <td>45-55 minutes per subject</td>
                  </tr>            

                  <tr>
                    <th scope="row">Grades 6 - 9</th>
                    <td>Arabic, English, and Mathematics</td>
                    <td>90 minutes per subject</td>
                  </tr>            

                  <tr>
                    <th scope="row">Grades 10 - 11</th>
                    <td>Arabic, English, Mathematics, and Science</td>
                    <td>90 minutes per subject</td>
                  </tr>                        

                </tbody>
              </table>
              </div>
              
              <p>The entrance exams/interviews will take place in January for students applying for KG1 to Grade 5 and in February for students applying for grades 6 to 11. The exact dates will be communicated to interested parents at that time.</p>


              <h4 className="mt-5 page-title">Admission Follow Up Procedure</h4>
              <p><span className="d-block"><strong>KG1:</strong></span> 
                All KG1 students who sit for an interview will receive an SMS regarding their application. The SMS will have information regarding the results of the interview, as well as the next steps required to take, if any, to finalize the admissions procedure.
              </p>

              <p><span className="d-block"><strong>KG2 - Grade 11:</strong></span> 
              All KG2 to Grade 11 students who sit for the entrance exams will be placed on a waiting list based on their performance on these exams. Based on the results of the Entrance Exams, the Admission Office will notify the parent whether his/her child is to be invited for an interview or not. Offering a student a seat at IKNS depends on the overall performance of the student in the interview and the Entrance Exams, and the parent will be notified accordingly.
              </p>
              
              <p>Students who were offered seats may proceed with the next steps in the admission procedure as informed by the office</p>


              <h4 className="mt-5 page-title">General Requirements</h4>
              <p>Parents who are interested in enrolling their children at IKNS should familiarize 
                themselves with the School culture, the educational programs being offered and 
                assessment techniques, etc. <a href="https://drive.google.com/file/d/12Ou81r8DmRAhNQP2It5bq2gIEdMPQM6Q/view" className="mt-3 text-danger" target="_blank" rel="noreferrer">
                  The IKNS Brochure</a> is is intended to provide such information. Enrollment at IKNS must be based on appreciation and support of the schools <a href="/philosophy-statement" className="mt-3 text-danger" target="_blank" rel="noreferrer">
                  Philosophy</a>, <a href="/mission-vision" className="mt-3 text-danger" target="_blank" rel="noreferrer">Mission and Vision</a>, <a href="/core-values" className="mt-3 text-danger" target="_blank" rel="noreferrer">Core Values</a> and <a href="/graduate-profile" className="mt-3 text-danger" target="_blank" rel="noreferrer">Graduate Profile</a>. This is necessary for a long term partnership that is based on knowledge, transparency and trust.
                  </p>
                  
                  <p>For inquiries, please call 17780661 and follow the voicemail to speak directly to the section of the school you are applying for. For grades KG1 to 5, call the Elementary School, grades 6 to 8, call the Middle School, and grades 9 to 11, call the Secondary School.</p>


                  



        <div className="contact-section col-lg-9 mx-auto">
        <h4 className="mt-3 mb-3 page-title">Apply Now</h4>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfM5D59uMrtoOz2hwag-vqcZ-DyLwjuqTRCG3X-U-KklFf9Cg/viewform" className="mt-3 text-danger" target="_blank" rel="noreferrer">KG1 Application Form <i className="fas fa-arrow-right text-right"></i></a>
        <p className="mt-3">To apply for a grade level from KG2 to Grade 11.
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSe6u7KuqVqzgxoGBlES68TVPTbH2YwUwxDbpn_rE1jSXk86-w/viewform" className="d-block text-danger" target="_blank" rel="noreferrer">KG2 - Grade 11 New Student Application Form <i className="fas fa-arrow-right text-right"></i></a>
        </p>
        </div>
       
             

        </div>
      <Footer/>
    </div>
  )
}


