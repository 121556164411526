export const SidebarLinks = [
  {
    label: "The Governing Body",
    path: "/governing-body"
  },
  {
    label: "Committees",
    path: "/committees"
  },
  {
    label: "Constitution",
    path: "/constitution"
  },

];

export default SidebarLinks;



