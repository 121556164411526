import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/ibdp';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const TheExtendedEssay = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="ibdp" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#">The IB Diploma Programme (G11-G12)</Link></li>                   
                    <li className="breadcrumb-item"><Link to="#"><strong> The Extended Essay</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">The Extended Essay</h4>
                </div>        
              </div>

              

              <div className="img-right col-md-6">
              <img src={require('../../assets/img/ibdp/the_extended_essay.png')} className="rounded img-fluid" alt='pyp'/>              
              </div>

              <p>
              The Extended Essay is one of the three core elements of the IBDP. It is an 
              independent research in a subject of the student's choice that leads to a 
              4000-word essay.
              </p>

              <p>
                The Extended Essay process extends from November of Grade 11 to November 
                of Grade 12 at IKNS. The student selects the subject and topic he/she is 
                interested in and works on a concise research question. Based on this he 
                is assigned a member of staff as a supervisor. The supervisor supports the 
                student in refining the research question, finding suitable sources, 
                deciding on the methodology of the research and ensuring the 
                authenticity of the work.
              </p>
              
              <p>
                Students at IKNS have one period per cycle allocated to Extended Essay work in 
                Grade 11. They receive guidance on academic writing and familiarize themselves 
                with the subject specific requirements before deciding on their own 
                research topic and question.
              </p>
              
              <p>
                IKNS students have engaged in interesting extended essays in most 
                subjects areas. These essays are made available to read in the library.
              </p>
            
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


