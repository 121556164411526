import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/admission';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const AdmissionGuide  = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="admission" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Admission</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><strong>Admission Guide</strong></Link></li>
                  </ol>
                  {/* <h4 className="mt-3 page-title">Academic Integrity</h4> */}
                </div>        
              </div>
             <p>
             This is a brief guide meant to parents applying to enroll their
              children in KGI at Ibn Khuldoon National School (IKNS). Your
              child will be taking his first step in the world of formal education,
              and it is natural for a parent to feel a little anxious. The journey
              begins with the first step of registering your child at IKNS.</p>

              <a href="https://drive.google.com/file/d/1i-21V5b3E8cEmkSDVCO2gwZR_4ZJhi_3/view" className="text-primary" target="_blank" rel="noreferrer">
                View KG1 Admission Interview - Parent Guide
              </a>

            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


