export const SidebarLinks = [
  {
    label: "Alumni",
    path: "/alumni"
  },


];

export default SidebarLinks;
