import { Link } from "react-router-dom"

export const Footer = () =>{
  return (
    <div>
      <footer className="container-fluid mt-3">
        <div className="col-md-12 mt-3 text-center">
          <ul className="social-circle">
            <li><Link to="https://www.facebook.com/ibn.khuldoon.national.school/" target="_blank"><i className="fab fa-facebook-f"></i></Link></li>
            <li><Link to="https://twitter.com/IknsInfo" target="_blank"><i className="fa-brands fa-twitter"></i></Link></li>
            <li><Link to="https://www.instagram.com/ikns/" target="_blank"><i className="fab fa-instagram"></i></Link></li>
            <li><Link to="https://www.linkedin.com/company/ibn-khuldoon-national-school/" target="_blank"><i className="fab fa-linkedin"></i></Link></li>
            <li><Link to="https://www.youtube.com/channel/UCB9bQQq4m-C_n1t8GNO4mXA" target="_blank"><i className="fab fa-youtube"></i></Link></li>
          </ul>				
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12 mt-3 text-center">
          <small>COPYRIGHT &copy; 2023. IBN KHULDOON NATIONAL SCHOOL</small>                                 
          <ul className="legal list-group list-group-horizontal d-flex justify-content-center">
            <li className="border-0 list-group-item"><Link to="#">Terms of Use</Link></li>
            <li className="border-0 list-group-item"><Link to="#">Privacy Policy</Link></li>
            <li className="border-0 list-group-item"><Link to="#">Cookie Policy</Link></li>
          </ul>
        </div>
      </footer>
    </div>
  )
}

