import "../../styles/Brands.css";
// import Marquee from "react-fast-marquee";

export const Brands = () => {
  return (
    <div className="container spacer" id="contact">
      <div className="col-md-10 mx-auto text-center mb-5 mt-5">
        <div className="container text-center">
          <div className="row">
            <div className="col">
              <a href="https://www.ibo.org" target="_blank" rel="noreferrer">
                <img className="brands d-inline-block" alt="" src={require('../../assets/logo/ib_main.png')} />
              </a>
              <a href="https://www.ibo.org/globalassets/new-structure/brochures-and-infographics/pdfs/ib-continuum-brochure-en.pdf" target="_blank" rel="noreferrer">
                <img className="brands d-inline-block" alt="" src={require('../../assets/logo/ib_continuum.png')} />
              </a>
              <a href="https://www.ibo.org/programmes/primary-years-programme/" target="_blank" rel="noreferrer">
                <img className="brands d-inline-block" alt="" src={require('../../assets/logo/ib_pyp.png')} />
              </a>
              <a href="https://www.ibo.org/programmes/middle-years-programme" target="_blank" rel="noreferrer">
                <img className="brands d-inline-block" alt="" src={require('../../assets/logo/ib_myp.png')} />
              </a>
              <a href="https://www.ibo.org/programmes/diploma-programme" target="_blank" rel="noreferrer">
                <img className="brands d-inline-block" alt="" src={require('../../assets/logo/ib_dp.png')} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}