import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/ibdp';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const UniversityRecognition = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="ibdp" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#">The IB Diploma Programme (G11-G12)</Link></li>                   
                    <li className="breadcrumb-item"><Link to="#"><strong>University Recognition</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">University Recognition</h4>
                </div>        
              </div>

              

              <div className="img-right col-md-6">
              <img src={require('../../assets/img/ibdp/theory-of-knowledge.png')} className="rounded img-fluid" alt='pyp'/>              
              </div>

              <p>
              The IB Diploma is seen as excellent preparation by most universities around the world. In the UK, Ireland and Australia the IB Diploma allows the student to move directly to the first year of a university course based on meeting conditional offers with their IB results. Without the IB Diploma the student has to complete a foundation year before starting the university course.
              </p>

              <p>
              US and Canadian universities often give credit to specific IB subjects especially if a student obtains a grade 5 or above. The subjects and grades awarded credit vary between different universities and courses of study.
              </p>

            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


