
export const SidebarLinks = [
  {
    label: "IKNS Philosophy Statement",
    path: "/philosophy-statement"
  },

  {
    label: "IKNS Mission Statement",
    path: "/mission-vision"
  },

  {
    label: "IKNS Core Values",
    path: "/core-values"
  },

];

export default SidebarLinks;
