import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/school-policies';
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const SchoolPolicies = () => {
  const location = useLocation();

  return (
    <div>
      <Navigation />

      <div id="school_policies" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>School Policies</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">School Policies</h4>
              </div>

            </div>


            <p>We take great pride in maintaining a safe, inclusive, and thriving educational
              environment. Central to achieving this objective are our school policies.
              Our policies serve as the foundation upon which our school community is built,
              providing essential guidelines and principles that shape every aspect of our
              institution.</p>

            <p>Our school policies encompass a wide range of areas, from academic standards
              to student conduct, safety protocols, and everything in between. They are
              meticulously designed to ensure that our students have the best possible
              learning experience and that our staff and parents or guardians have a clear
              understanding of the expectations and standards that govern our institution.</p>

            <p>Our policies are not just rules to be followed; they are a reflection of our
              commitment to excellence, equity, and the well-being of all individuals in our
              school community. They are living documents that evolve to adapt to the changing
              needs of our dynamic learning environment.</p>

            <p>In this section, you will find detailed information about our school policies, including:</p>


            <p className="mt-5"><strong>Infirmary Policies</strong></p>

            <ul className="list-group list-group-flush">
              <li className="list-group-item"><a href="https://drive.google.com/file/d/1lBNXAHFb3Dtc6cPJ3ZVNmv4Ny4HeAHLx/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">Infirmary Services & procedures</a></li>
              <li className="list-group-item"><a href="https://drive.google.com/file/d/1rTcXganCGktIBBmGE8RMExdwwWwWZ6dv/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">Diabetes Management Plan</a></li>
              <li className="list-group-item"><a href="https://drive.google.com/file/d/1j4zFJafvrCfjQbqdQ9wvlTmeW8ybvElt/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">Asthma Management Plan</a></li>
              <li className="list-group-item">
                Health Awareness
                <ul>
                  <li><a href="https://drive.google.com/file/d/1dL-LtoaSJJlgS7xtDlSEcJAoRI3jqENo/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">Head Lice Brochure</a></li>
                  <li><a href="https://drive.google.com/file/d/1gqoFHff_83xfqnKTwHrCm-ZkEdQriEHM/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">Hand, foot, and mouth Disease</a></li>
                </ul>
              </li>
            </ul>



            <p className="mt-5"><strong>Information Technology</strong></p>
            <ul className="list-group list-group-flush">
              <li className="list-group-item"><a href="https://drive.google.com/file/d/1WikR4z5_VQnLkihNv2u4zyiDZqbffqu5/view" className="text-danger" target="_blank" rel="noreferrer">Students Device Specifications</a></li>
            </ul>


            <p className="mt-5"><strong> Emergency Evacuation Procedures</strong></p>
            <ul className="list-group list-group-flush">
              <li className="list-group-item"><a href="https://drive.google.com/file/d/1yrZ0HgRJt68I58on4aJPYMdVYgALUj8Q/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">IKNS Fire Drill/Evacuation Procedures</a></li>
              <li className="list-group-item"><a href="https://drive.google.com/file/d/1bGHXNABLArJK1nPgSV176cWiWDvgYEaD/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">Earthquakes Evacuation Procedures</a></li>
            </ul>


            <p className="mt-5"><strong> Assessment Policies</strong></p>
            <ul className="list-group list-group-flush">
              <li className="list-group-item"><a href="https://drive.google.com/file/d/1RCWC0oKFtnik9Y8LT3U0mhd7BkzKVcOi/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">KG & Elementary</a></li>
              <li className="list-group-item"><a href="https://drive.google.com/file/d/1bxUX_gIUaO79k1NoS-Js1fbPVkZuem7L/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">Middle School</a></li>
              <li className="list-group-item"><a href="https://drive.google.com/file/d/19lh0kMnCWdgPoLwbsyiYrmRBzz8XL4uP/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">Secondary School</a></li>
            </ul>


            <p className="mt-5"><strong>Other Policies</strong></p>
            <ul className="list-group list-group-flush">
              {/* <li className="list-group-item"><a href="/" className="text-danger" target="_blank" rel="noreferrer">Code of Conduct, KG and Elementary</a></li> */}
              <li className="list-group-item"><a href="https://drive.google.com/file/d/1OsNf-3N-ph0yb3LhgwaiAjR2Sad1HomN/view" className="text-danger" target="_blank" rel="noreferrer">Academic Integrity Policy</a></li>
              <li className="list-group-item"><a href="https://drive.google.com/file/d/1i6S53DqqKomwkgf-mUMlwTDReJR6PusB/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">Language Policy</a></li>
              <li className="list-group-item"><a href="https://drive.google.com/file/d/1Hw9Pefsav-qx7rTIaxq2vm4RZ5ICzITP/view?usp=sharing" className="text-danger" target="_blank" rel="noreferrer">Communication Policy</a></li>
              <li className="list-group-item"><a href="https://drive.google.com/file/d/1eTmx-fwPSOu1V4gQO_IFZJfCwT6QGp2u/view" className="text-danger" target="_blank" rel="noreferrer">Access and Inclusion Policy</a></li>
            </ul>


          </div>
        </div>
      </div>




      <Footer />
    </div>
  )
}


