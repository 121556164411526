
export const SidebarLinks = [
  {
    label: "Why MSA Accreditation",
    path: "/why-msa-accreditation"
  },

];

export default SidebarLinks;
