import { Link,useLocation } from "react-router-dom";
import {Navigation} from "../../components/Navigation";
import {Footer} from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/ibdp';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const CreativityActivityService = () => {
  
  const location = useLocation();

  return (
    <div>
      <Navigation/>
      <div id="ibdp" className="container-fluid col-md-12 page-header"></div>

        <div className="wrapper">
          <div className="row">
            <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
              <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                { sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
                </div>  
              </div> 
            </div>

            <div id="content" className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-10">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                    <li className="breadcrumb-item"><Link to="#">The IB Diploma Programme (G11-G12)</Link></li>                   
                    <li className="breadcrumb-item"><Link to="#"><strong>Creativity, Activity, Service</strong></Link></li>
                  </ol>
                  <h4 className="mt-3 page-title">Creativity, Activity, Service</h4>
                </div>        
              </div>

              

              <div className="img-right col-md-6">
              <img src={require('../../assets/img/ibdp/creativity-activity-service.png')} className="rounded img-fluid" alt='pyp'/>              
              </div>

              <p>
                Creativity, Activity, Service (CAS) is one of the three core elements of the IBDP 
                in which students engage over the two years of the programme in activities related 
                to creativity, activity and service. In addition to these activities, IBDP students 
                are required to work collaboratively to complete a CAS related project.
              </p>
              
              <p>
                The Creativity aspect of CAS entails exploring and extending ideas leading to 
                an original or interpretive product or performance. The Activity aspect entails 
                physical exertion contributing to a healthy lifestyle, and Service entails 
                collaborative and reciprocal engagement with the community in response to an 
                authentic need.
              </p>

              <p>
                CAS paves the way for students to learn through experience and balances 
                the academic pressures of the DP. It encourages students to develop their 
                skills, work collaboratively on shared goals and be actively involved in 
                the local, national and global communities.
              </p>

              <p>
                IKNS students engage in a variety of activities within the school that 
                are recognised as CAS related activities and projects. These include 
                involvement in after school clubs, societies and activities. IKNS 
                provides a wide variety of activities that suit the different students' 
                talents and interests, and provide new learning experiences for them. 
                Activities include the Honor Society, Student Congress, Go Green, MUN, 
                Tradequest, Injaz, Arabic Magazine, Yearbook Club, Heritage Club, 
                Leadership Club, Basketball, Volleyball, Soccer, Track and Field, 
                Badminton, Tennis, Table Tennis, Handball. They participate in 
                competitions, organize charity events and propose initiatives for 
                improvements around school and beyond.
              </p>

              <p>
                Students also participate in clubs and activities outside school and 
                volunteer to work with less advantaged groups both nationally and 
                internationally.
              </p>
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}


