import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const BellSchedule = () => {

  return (
    <div>
      <Navigation />

      <div id="school_fees" className="container-fluid col-md-12 page-header"></div>

      <div id="one-page" className="wrapper">
        <div className="row">


          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>Bell Schedule</strong></Link></li>
            </ol>
            <h4 className="mt-3 page-title">IKNS Bell Schedule Summary 2023-2024</h4>
          </div>
        </div>


        <p className="text-sm">On Tuesdays, students in Grades 1 to 12 follow a short day schedule where they are dismissed earlier than the rest of the week. This adjustment has been made to allocate time within the schedule for weekly collaborative planning meetings among our faculty members.</p>
        <p className="text-sm">The Tuesday bell schedule is referred to as the Short Day Schedule and the bell schedule for Sunday, Monday, Wednesday and Thursday is referred to as the Regular Day Schedule. Below are the Starting and Dismissal timings for students in the different sections of the school, for both the regular school day and and the short day.</p>
        <p className="text-sm">The KG students follow a regular schedule Sunday to Thursday.</p>

        <table className="table table-bordered text-xs align-middle">
          <thead>
            <tr className="align-middle text-center">
              <th scope="col">School Section</th>
              <th scope="col">Starting Time</th>
              <th scope="col">Regular Day Dismissal Time</th>
              <th scope="col">Short Day Dismissal Time</th>
              <th scope="col">After School Activities Time (Regular Day)</th>
              <th scope="col">After School Activities Time (Short Day)</th>
            </tr>
          </thead>
          <tbody className="text-center">
            <tr>
              <td>Kindergarten<br />KG 1 & 2</td>
              <td>7:40 AM</td>
              <td>12:30 PM</td>
              <td>Not applicable</td>
              <td>12:30 PM - 1:50 PM</td>
              <td>12:30 PM - 1:10 PM</td>
            </tr>

            <tr>
              <td className="text-center">Elementary School<br />Grades 1 - 5</td>
              <td>7:40 AM</td>
              <td>1:50 PM</td>
              <td>1:10 PM</td>
              <td>2:00 PM - 2:50 PM</td>
              <td>1:20 PM - 2:10 PM</td>
            </tr>

            <tr>
              <td className="text-center">Middle School<br />Grades 6 to 8</td>
              <td>7:45 AM</td>
              <td>2:55 PM</td>
              <td>1:25 PM</td>
              <td>3:10 PM - 4:10 PM</td>
              <td>1:40 PM - 2:40 PM</td>
            </tr>
            <tr>
              <td className="text-center">Secondary School<br />Grades 9 to 12</td>
              <td>7:45 AM</td>
              <td>2:55 PM</td>
              <td>1:25 PM</td>
              <td>3:10 PM - 4:10 PM</td>
              <td>1:40 PM - 2:40 PM</td>
            </tr>
          </tbody>
        </table>



        <p>
          <a target="_blank" className="text-link" rel="noreferrer" href="https://drive.google.com/file/d/1Zf4L3IAV_l9e8c3Rk6-n-Hb4EWeSAp0S/view?usp=sharing"><i className="fa-solid fa-file-pdf"></i> KG and Elementary Bell Schedule</a>
        </p>

        <p>
          <a target="_blank" className="text-link" rel="noreferrer" href="https://drive.google.com/file/d/1jITnZk0_ovvf3GIOt7LT3HKQjMQVdquh/view?usp=sharing"><i className="fa-solid fa-file-pdf"></i> Middle and Secondary Bell Schedule</a>
        </p>
        <p>
          <a target="_blank" className="text-link" rel="noreferrer" href="/contact-us"><i className="fa-solid fa-file-pdf"></i> Ramadan Bell Schedule</a>
        </p>

        <div className="contact-section col-lg-9 mx-auto mt-5">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the IKNS Bell Schedule, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>


      </div>
      <Footer />
    </div>
  )
}


