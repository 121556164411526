import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const CycleCalendar = () => {

  return (
    <div>
      <Navigation />

      <div id="school_fees" className="container-fluid col-md-12 page-header"></div>

      <div id="one-page" className="wrapper">
        <div className="row">


          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>Middle & Secondary Schools 6-day Cycle Calendar</strong></Link></li>
            </ol>
            <h4 className="mt-3 page-title">Middle & Secondary Schools 6-day Cycle Calendar</h4>
          </div>
        </div>


        <p className="text-sm">Our school operates on a unique 6-day cycle schedule to provide students with a diverse and comprehensive learning experience.</p>

        <p className="text-sm">
          At IKNS, we believe in the power of variety and flexibility in education.
          Our 6-day cycle allows us to offer a wide range of subjects and extracurricular
          activities, ensuring that students can explore their interests and reach their full potential.
        </p>




        <p>
          <a target="_blank" className="text-link" rel="noreferrer" href="https://drive.google.com/file/d/1pv6qaV5y39kXHneFRfpYDjZw5u6AAzcc/view?usp=sharing"><i className="fa-solid fa-file-pdf"></i> Middle & Secondary Schools 6-day Cycle Calendar 2023-2024</a>
        </p>


        <div className="contact-section col-lg-9 mx-auto mt-5">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the Middle & Secondary Schools 6-day Cycle Calendar, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>


      </div>
      <Footer />
    </div>
  )
}


