import { Link } from "react-router-dom";
export const Ourcampus = () => {

  return (
    <div>
      <div id="our_campus" className="mt-5 container-fluid">
      <div className="col-md-11 mx-auto">
        <h3 className="text-center page-title text-white pt-5">Our Campus</h3>
          <div className="row">
            <div className="col-sm-4 g-2 custom-pointer">
            <Link to="/facilities-photo">
              <div className="card border-0">               
                <img src={require('../../assets/img/our_campus/our_campus.jpg')} className="img-fluid" alt='Our Campus'/>          
                  <div className="card-body">
                  <h5 className="text-center">Our Campus</h5>
                  </div>
              </div>
              </Link>
            </div>

            <div className="col-sm-4 g-2 custom-pointer">
            <Link to="video-gallery">
              <div className="card border-0">               
                <img src={require('../../assets/img/our_campus/inmotion.jpg')} className="img-fluid" alt='IKNS in Motion'/>          
                <div className="card-body">
                  <h5 className="text-center">IKNS in Motion</h5>
                </div>
              </div>
              </Link>
            </div>
           
            <div className="col-sm-4 g-2 custom-pointer">
            <Link to="/photo-gallery">           
              <div className="card border-0">
             
                <img src={require('../../assets/img/our_campus/photo_gallery.jpg')} className="img-fluid" alt='IKNS in Photos'/>          
               
                <div className="card-body">
                <h5 className="text-center">IKNS in Photos</h5>
                </div>
              </div>       
              </Link>      
            </div>
            
          </div>
      </div>
      </div>
    </div>
    )
  }