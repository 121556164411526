export const SidebarLinks = [
  {
    label: "Admission",
    path: "/admission"
  },

  {
    label: "Apply-New Student",
    path: "/apply-new-student"
  },

  {
    label: "KG1 Admission Interview - Parent Guide",
    path: "/kg1-admission-interview-parent-guide"
  },

  {
    label: "School Fees",
    path: "/school-fees"
  },

];

export default SidebarLinks;
